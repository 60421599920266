/* eslint-disable */
import { CreditRange } from "../../../mortgage/data/credit_range";

export const protobufPackage = "od_protos.sell.reception.data";

/**
 * Answers collected from the seller as part of the seller flow.
 * Next ID: 129
 */
export interface Answers {
  /** Home related answers */
  addition?: string;
  backYardCondition?: string;
  backYardSlope?: string;
  backYardSlopeRetainingWall?: boolean;
  backYardSlopeType?: string;
  basement?: string;
  basementFinishedSqFt?: number;
  basementSqFt?: number;
  basementSqFtTriage?: string;
  /**
   * Deprecated. Use master_bathroom
   *
   * @deprecated
   */
  bathroomCondition?: string;
  masterBathroomCondition?: string;
  bathroomRemodel?: string;
  bathrooms?: number;
  bathroomsFull?: number;
  bathroomsHalf?: number;
  bedrooms?: number;
  bedroomsTriage?: string;
  carportSpaces?: number;
  dwellingType?: string;
  entryTypes?: Answers_EntryTypes;
  exteriorFacade?: string;
  exteriorStories?: number;
  floodZoneTriage?: string;
  flooringCarpetCondition?: string;
  flooringFollowUpLiving?: string;
  flooringFollowUpMasterBedroom?: string;
  flooringHardwoodCondition?: string;
  flooringLaminateCondition?: string;
  flooringPrimaryFloorCondition?: string;
  flooringPrimaryFloorType?: string;
  flooringSecondaryFloorCondition?: string;
  flooringSecondaryFloorType?: string;
  flooringTileCondition?: string;
  foundationIssue?: string;
  garageSpaces?: number;
  hoa?: boolean;
  hoaTypes?: Answers_HoaTypes;
  interiorPaintCondition?: string;
  interiorPaintNeutral?: string;
  kitchenApplianceType?: string;
  kitchenCondition?: string;
  kitchenCounterType?: string;
  kitchenRemodel?: string;
  leasedSolarPanelsTriage?: string;
  lotOwnership?: string;
  masterBedroomFloor?: number;
  poolType?: string;
  sharedEntrance?: boolean;
  solarPanelsOwnershipType?: string;
  /**
   * Deprecated. Use above_grade_sq_ft
   *
   * @deprecated
   */
  sqFt?: number;
  aboveGradeSqFt?: number;
  sqFtTriage?: string;
  yearBuilt?: number;
  selfServiceVirtualAssessmentChoice?: string;
  /** Seller Score */
  kitchenSellerScore?: string;
  bathroomSellerScore?: string;
  livingRoomSellerScore?: string;
  exteriorSellerScore?: string;
  /** Seller related answers */
  activelyLooking?: string;
  agent?: string;
  brokerage?: string;
  email?: string;
  signedListingAgreement?: boolean;
  priceEstimateRange?: Answers_PriceEstimateRange;
  optedIntoExclusiveOffers?: boolean;
  buyingNextHome?: boolean;
  buyingNextHomeStatus?: string;
  sellingTimelineFlexibility?: string;
  willingnessForTours?: string;
  /** Agent info related answers */
  listingAgentEmail?: string;
  listingAgentFullName?: string;
  /**
   * Not used since April 2019
   *
   * @deprecated
   */
  financingReferral?: string;
  financingReferralOpendoorMortgage?: string;
  financingReferralTrustedLender?: string;
  firstName?: string;
  fullName?: string;
  homeBuilder?: string;
  homeBuilderCommunity?: string;
  homeBuilderEmail?: string;
  homeBuilderOther?: string;
  homeBuyingProcess?: string;
  homeBuyingProcessOther?: string;
  initialFinancingStatus?: string;
  lastName?: string;
  mlsAgentId?: string;
  mortgageAbad?: string;
  moveInReady?: string;
  movingAway?: string;
  otherInformation?: string;
  otherSellingWays?: string;
  phoneNumber?: string;
  priceEstimateCents?: number;
  priceEstimateTriage?: string;
  realtorEmailOverride?: boolean;
  realtorNameOverride?: boolean;
  referralSource?: string;
  referralSourceOther?: string;
  referralSourceRadio?: string;
  relationToOwner?: string;
  relationToOwnerOther?: string;
  saleTimeline?: string;
  scheduleOfferReview?: string;
  sellingReasonOther?: string;
  workingWithHomeBuilder?: boolean;
  verificationPrimaryOwner?: string;
  bathroomFeatures?: Answers_BathroomFeatures;
  eligibilityCriteria?: Answers_EligibilityCriteria;
  floodHistoryTriage?: Answers_FloodHistoryTriage;
  flooringAllFloorTypes?: Answers_FloorTypes;
  flooringCarpetRooms?: Answers_Rooms;
  flooringHardwoodRooms?: Answers_Rooms;
  flooringLaminateRooms?: Answers_Rooms;
  flooringOtherRooms?: Answers_Rooms;
  flooringTileRooms?: Answers_Rooms;
  flooringMaterials: string[];
  kitchenFeatures?: Answers_KitchenFeatures;
  photosUploaded: Answers_Photo[];
  sellingReason?: Answers_SellingReason;
  exploringFinancing?: string;
  /** deprecated */
  buyerZones: Answers_BuyerZone[];
  appSmsOptIn?: boolean;
  setupBuyerFeed?: Answers_SetupBuyerFeed;
  buyingPower?: Answers_BuyingPower;
  buyingTimeline?: string;
  buyingStage?: string;
  agentStatus?: string;
  agentType?: string;
}

/** Next ID: 6 */
export interface Answers_BathroomFeatures {
  doubleSink?: boolean;
  graniteCountertops?: boolean;
  separateShowerTub?: boolean;
  tileSurround?: boolean;
  updatedTileFloors?: boolean;
}

/** Next ID: 20 */
export interface Answers_EligibilityCriteria {
  ageRestrictedCommunity?: boolean;
  asbestosSiding?: boolean;
  fireDamage?: boolean;
  gatedCommunity?: boolean;
  historyOfChemicalContamination?: boolean;
  knownFoundationIssues?: boolean;
  leasedSolarPanels?: boolean;
  livestock?: boolean;
  mobileManufacturedHome?: boolean;
  ownedSolarPanels?: boolean;
  permittedAddition?: boolean;
  septic?: boolean;
  wellWater?: boolean;
  uniqueOwnershipStructure?: boolean;
  belowMarketRateOwnership?: boolean;
  rentControlledTenantOccupied?: boolean;
  undergroundOilTank?: boolean;
  /**
   * Not used since Oct 2018
   *
   * @deprecated
   */
  polybutylenePiping?: boolean;
  unpermittedAddition?: boolean;
  cesspool?: boolean;
}

/** Next ID: 6 */
export interface Answers_FloodHistoryTriage {
  harvey?: boolean;
  memorialDay?: boolean;
  notSure?: boolean;
  other?: boolean;
  taxDay?: boolean;
}

/** Next ID: 6 */
export interface Answers_FloorTypes {
  carpet?: boolean;
  hardwood?: boolean;
  laminate?: boolean;
  other?: boolean;
  tile?: boolean;
}

/** Next ID: 7 */
export interface Answers_Rooms {
  dining?: boolean;
  kitchen?: boolean;
  living?: boolean;
  masterBathroom?: boolean;
  masterBedroom?: boolean;
  other?: boolean;
}

/** Next ID: 4 */
export interface Answers_KitchenFeatures {
  kitchenIsland?: boolean;
  newCabinets?: boolean;
  tileBacksplash?: boolean;
}

/** Next ID: 8 */
export interface Answers_SellingReason {
  downsizing?: boolean;
  investmentProperty?: boolean;
  needCash?: boolean;
  other?: boolean;
  relocating?: boolean;
  retiring?: boolean;
  upgrading?: boolean;
}

/** Next ID: 5 */
export interface Answers_Photo {
  key?: string;
  url?: string;
  filename?: string;
  mimetype?: string;
}

/** Next ID: 11 */
export interface Answers_BuyerZone {
  breadcrumbPath?: string;
  displayName?: string;
  displayablePropertiesCount?: number;
  id?: string;
  isNearby?: boolean;
  name?: string;
  parentName?: string;
  parents: Answers_BuyerZone_ZoneParent[];
  value?: string;
  zip?: boolean;
  gdsId?: string;
}

export interface Answers_BuyerZone_ZoneParent {
  breadcrumbPath?: string;
  name?: string;
}

export interface Answers_FeedPropertyTypes {
  home?: boolean;
  apartment?: boolean;
  townhome?: boolean;
  multiFamily?: boolean;
}

/** Next ID: 7 */
export interface Answers_SetupBuyerFeed {
  zones: Answers_BuyerZone[];
  maxPrice?: number;
  minPrice?: number;
  minBedrooms?: number;
  minBathrooms?: number;
  propertyTypes?: Answers_FeedPropertyTypes;
}

export interface Answers_BuyingPower {
  /** annual income in cents. */
  annualHouseholdIncome: number;
  /** down payment in cents. */
  downPaymentAmount: number;
  /** monthly debt/expenses in cents. */
  monthlyDebt: number;
  /** credit score range. */
  creditScoreIndicated?: CreditRange;
}

/** Next ID: 4 */
export interface Answers_EntryTypes {
  directEntry?: boolean;
  sharedEntranceCondo?: boolean;
}

/** Next ID: 4 */
export interface Answers_HoaTypes {
  ageRestrictedCommunity?: boolean;
  gatedCommunity?: boolean;
  guardedGatedCommunity?: string;
  sharedEntranceCondo?: boolean;
}

/** Next ID: 3 */
export interface Answers_PriceEstimateRange {
  minPrice?: string;
  maxPrice?: string;
}

function createBaseAnswers(): Answers {
  return {
    addition: undefined,
    backYardCondition: undefined,
    backYardSlope: undefined,
    backYardSlopeRetainingWall: undefined,
    backYardSlopeType: undefined,
    basement: undefined,
    basementFinishedSqFt: undefined,
    basementSqFt: undefined,
    basementSqFtTriage: undefined,
    bathroomCondition: undefined,
    masterBathroomCondition: undefined,
    bathroomRemodel: undefined,
    bathrooms: undefined,
    bathroomsFull: undefined,
    bathroomsHalf: undefined,
    bedrooms: undefined,
    bedroomsTriage: undefined,
    carportSpaces: undefined,
    dwellingType: undefined,
    entryTypes: undefined,
    exteriorFacade: undefined,
    exteriorStories: undefined,
    floodZoneTriage: undefined,
    flooringCarpetCondition: undefined,
    flooringFollowUpLiving: undefined,
    flooringFollowUpMasterBedroom: undefined,
    flooringHardwoodCondition: undefined,
    flooringLaminateCondition: undefined,
    flooringPrimaryFloorCondition: undefined,
    flooringPrimaryFloorType: undefined,
    flooringSecondaryFloorCondition: undefined,
    flooringSecondaryFloorType: undefined,
    flooringTileCondition: undefined,
    foundationIssue: undefined,
    garageSpaces: undefined,
    hoa: undefined,
    hoaTypes: undefined,
    interiorPaintCondition: undefined,
    interiorPaintNeutral: undefined,
    kitchenApplianceType: undefined,
    kitchenCondition: undefined,
    kitchenCounterType: undefined,
    kitchenRemodel: undefined,
    leasedSolarPanelsTriage: undefined,
    lotOwnership: undefined,
    masterBedroomFloor: undefined,
    poolType: undefined,
    sharedEntrance: undefined,
    solarPanelsOwnershipType: undefined,
    sqFt: undefined,
    aboveGradeSqFt: undefined,
    sqFtTriage: undefined,
    yearBuilt: undefined,
    selfServiceVirtualAssessmentChoice: undefined,
    kitchenSellerScore: undefined,
    bathroomSellerScore: undefined,
    livingRoomSellerScore: undefined,
    exteriorSellerScore: undefined,
    activelyLooking: undefined,
    agent: undefined,
    brokerage: undefined,
    email: undefined,
    signedListingAgreement: undefined,
    priceEstimateRange: undefined,
    optedIntoExclusiveOffers: undefined,
    buyingNextHome: undefined,
    buyingNextHomeStatus: undefined,
    sellingTimelineFlexibility: undefined,
    willingnessForTours: undefined,
    listingAgentEmail: undefined,
    listingAgentFullName: undefined,
    financingReferral: undefined,
    financingReferralOpendoorMortgage: undefined,
    financingReferralTrustedLender: undefined,
    firstName: undefined,
    fullName: undefined,
    homeBuilder: undefined,
    homeBuilderCommunity: undefined,
    homeBuilderEmail: undefined,
    homeBuilderOther: undefined,
    homeBuyingProcess: undefined,
    homeBuyingProcessOther: undefined,
    initialFinancingStatus: undefined,
    lastName: undefined,
    mlsAgentId: undefined,
    mortgageAbad: undefined,
    moveInReady: undefined,
    movingAway: undefined,
    otherInformation: undefined,
    otherSellingWays: undefined,
    phoneNumber: undefined,
    priceEstimateCents: undefined,
    priceEstimateTriage: undefined,
    realtorEmailOverride: undefined,
    realtorNameOverride: undefined,
    referralSource: undefined,
    referralSourceOther: undefined,
    referralSourceRadio: undefined,
    relationToOwner: undefined,
    relationToOwnerOther: undefined,
    saleTimeline: undefined,
    scheduleOfferReview: undefined,
    sellingReasonOther: undefined,
    workingWithHomeBuilder: undefined,
    verificationPrimaryOwner: undefined,
    bathroomFeatures: undefined,
    eligibilityCriteria: undefined,
    floodHistoryTriage: undefined,
    flooringAllFloorTypes: undefined,
    flooringCarpetRooms: undefined,
    flooringHardwoodRooms: undefined,
    flooringLaminateRooms: undefined,
    flooringOtherRooms: undefined,
    flooringTileRooms: undefined,
    flooringMaterials: [],
    kitchenFeatures: undefined,
    photosUploaded: [],
    sellingReason: undefined,
    exploringFinancing: undefined,
    buyerZones: [],
    appSmsOptIn: undefined,
    setupBuyerFeed: undefined,
    buyingPower: undefined,
    buyingTimeline: undefined,
    buyingStage: undefined,
    agentStatus: undefined,
    agentType: undefined,
  };
}

export const Answers = {
  fromJSON(object: any): Answers {
    return {
      addition: isSet(object["home.addition"]) ? String(object["home.addition"]) : undefined,
      backYardCondition: isSet(object["home.back_yard.condition"])
        ? String(object["home.back_yard.condition"])
        : undefined,
      backYardSlope: isSet(object["home.back_yard.slope"]) ? String(object["home.back_yard.slope"]) : undefined,
      backYardSlopeRetainingWall: isSet(object["home.back_yard.slope.retaining_wall"])
        ? Boolean(object["home.back_yard.slope.retaining_wall"])
        : undefined,
      backYardSlopeType: isSet(object["home.back_yard.slope.type"])
        ? String(object["home.back_yard.slope.type"])
        : undefined,
      basement: isSet(object["home.basement"]) ? String(object["home.basement"]) : undefined,
      basementFinishedSqFt: isSet(object["home.basement_finished_sq_ft"])
        ? Number(object["home.basement_finished_sq_ft"])
        : undefined,
      basementSqFt: isSet(object["home.basement_sq_ft"]) ? Number(object["home.basement_sq_ft"]) : undefined,
      basementSqFtTriage: isSet(object["home.basement_sq_ft.triage"])
        ? String(object["home.basement_sq_ft.triage"])
        : undefined,
      bathroomCondition: isSet(object["home.bathroom.condition"])
        ? String(object["home.bathroom.condition"])
        : undefined,
      masterBathroomCondition: isSet(object["home.master_bathroom.condition"])
        ? String(object["home.master_bathroom.condition"])
        : undefined,
      bathroomRemodel: isSet(object["home.bathroom.remodel"]) ? String(object["home.bathroom.remodel"]) : undefined,
      bathrooms: isSet(object["home.bathrooms"]) ? Number(object["home.bathrooms"]) : undefined,
      bathroomsFull: isSet(object["home.bathrooms.full"]) ? Number(object["home.bathrooms.full"]) : undefined,
      bathroomsHalf: isSet(object["home.bathrooms.half"]) ? Number(object["home.bathrooms.half"]) : undefined,
      bedrooms: isSet(object["home.bedrooms"]) ? Number(object["home.bedrooms"]) : undefined,
      bedroomsTriage: isSet(object["home.bedrooms.triage"]) ? String(object["home.bedrooms.triage"]) : undefined,
      carportSpaces: isSet(object["home.carport_spaces"]) ? Number(object["home.carport_spaces"]) : undefined,
      dwellingType: isSet(object["home.dwelling_type"]) ? String(object["home.dwelling_type"]) : undefined,
      entryTypes: isSet(object["home.entry_type"]) ? Answers_EntryTypes.fromJSON(object["home.entry_type"]) : undefined,
      exteriorFacade: isSet(object["home.exterior_facade"]) ? String(object["home.exterior_facade"]) : undefined,
      exteriorStories: isSet(object["home.exterior_stories"]) ? Number(object["home.exterior_stories"]) : undefined,
      floodZoneTriage: isSet(object["home.flood_zone.triage"]) ? String(object["home.flood_zone.triage"]) : undefined,
      flooringCarpetCondition: isSet(object["home.flooring.carpet_condition"])
        ? String(object["home.flooring.carpet_condition"])
        : undefined,
      flooringFollowUpLiving: isSet(object["home.flooring.follow_up_living"])
        ? String(object["home.flooring.follow_up_living"])
        : undefined,
      flooringFollowUpMasterBedroom: isSet(object["home.flooring.follow_up_master_bedroom"])
        ? String(object["home.flooring.follow_up_master_bedroom"])
        : undefined,
      flooringHardwoodCondition: isSet(object["home.flooring.hardwood_condition"])
        ? String(object["home.flooring.hardwood_condition"])
        : undefined,
      flooringLaminateCondition: isSet(object["home.flooring.laminate_condition"])
        ? String(object["home.flooring.laminate_condition"])
        : undefined,
      flooringPrimaryFloorCondition: isSet(object["home.flooring.primary_floor_condition"])
        ? String(object["home.flooring.primary_floor_condition"])
        : undefined,
      flooringPrimaryFloorType: isSet(object["home.flooring.primary_floor_type"])
        ? String(object["home.flooring.primary_floor_type"])
        : undefined,
      flooringSecondaryFloorCondition: isSet(object["home.flooring.secondary_floor_condition"])
        ? String(object["home.flooring.secondary_floor_condition"])
        : undefined,
      flooringSecondaryFloorType: isSet(object["home.flooring.secondary_floor_type"])
        ? String(object["home.flooring.secondary_floor_type"])
        : undefined,
      flooringTileCondition: isSet(object["home.flooring.tile_condition"])
        ? String(object["home.flooring.tile_condition"])
        : undefined,
      foundationIssue: isSet(object["home.foundation_issue"]) ? String(object["home.foundation_issue"]) : undefined,
      garageSpaces: isSet(object["home.garage_spaces"]) ? Number(object["home.garage_spaces"]) : undefined,
      hoa: isSet(object["home.hoa"]) ? Boolean(object["home.hoa"]) : undefined,
      hoaTypes: isSet(object["home.hoa_type"]) ? Answers_HoaTypes.fromJSON(object["home.hoa_type"]) : undefined,
      interiorPaintCondition: isSet(object["home.interior_paint.condition"])
        ? String(object["home.interior_paint.condition"])
        : undefined,
      interiorPaintNeutral: isSet(object["home.interior_paint.neutral"])
        ? String(object["home.interior_paint.neutral"])
        : undefined,
      kitchenApplianceType: isSet(object["home.kitchen.appliance_type"])
        ? String(object["home.kitchen.appliance_type"])
        : undefined,
      kitchenCondition: isSet(object["home.kitchen.condition"]) ? String(object["home.kitchen.condition"]) : undefined,
      kitchenCounterType: isSet(object["home.kitchen.counter_type"])
        ? String(object["home.kitchen.counter_type"])
        : undefined,
      kitchenRemodel: isSet(object["home.kitchen.remodel"]) ? String(object["home.kitchen.remodel"]) : undefined,
      leasedSolarPanelsTriage: isSet(object["home.leased_solar_panels.triage"])
        ? String(object["home.leased_solar_panels.triage"])
        : undefined,
      lotOwnership: isSet(object["home.lot_ownership"]) ? String(object["home.lot_ownership"]) : undefined,
      masterBedroomFloor: isSet(object["home.master_bedroom_floor"])
        ? Number(object["home.master_bedroom_floor"])
        : undefined,
      poolType: isSet(object["home.pool_type"]) ? String(object["home.pool_type"]) : undefined,
      sharedEntrance: isSet(object["home.shared_entrance"]) ? Boolean(object["home.shared_entrance"]) : undefined,
      solarPanelsOwnershipType: isSet(object["home.solar_panels.ownership_type"])
        ? String(object["home.solar_panels.ownership_type"])
        : undefined,
      sqFt: isSet(object["home.sq_ft"]) ? Number(object["home.sq_ft"]) : undefined,
      aboveGradeSqFt: isSet(object["home.above_grade_sq_ft"]) ? Number(object["home.above_grade_sq_ft"]) : undefined,
      sqFtTriage: isSet(object["home.sq_ft.triage"]) ? String(object["home.sq_ft.triage"]) : undefined,
      yearBuilt: isSet(object["home.year_built"]) ? Number(object["home.year_built"]) : undefined,
      selfServiceVirtualAssessmentChoice: isSet(object["seller.ssva_opt_in"])
        ? String(object["seller.ssva_opt_in"])
        : undefined,
      kitchenSellerScore: isSet(object["home.kitchen_seller_score"])
        ? String(object["home.kitchen_seller_score"])
        : undefined,
      bathroomSellerScore: isSet(object["home.bathroom_seller_score"])
        ? String(object["home.bathroom_seller_score"])
        : undefined,
      livingRoomSellerScore: isSet(object["home.living_room_seller_score"])
        ? String(object["home.living_room_seller_score"])
        : undefined,
      exteriorSellerScore: isSet(object["home.exterior_seller_score"])
        ? String(object["home.exterior_seller_score"])
        : undefined,
      activelyLooking: isSet(object["seller.actively_looking"]) ? String(object["seller.actively_looking"]) : undefined,
      agent: isSet(object["seller.agent"]) ? String(object["seller.agent"]) : undefined,
      brokerage: isSet(object["seller.brokerage"]) ? String(object["seller.brokerage"]) : undefined,
      email: isSet(object["seller.email"]) ? String(object["seller.email"]) : undefined,
      signedListingAgreement: isSet(object["seller.signed_listing_agreement"])
        ? Boolean(object["seller.signed_listing_agreement"])
        : undefined,
      priceEstimateRange: isSet(object["seller.price_estimate_range"])
        ? Answers_PriceEstimateRange.fromJSON(object["seller.price_estimate_range"])
        : undefined,
      optedIntoExclusiveOffers: isSet(object["seller.opted_into_exclusive_offers"])
        ? Boolean(object["seller.opted_into_exclusive_offers"])
        : undefined,
      buyingNextHome: isSet(object["seller.buying_next_home"]) ? Boolean(object["seller.buying_next_home"]) : undefined,
      buyingNextHomeStatus: isSet(object["seller.buying_next_home_status"])
        ? String(object["seller.buying_next_home_status"])
        : undefined,
      sellingTimelineFlexibility: isSet(object["seller.selling_timeline_flexibility"])
        ? String(object["seller.selling_timeline_flexibility"])
        : undefined,
      willingnessForTours: isSet(object["seller.willingness_for_tours"])
        ? String(object["seller.willingness_for_tours"])
        : undefined,
      listingAgentEmail: isSet(object["seller.listing_agent.email"])
        ? String(object["seller.listing_agent.email"])
        : undefined,
      listingAgentFullName: isSet(object["seller.listing_agent.full_name"])
        ? String(object["seller.listing_agent.full_name"])
        : undefined,
      financingReferral: isSet(object["seller.financing_referral"])
        ? String(object["seller.financing_referral"])
        : undefined,
      financingReferralOpendoorMortgage: isSet(object["seller.financing_referral_opendoor_mortgage"])
        ? String(object["seller.financing_referral_opendoor_mortgage"])
        : undefined,
      financingReferralTrustedLender: isSet(object["seller.financing_referral_trusted_lender"])
        ? String(object["seller.financing_referral_trusted_lender"])
        : undefined,
      firstName: isSet(object["seller.first_name"]) ? String(object["seller.first_name"]) : undefined,
      fullName: isSet(object["seller.full_name"]) ? String(object["seller.full_name"]) : undefined,
      homeBuilder: isSet(object["seller.home_builder"]) ? String(object["seller.home_builder"]) : undefined,
      homeBuilderCommunity: isSet(object["seller.home_builder_community"])
        ? String(object["seller.home_builder_community"])
        : undefined,
      homeBuilderEmail: isSet(object["seller.home_builder_email"])
        ? String(object["seller.home_builder_email"])
        : undefined,
      homeBuilderOther: isSet(object["seller.home_builder.other"])
        ? String(object["seller.home_builder.other"])
        : undefined,
      homeBuyingProcess: isSet(object["seller.home_buying_process"])
        ? String(object["seller.home_buying_process"])
        : undefined,
      homeBuyingProcessOther: isSet(object["seller.home_buying_process.other"])
        ? String(object["seller.home_buying_process.other"])
        : undefined,
      initialFinancingStatus: isSet(object["seller.initial_financing_status"])
        ? String(object["seller.initial_financing_status"])
        : undefined,
      lastName: isSet(object["seller.last_name"]) ? String(object["seller.last_name"]) : undefined,
      mlsAgentId: isSet(object["seller.mls_agent_id"]) ? String(object["seller.mls_agent_id"]) : undefined,
      mortgageAbad: isSet(object["seller.mortgage_abad"]) ? String(object["seller.mortgage_abad"]) : undefined,
      moveInReady: isSet(object["seller.move_in_ready"]) ? String(object["seller.move_in_ready"]) : undefined,
      movingAway: isSet(object["seller.moving_away"]) ? String(object["seller.moving_away"]) : undefined,
      otherInformation: isSet(object["seller.other_information"])
        ? String(object["seller.other_information"])
        : undefined,
      otherSellingWays: isSet(object["seller.other_selling_ways"])
        ? String(object["seller.other_selling_ways"])
        : undefined,
      phoneNumber: isSet(object["seller.phone_number"]) ? String(object["seller.phone_number"]) : undefined,
      priceEstimateCents: isSet(object["seller.price_estimate_cents"])
        ? Number(object["seller.price_estimate_cents"])
        : undefined,
      priceEstimateTriage: isSet(object["seller.price_estimate.triage"])
        ? String(object["seller.price_estimate.triage"])
        : undefined,
      realtorEmailOverride: isSet(object["seller.realtor_email_override"])
        ? Boolean(object["seller.realtor_email_override"])
        : undefined,
      realtorNameOverride: isSet(object["seller.realtor_name_override"])
        ? Boolean(object["seller.realtor_name_override"])
        : undefined,
      referralSource: isSet(object["seller.referral_source"]) ? String(object["seller.referral_source"]) : undefined,
      referralSourceOther: isSet(object["seller.referral_source.other"])
        ? String(object["seller.referral_source.other"])
        : undefined,
      referralSourceRadio: isSet(object["seller.referral_source_radio"])
        ? String(object["seller.referral_source_radio"])
        : undefined,
      relationToOwner: isSet(object["seller.relation_to_owner"])
        ? String(object["seller.relation_to_owner"])
        : undefined,
      relationToOwnerOther: isSet(object["seller.relation_to_owner.other"])
        ? String(object["seller.relation_to_owner.other"])
        : undefined,
      saleTimeline: isSet(object["seller.sale_timeline"]) ? String(object["seller.sale_timeline"]) : undefined,
      scheduleOfferReview: isSet(object.schedule_offer_review) ? String(object.schedule_offer_review) : undefined,
      sellingReasonOther: isSet(object["seller.selling_reason.other"])
        ? String(object["seller.selling_reason.other"])
        : undefined,
      workingWithHomeBuilder: isSet(object["seller.working_with_home_builder"])
        ? Boolean(object["seller.working_with_home_builder"])
        : undefined,
      verificationPrimaryOwner: isSet(object["verification.primary_owner"])
        ? String(object["verification.primary_owner"])
        : undefined,
      bathroomFeatures: isSet(object["home.bathroom.features"])
        ? Answers_BathroomFeatures.fromJSON(object["home.bathroom.features"])
        : undefined,
      eligibilityCriteria: isSet(object["home.eligibility_criteria"])
        ? Answers_EligibilityCriteria.fromJSON(object["home.eligibility_criteria"])
        : undefined,
      floodHistoryTriage: isSet(object["home.flood_history.triage"])
        ? Answers_FloodHistoryTriage.fromJSON(object["home.flood_history.triage"])
        : undefined,
      flooringAllFloorTypes: isSet(object["home.flooring.all_floor_types"])
        ? Answers_FloorTypes.fromJSON(object["home.flooring.all_floor_types"])
        : undefined,
      flooringCarpetRooms: isSet(object["home.flooring.carpet_rooms"])
        ? Answers_Rooms.fromJSON(object["home.flooring.carpet_rooms"])
        : undefined,
      flooringHardwoodRooms: isSet(object["home.flooring.hardwood_rooms"])
        ? Answers_Rooms.fromJSON(object["home.flooring.hardwood_rooms"])
        : undefined,
      flooringLaminateRooms: isSet(object["home.flooring.laminate_rooms"])
        ? Answers_Rooms.fromJSON(object["home.flooring.laminate_rooms"])
        : undefined,
      flooringOtherRooms: isSet(object["home.flooring.other_rooms"])
        ? Answers_Rooms.fromJSON(object["home.flooring.other_rooms"])
        : undefined,
      flooringTileRooms: isSet(object["home.flooring.tile_rooms"])
        ? Answers_Rooms.fromJSON(object["home.flooring.tile_rooms"])
        : undefined,
      flooringMaterials: Array.isArray(object?.["home.flooring_materials"])
        ? object["home.flooring_materials"].map((e: any) => String(e))
        : [],
      kitchenFeatures: isSet(object["home.kitchen.features"])
        ? Answers_KitchenFeatures.fromJSON(object["home.kitchen.features"])
        : undefined,
      photosUploaded: Array.isArray(object?.["home.photos.uploaded"])
        ? object["home.photos.uploaded"].map((e: any) => Answers_Photo.fromJSON(e))
        : [],
      sellingReason: isSet(object["seller.selling_reason"])
        ? Answers_SellingReason.fromJSON(object["seller.selling_reason"])
        : undefined,
      exploringFinancing: isSet(object["seller.exploring_financing"])
        ? String(object["seller.exploring_financing"])
        : undefined,
      buyerZones: Array.isArray(object?.["seller.setup_buyer_feed.zones"])
        ? object["seller.setup_buyer_feed.zones"].map((e: any) => Answers_BuyerZone.fromJSON(e))
        : [],
      appSmsOptIn: isSet(object["seller.app_sms_opt_in"]) ? Boolean(object["seller.app_sms_opt_in"]) : undefined,
      setupBuyerFeed: isSet(object["seller.setup_buyer_feed"])
        ? Answers_SetupBuyerFeed.fromJSON(object["seller.setup_buyer_feed"])
        : undefined,
      buyingPower: isSet(object["seller.buying_power"])
        ? Answers_BuyingPower.fromJSON(object["seller.buying_power"])
        : undefined,
      buyingTimeline: isSet(object["seller.buying_timeline"]) ? String(object["seller.buying_timeline"]) : undefined,
      buyingStage: isSet(object["seller.buying_stage"]) ? String(object["seller.buying_stage"]) : undefined,
      agentStatus: isSet(object["seller.agent_status"]) ? String(object["seller.agent_status"]) : undefined,
      agentType: isSet(object["seller.agent_type"]) ? String(object["seller.agent_type"]) : undefined,
    };
  },

  toJSON(message: Answers): unknown {
    const obj: any = {};
    message.addition !== undefined && (obj["home.addition"] = message.addition);
    message.backYardCondition !== undefined && (obj["home.back_yard.condition"] = message.backYardCondition);
    message.backYardSlope !== undefined && (obj["home.back_yard.slope"] = message.backYardSlope);
    message.backYardSlopeRetainingWall !== undefined &&
      (obj["home.back_yard.slope.retaining_wall"] = message.backYardSlopeRetainingWall);
    message.backYardSlopeType !== undefined && (obj["home.back_yard.slope.type"] = message.backYardSlopeType);
    message.basement !== undefined && (obj["home.basement"] = message.basement);
    message.basementFinishedSqFt !== undefined && (obj["home.basement_finished_sq_ft"] = message.basementFinishedSqFt);
    message.basementSqFt !== undefined && (obj["home.basement_sq_ft"] = message.basementSqFt);
    message.basementSqFtTriage !== undefined && (obj["home.basement_sq_ft.triage"] = message.basementSqFtTriage);
    message.bathroomCondition !== undefined && (obj["home.bathroom.condition"] = message.bathroomCondition);
    message.masterBathroomCondition !== undefined &&
      (obj["home.master_bathroom.condition"] = message.masterBathroomCondition);
    message.bathroomRemodel !== undefined && (obj["home.bathroom.remodel"] = message.bathroomRemodel);
    message.bathrooms !== undefined && (obj["home.bathrooms"] = message.bathrooms);
    message.bathroomsFull !== undefined && (obj["home.bathrooms.full"] = message.bathroomsFull);
    message.bathroomsHalf !== undefined && (obj["home.bathrooms.half"] = message.bathroomsHalf);
    message.bedrooms !== undefined && (obj["home.bedrooms"] = message.bedrooms);
    message.bedroomsTriage !== undefined && (obj["home.bedrooms.triage"] = message.bedroomsTriage);
    message.carportSpaces !== undefined && (obj["home.carport_spaces"] = message.carportSpaces);
    message.dwellingType !== undefined && (obj["home.dwelling_type"] = message.dwellingType);
    message.entryTypes !== undefined &&
      (obj["home.entry_type"] = message.entryTypes ? Answers_EntryTypes.toJSON(message.entryTypes) : undefined);
    message.exteriorFacade !== undefined && (obj["home.exterior_facade"] = message.exteriorFacade);
    message.exteriorStories !== undefined && (obj["home.exterior_stories"] = message.exteriorStories);
    message.floodZoneTriage !== undefined && (obj["home.flood_zone.triage"] = message.floodZoneTriage);
    message.flooringCarpetCondition !== undefined &&
      (obj["home.flooring.carpet_condition"] = message.flooringCarpetCondition);
    message.flooringFollowUpLiving !== undefined &&
      (obj["home.flooring.follow_up_living"] = message.flooringFollowUpLiving);
    message.flooringFollowUpMasterBedroom !== undefined &&
      (obj["home.flooring.follow_up_master_bedroom"] = message.flooringFollowUpMasterBedroom);
    message.flooringHardwoodCondition !== undefined &&
      (obj["home.flooring.hardwood_condition"] = message.flooringHardwoodCondition);
    message.flooringLaminateCondition !== undefined &&
      (obj["home.flooring.laminate_condition"] = message.flooringLaminateCondition);
    message.flooringPrimaryFloorCondition !== undefined &&
      (obj["home.flooring.primary_floor_condition"] = message.flooringPrimaryFloorCondition);
    message.flooringPrimaryFloorType !== undefined &&
      (obj["home.flooring.primary_floor_type"] = message.flooringPrimaryFloorType);
    message.flooringSecondaryFloorCondition !== undefined &&
      (obj["home.flooring.secondary_floor_condition"] = message.flooringSecondaryFloorCondition);
    message.flooringSecondaryFloorType !== undefined &&
      (obj["home.flooring.secondary_floor_type"] = message.flooringSecondaryFloorType);
    message.flooringTileCondition !== undefined &&
      (obj["home.flooring.tile_condition"] = message.flooringTileCondition);
    message.foundationIssue !== undefined && (obj["home.foundation_issue"] = message.foundationIssue);
    message.garageSpaces !== undefined && (obj["home.garage_spaces"] = message.garageSpaces);
    message.hoa !== undefined && (obj["home.hoa"] = message.hoa);
    message.hoaTypes !== undefined &&
      (obj["home.hoa_type"] = message.hoaTypes ? Answers_HoaTypes.toJSON(message.hoaTypes) : undefined);
    message.interiorPaintCondition !== undefined &&
      (obj["home.interior_paint.condition"] = message.interiorPaintCondition);
    message.interiorPaintNeutral !== undefined && (obj["home.interior_paint.neutral"] = message.interiorPaintNeutral);
    message.kitchenApplianceType !== undefined && (obj["home.kitchen.appliance_type"] = message.kitchenApplianceType);
    message.kitchenCondition !== undefined && (obj["home.kitchen.condition"] = message.kitchenCondition);
    message.kitchenCounterType !== undefined && (obj["home.kitchen.counter_type"] = message.kitchenCounterType);
    message.kitchenRemodel !== undefined && (obj["home.kitchen.remodel"] = message.kitchenRemodel);
    message.leasedSolarPanelsTriage !== undefined &&
      (obj["home.leased_solar_panels.triage"] = message.leasedSolarPanelsTriage);
    message.lotOwnership !== undefined && (obj["home.lot_ownership"] = message.lotOwnership);
    message.masterBedroomFloor !== undefined && (obj["home.master_bedroom_floor"] = message.masterBedroomFloor);
    message.poolType !== undefined && (obj["home.pool_type"] = message.poolType);
    message.sharedEntrance !== undefined && (obj["home.shared_entrance"] = message.sharedEntrance);
    message.solarPanelsOwnershipType !== undefined &&
      (obj["home.solar_panels.ownership_type"] = message.solarPanelsOwnershipType);
    message.sqFt !== undefined && (obj["home.sq_ft"] = message.sqFt);
    message.aboveGradeSqFt !== undefined && (obj["home.above_grade_sq_ft"] = message.aboveGradeSqFt);
    message.sqFtTriage !== undefined && (obj["home.sq_ft.triage"] = message.sqFtTriage);
    message.yearBuilt !== undefined && (obj["home.year_built"] = message.yearBuilt);
    message.selfServiceVirtualAssessmentChoice !== undefined &&
      (obj["seller.ssva_opt_in"] = message.selfServiceVirtualAssessmentChoice);
    message.kitchenSellerScore !== undefined && (obj["home.kitchen_seller_score"] = message.kitchenSellerScore);
    message.bathroomSellerScore !== undefined && (obj["home.bathroom_seller_score"] = message.bathroomSellerScore);
    message.livingRoomSellerScore !== undefined &&
      (obj["home.living_room_seller_score"] = message.livingRoomSellerScore);
    message.exteriorSellerScore !== undefined && (obj["home.exterior_seller_score"] = message.exteriorSellerScore);
    message.activelyLooking !== undefined && (obj["seller.actively_looking"] = message.activelyLooking);
    message.agent !== undefined && (obj["seller.agent"] = message.agent);
    message.brokerage !== undefined && (obj["seller.brokerage"] = message.brokerage);
    message.email !== undefined && (obj["seller.email"] = message.email);
    message.signedListingAgreement !== undefined &&
      (obj["seller.signed_listing_agreement"] = message.signedListingAgreement);
    message.priceEstimateRange !== undefined && (obj["seller.price_estimate_range"] = message.priceEstimateRange
      ? Answers_PriceEstimateRange.toJSON(message.priceEstimateRange)
      : undefined);
    message.optedIntoExclusiveOffers !== undefined &&
      (obj["seller.opted_into_exclusive_offers"] = message.optedIntoExclusiveOffers);
    message.buyingNextHome !== undefined && (obj["seller.buying_next_home"] = message.buyingNextHome);
    message.buyingNextHomeStatus !== undefined &&
      (obj["seller.buying_next_home_status"] = message.buyingNextHomeStatus);
    message.sellingTimelineFlexibility !== undefined &&
      (obj["seller.selling_timeline_flexibility"] = message.sellingTimelineFlexibility);
    message.willingnessForTours !== undefined && (obj["seller.willingness_for_tours"] = message.willingnessForTours);
    message.listingAgentEmail !== undefined && (obj["seller.listing_agent.email"] = message.listingAgentEmail);
    message.listingAgentFullName !== undefined &&
      (obj["seller.listing_agent.full_name"] = message.listingAgentFullName);
    message.financingReferral !== undefined && (obj["seller.financing_referral"] = message.financingReferral);
    message.financingReferralOpendoorMortgage !== undefined &&
      (obj["seller.financing_referral_opendoor_mortgage"] = message.financingReferralOpendoorMortgage);
    message.financingReferralTrustedLender !== undefined &&
      (obj["seller.financing_referral_trusted_lender"] = message.financingReferralTrustedLender);
    message.firstName !== undefined && (obj["seller.first_name"] = message.firstName);
    message.fullName !== undefined && (obj["seller.full_name"] = message.fullName);
    message.homeBuilder !== undefined && (obj["seller.home_builder"] = message.homeBuilder);
    message.homeBuilderCommunity !== undefined && (obj["seller.home_builder_community"] = message.homeBuilderCommunity);
    message.homeBuilderEmail !== undefined && (obj["seller.home_builder_email"] = message.homeBuilderEmail);
    message.homeBuilderOther !== undefined && (obj["seller.home_builder.other"] = message.homeBuilderOther);
    message.homeBuyingProcess !== undefined && (obj["seller.home_buying_process"] = message.homeBuyingProcess);
    message.homeBuyingProcessOther !== undefined &&
      (obj["seller.home_buying_process.other"] = message.homeBuyingProcessOther);
    message.initialFinancingStatus !== undefined &&
      (obj["seller.initial_financing_status"] = message.initialFinancingStatus);
    message.lastName !== undefined && (obj["seller.last_name"] = message.lastName);
    message.mlsAgentId !== undefined && (obj["seller.mls_agent_id"] = message.mlsAgentId);
    message.mortgageAbad !== undefined && (obj["seller.mortgage_abad"] = message.mortgageAbad);
    message.moveInReady !== undefined && (obj["seller.move_in_ready"] = message.moveInReady);
    message.movingAway !== undefined && (obj["seller.moving_away"] = message.movingAway);
    message.otherInformation !== undefined && (obj["seller.other_information"] = message.otherInformation);
    message.otherSellingWays !== undefined && (obj["seller.other_selling_ways"] = message.otherSellingWays);
    message.phoneNumber !== undefined && (obj["seller.phone_number"] = message.phoneNumber);
    message.priceEstimateCents !== undefined && (obj["seller.price_estimate_cents"] = message.priceEstimateCents);
    message.priceEstimateTriage !== undefined && (obj["seller.price_estimate.triage"] = message.priceEstimateTriage);
    message.realtorEmailOverride !== undefined && (obj["seller.realtor_email_override"] = message.realtorEmailOverride);
    message.realtorNameOverride !== undefined && (obj["seller.realtor_name_override"] = message.realtorNameOverride);
    message.referralSource !== undefined && (obj["seller.referral_source"] = message.referralSource);
    message.referralSourceOther !== undefined && (obj["seller.referral_source.other"] = message.referralSourceOther);
    message.referralSourceRadio !== undefined && (obj["seller.referral_source_radio"] = message.referralSourceRadio);
    message.relationToOwner !== undefined && (obj["seller.relation_to_owner"] = message.relationToOwner);
    message.relationToOwnerOther !== undefined &&
      (obj["seller.relation_to_owner.other"] = message.relationToOwnerOther);
    message.saleTimeline !== undefined && (obj["seller.sale_timeline"] = message.saleTimeline);
    message.scheduleOfferReview !== undefined && (obj.schedule_offer_review = message.scheduleOfferReview);
    message.sellingReasonOther !== undefined && (obj["seller.selling_reason.other"] = message.sellingReasonOther);
    message.workingWithHomeBuilder !== undefined &&
      (obj["seller.working_with_home_builder"] = message.workingWithHomeBuilder);
    message.verificationPrimaryOwner !== undefined &&
      (obj["verification.primary_owner"] = message.verificationPrimaryOwner);
    message.bathroomFeatures !== undefined && (obj["home.bathroom.features"] = message.bathroomFeatures
      ? Answers_BathroomFeatures.toJSON(message.bathroomFeatures)
      : undefined);
    message.eligibilityCriteria !== undefined && (obj["home.eligibility_criteria"] = message.eligibilityCriteria
      ? Answers_EligibilityCriteria.toJSON(message.eligibilityCriteria)
      : undefined);
    message.floodHistoryTriage !== undefined && (obj["home.flood_history.triage"] = message.floodHistoryTriage
      ? Answers_FloodHistoryTriage.toJSON(message.floodHistoryTriage)
      : undefined);
    message.flooringAllFloorTypes !== undefined && (obj["home.flooring.all_floor_types"] = message.flooringAllFloorTypes
      ? Answers_FloorTypes.toJSON(message.flooringAllFloorTypes)
      : undefined);
    message.flooringCarpetRooms !== undefined && (obj["home.flooring.carpet_rooms"] = message.flooringCarpetRooms
      ? Answers_Rooms.toJSON(message.flooringCarpetRooms)
      : undefined);
    message.flooringHardwoodRooms !== undefined && (obj["home.flooring.hardwood_rooms"] = message.flooringHardwoodRooms
      ? Answers_Rooms.toJSON(message.flooringHardwoodRooms)
      : undefined);
    message.flooringLaminateRooms !== undefined && (obj["home.flooring.laminate_rooms"] = message.flooringLaminateRooms
      ? Answers_Rooms.toJSON(message.flooringLaminateRooms)
      : undefined);
    message.flooringOtherRooms !== undefined && (obj["home.flooring.other_rooms"] = message.flooringOtherRooms
      ? Answers_Rooms.toJSON(message.flooringOtherRooms)
      : undefined);
    message.flooringTileRooms !== undefined && (obj["home.flooring.tile_rooms"] = message.flooringTileRooms
      ? Answers_Rooms.toJSON(message.flooringTileRooms)
      : undefined);
    if (message.flooringMaterials) {
      obj["home.flooring_materials"] = message.flooringMaterials.map((e) => e);
    } else {
      obj["home.flooring_materials"] = [];
    }
    message.kitchenFeatures !== undefined && (obj["home.kitchen.features"] = message.kitchenFeatures
      ? Answers_KitchenFeatures.toJSON(message.kitchenFeatures)
      : undefined);
    if (message.photosUploaded) {
      obj["home.photos.uploaded"] = message.photosUploaded.map((e) => e ? Answers_Photo.toJSON(e) : undefined);
    } else {
      obj["home.photos.uploaded"] = [];
    }
    message.sellingReason !== undefined && (obj["seller.selling_reason"] = message.sellingReason
      ? Answers_SellingReason.toJSON(message.sellingReason)
      : undefined);
    message.exploringFinancing !== undefined && (obj["seller.exploring_financing"] = message.exploringFinancing);
    if (message.buyerZones) {
      obj["seller.setup_buyer_feed.zones"] = message.buyerZones.map((e) => e ? Answers_BuyerZone.toJSON(e) : undefined);
    } else {
      obj["seller.setup_buyer_feed.zones"] = [];
    }
    message.appSmsOptIn !== undefined && (obj["seller.app_sms_opt_in"] = message.appSmsOptIn);
    message.setupBuyerFeed !== undefined && (obj["seller.setup_buyer_feed"] = message.setupBuyerFeed
      ? Answers_SetupBuyerFeed.toJSON(message.setupBuyerFeed)
      : undefined);
    message.buyingPower !== undefined &&
      (obj["seller.buying_power"] = message.buyingPower ? Answers_BuyingPower.toJSON(message.buyingPower) : undefined);
    message.buyingTimeline !== undefined && (obj["seller.buying_timeline"] = message.buyingTimeline);
    message.buyingStage !== undefined && (obj["seller.buying_stage"] = message.buyingStage);
    message.agentStatus !== undefined && (obj["seller.agent_status"] = message.agentStatus);
    message.agentType !== undefined && (obj["seller.agent_type"] = message.agentType);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers>, I>>(base?: I): Answers {
    return Answers.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers>, I>>(object: I): Answers {
    const message = createBaseAnswers();
    message.addition = object.addition ?? undefined;
    message.backYardCondition = object.backYardCondition ?? undefined;
    message.backYardSlope = object.backYardSlope ?? undefined;
    message.backYardSlopeRetainingWall = object.backYardSlopeRetainingWall ?? undefined;
    message.backYardSlopeType = object.backYardSlopeType ?? undefined;
    message.basement = object.basement ?? undefined;
    message.basementFinishedSqFt = object.basementFinishedSqFt ?? undefined;
    message.basementSqFt = object.basementSqFt ?? undefined;
    message.basementSqFtTriage = object.basementSqFtTriage ?? undefined;
    message.bathroomCondition = object.bathroomCondition ?? undefined;
    message.masterBathroomCondition = object.masterBathroomCondition ?? undefined;
    message.bathroomRemodel = object.bathroomRemodel ?? undefined;
    message.bathrooms = object.bathrooms ?? undefined;
    message.bathroomsFull = object.bathroomsFull ?? undefined;
    message.bathroomsHalf = object.bathroomsHalf ?? undefined;
    message.bedrooms = object.bedrooms ?? undefined;
    message.bedroomsTriage = object.bedroomsTriage ?? undefined;
    message.carportSpaces = object.carportSpaces ?? undefined;
    message.dwellingType = object.dwellingType ?? undefined;
    message.entryTypes = (object.entryTypes !== undefined && object.entryTypes !== null)
      ? Answers_EntryTypes.fromPartial(object.entryTypes)
      : undefined;
    message.exteriorFacade = object.exteriorFacade ?? undefined;
    message.exteriorStories = object.exteriorStories ?? undefined;
    message.floodZoneTriage = object.floodZoneTriage ?? undefined;
    message.flooringCarpetCondition = object.flooringCarpetCondition ?? undefined;
    message.flooringFollowUpLiving = object.flooringFollowUpLiving ?? undefined;
    message.flooringFollowUpMasterBedroom = object.flooringFollowUpMasterBedroom ?? undefined;
    message.flooringHardwoodCondition = object.flooringHardwoodCondition ?? undefined;
    message.flooringLaminateCondition = object.flooringLaminateCondition ?? undefined;
    message.flooringPrimaryFloorCondition = object.flooringPrimaryFloorCondition ?? undefined;
    message.flooringPrimaryFloorType = object.flooringPrimaryFloorType ?? undefined;
    message.flooringSecondaryFloorCondition = object.flooringSecondaryFloorCondition ?? undefined;
    message.flooringSecondaryFloorType = object.flooringSecondaryFloorType ?? undefined;
    message.flooringTileCondition = object.flooringTileCondition ?? undefined;
    message.foundationIssue = object.foundationIssue ?? undefined;
    message.garageSpaces = object.garageSpaces ?? undefined;
    message.hoa = object.hoa ?? undefined;
    message.hoaTypes = (object.hoaTypes !== undefined && object.hoaTypes !== null)
      ? Answers_HoaTypes.fromPartial(object.hoaTypes)
      : undefined;
    message.interiorPaintCondition = object.interiorPaintCondition ?? undefined;
    message.interiorPaintNeutral = object.interiorPaintNeutral ?? undefined;
    message.kitchenApplianceType = object.kitchenApplianceType ?? undefined;
    message.kitchenCondition = object.kitchenCondition ?? undefined;
    message.kitchenCounterType = object.kitchenCounterType ?? undefined;
    message.kitchenRemodel = object.kitchenRemodel ?? undefined;
    message.leasedSolarPanelsTriage = object.leasedSolarPanelsTriage ?? undefined;
    message.lotOwnership = object.lotOwnership ?? undefined;
    message.masterBedroomFloor = object.masterBedroomFloor ?? undefined;
    message.poolType = object.poolType ?? undefined;
    message.sharedEntrance = object.sharedEntrance ?? undefined;
    message.solarPanelsOwnershipType = object.solarPanelsOwnershipType ?? undefined;
    message.sqFt = object.sqFt ?? undefined;
    message.aboveGradeSqFt = object.aboveGradeSqFt ?? undefined;
    message.sqFtTriage = object.sqFtTriage ?? undefined;
    message.yearBuilt = object.yearBuilt ?? undefined;
    message.selfServiceVirtualAssessmentChoice = object.selfServiceVirtualAssessmentChoice ?? undefined;
    message.kitchenSellerScore = object.kitchenSellerScore ?? undefined;
    message.bathroomSellerScore = object.bathroomSellerScore ?? undefined;
    message.livingRoomSellerScore = object.livingRoomSellerScore ?? undefined;
    message.exteriorSellerScore = object.exteriorSellerScore ?? undefined;
    message.activelyLooking = object.activelyLooking ?? undefined;
    message.agent = object.agent ?? undefined;
    message.brokerage = object.brokerage ?? undefined;
    message.email = object.email ?? undefined;
    message.signedListingAgreement = object.signedListingAgreement ?? undefined;
    message.priceEstimateRange = (object.priceEstimateRange !== undefined && object.priceEstimateRange !== null)
      ? Answers_PriceEstimateRange.fromPartial(object.priceEstimateRange)
      : undefined;
    message.optedIntoExclusiveOffers = object.optedIntoExclusiveOffers ?? undefined;
    message.buyingNextHome = object.buyingNextHome ?? undefined;
    message.buyingNextHomeStatus = object.buyingNextHomeStatus ?? undefined;
    message.sellingTimelineFlexibility = object.sellingTimelineFlexibility ?? undefined;
    message.willingnessForTours = object.willingnessForTours ?? undefined;
    message.listingAgentEmail = object.listingAgentEmail ?? undefined;
    message.listingAgentFullName = object.listingAgentFullName ?? undefined;
    message.financingReferral = object.financingReferral ?? undefined;
    message.financingReferralOpendoorMortgage = object.financingReferralOpendoorMortgage ?? undefined;
    message.financingReferralTrustedLender = object.financingReferralTrustedLender ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.fullName = object.fullName ?? undefined;
    message.homeBuilder = object.homeBuilder ?? undefined;
    message.homeBuilderCommunity = object.homeBuilderCommunity ?? undefined;
    message.homeBuilderEmail = object.homeBuilderEmail ?? undefined;
    message.homeBuilderOther = object.homeBuilderOther ?? undefined;
    message.homeBuyingProcess = object.homeBuyingProcess ?? undefined;
    message.homeBuyingProcessOther = object.homeBuyingProcessOther ?? undefined;
    message.initialFinancingStatus = object.initialFinancingStatus ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.mlsAgentId = object.mlsAgentId ?? undefined;
    message.mortgageAbad = object.mortgageAbad ?? undefined;
    message.moveInReady = object.moveInReady ?? undefined;
    message.movingAway = object.movingAway ?? undefined;
    message.otherInformation = object.otherInformation ?? undefined;
    message.otherSellingWays = object.otherSellingWays ?? undefined;
    message.phoneNumber = object.phoneNumber ?? undefined;
    message.priceEstimateCents = object.priceEstimateCents ?? undefined;
    message.priceEstimateTriage = object.priceEstimateTriage ?? undefined;
    message.realtorEmailOverride = object.realtorEmailOverride ?? undefined;
    message.realtorNameOverride = object.realtorNameOverride ?? undefined;
    message.referralSource = object.referralSource ?? undefined;
    message.referralSourceOther = object.referralSourceOther ?? undefined;
    message.referralSourceRadio = object.referralSourceRadio ?? undefined;
    message.relationToOwner = object.relationToOwner ?? undefined;
    message.relationToOwnerOther = object.relationToOwnerOther ?? undefined;
    message.saleTimeline = object.saleTimeline ?? undefined;
    message.scheduleOfferReview = object.scheduleOfferReview ?? undefined;
    message.sellingReasonOther = object.sellingReasonOther ?? undefined;
    message.workingWithHomeBuilder = object.workingWithHomeBuilder ?? undefined;
    message.verificationPrimaryOwner = object.verificationPrimaryOwner ?? undefined;
    message.bathroomFeatures = (object.bathroomFeatures !== undefined && object.bathroomFeatures !== null)
      ? Answers_BathroomFeatures.fromPartial(object.bathroomFeatures)
      : undefined;
    message.eligibilityCriteria = (object.eligibilityCriteria !== undefined && object.eligibilityCriteria !== null)
      ? Answers_EligibilityCriteria.fromPartial(object.eligibilityCriteria)
      : undefined;
    message.floodHistoryTriage = (object.floodHistoryTriage !== undefined && object.floodHistoryTriage !== null)
      ? Answers_FloodHistoryTriage.fromPartial(object.floodHistoryTriage)
      : undefined;
    message.flooringAllFloorTypes =
      (object.flooringAllFloorTypes !== undefined && object.flooringAllFloorTypes !== null)
        ? Answers_FloorTypes.fromPartial(object.flooringAllFloorTypes)
        : undefined;
    message.flooringCarpetRooms = (object.flooringCarpetRooms !== undefined && object.flooringCarpetRooms !== null)
      ? Answers_Rooms.fromPartial(object.flooringCarpetRooms)
      : undefined;
    message.flooringHardwoodRooms =
      (object.flooringHardwoodRooms !== undefined && object.flooringHardwoodRooms !== null)
        ? Answers_Rooms.fromPartial(object.flooringHardwoodRooms)
        : undefined;
    message.flooringLaminateRooms =
      (object.flooringLaminateRooms !== undefined && object.flooringLaminateRooms !== null)
        ? Answers_Rooms.fromPartial(object.flooringLaminateRooms)
        : undefined;
    message.flooringOtherRooms = (object.flooringOtherRooms !== undefined && object.flooringOtherRooms !== null)
      ? Answers_Rooms.fromPartial(object.flooringOtherRooms)
      : undefined;
    message.flooringTileRooms = (object.flooringTileRooms !== undefined && object.flooringTileRooms !== null)
      ? Answers_Rooms.fromPartial(object.flooringTileRooms)
      : undefined;
    message.flooringMaterials = object.flooringMaterials?.map((e) => e) || [];
    message.kitchenFeatures = (object.kitchenFeatures !== undefined && object.kitchenFeatures !== null)
      ? Answers_KitchenFeatures.fromPartial(object.kitchenFeatures)
      : undefined;
    message.photosUploaded = object.photosUploaded?.map((e) => Answers_Photo.fromPartial(e)) || [];
    message.sellingReason = (object.sellingReason !== undefined && object.sellingReason !== null)
      ? Answers_SellingReason.fromPartial(object.sellingReason)
      : undefined;
    message.exploringFinancing = object.exploringFinancing ?? undefined;
    message.buyerZones = object.buyerZones?.map((e) => Answers_BuyerZone.fromPartial(e)) || [];
    message.appSmsOptIn = object.appSmsOptIn ?? undefined;
    message.setupBuyerFeed = (object.setupBuyerFeed !== undefined && object.setupBuyerFeed !== null)
      ? Answers_SetupBuyerFeed.fromPartial(object.setupBuyerFeed)
      : undefined;
    message.buyingPower = (object.buyingPower !== undefined && object.buyingPower !== null)
      ? Answers_BuyingPower.fromPartial(object.buyingPower)
      : undefined;
    message.buyingTimeline = object.buyingTimeline ?? undefined;
    message.buyingStage = object.buyingStage ?? undefined;
    message.agentStatus = object.agentStatus ?? undefined;
    message.agentType = object.agentType ?? undefined;
    return message;
  },
};

function createBaseAnswers_BathroomFeatures(): Answers_BathroomFeatures {
  return {
    doubleSink: undefined,
    graniteCountertops: undefined,
    separateShowerTub: undefined,
    tileSurround: undefined,
    updatedTileFloors: undefined,
  };
}

export const Answers_BathroomFeatures = {
  fromJSON(object: any): Answers_BathroomFeatures {
    return {
      doubleSink: isSet(object.double_sink) ? Boolean(object.double_sink) : undefined,
      graniteCountertops: isSet(object.granite_countertops) ? Boolean(object.granite_countertops) : undefined,
      separateShowerTub: isSet(object.separate_shower_tub) ? Boolean(object.separate_shower_tub) : undefined,
      tileSurround: isSet(object.tile_surround) ? Boolean(object.tile_surround) : undefined,
      updatedTileFloors: isSet(object.updated_tile_floors) ? Boolean(object.updated_tile_floors) : undefined,
    };
  },

  toJSON(message: Answers_BathroomFeatures): unknown {
    const obj: any = {};
    message.doubleSink !== undefined && (obj.double_sink = message.doubleSink);
    message.graniteCountertops !== undefined && (obj.granite_countertops = message.graniteCountertops);
    message.separateShowerTub !== undefined && (obj.separate_shower_tub = message.separateShowerTub);
    message.tileSurround !== undefined && (obj.tile_surround = message.tileSurround);
    message.updatedTileFloors !== undefined && (obj.updated_tile_floors = message.updatedTileFloors);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_BathroomFeatures>, I>>(base?: I): Answers_BathroomFeatures {
    return Answers_BathroomFeatures.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_BathroomFeatures>, I>>(object: I): Answers_BathroomFeatures {
    const message = createBaseAnswers_BathroomFeatures();
    message.doubleSink = object.doubleSink ?? undefined;
    message.graniteCountertops = object.graniteCountertops ?? undefined;
    message.separateShowerTub = object.separateShowerTub ?? undefined;
    message.tileSurround = object.tileSurround ?? undefined;
    message.updatedTileFloors = object.updatedTileFloors ?? undefined;
    return message;
  },
};

function createBaseAnswers_EligibilityCriteria(): Answers_EligibilityCriteria {
  return {
    ageRestrictedCommunity: undefined,
    asbestosSiding: undefined,
    fireDamage: undefined,
    gatedCommunity: undefined,
    historyOfChemicalContamination: undefined,
    knownFoundationIssues: undefined,
    leasedSolarPanels: undefined,
    livestock: undefined,
    mobileManufacturedHome: undefined,
    ownedSolarPanels: undefined,
    permittedAddition: undefined,
    septic: undefined,
    wellWater: undefined,
    uniqueOwnershipStructure: undefined,
    belowMarketRateOwnership: undefined,
    rentControlledTenantOccupied: undefined,
    undergroundOilTank: undefined,
    polybutylenePiping: undefined,
    unpermittedAddition: undefined,
    cesspool: undefined,
  };
}

export const Answers_EligibilityCriteria = {
  fromJSON(object: any): Answers_EligibilityCriteria {
    return {
      ageRestrictedCommunity: isSet(object.age_restricted_community)
        ? Boolean(object.age_restricted_community)
        : undefined,
      asbestosSiding: isSet(object.asbestos_siding) ? Boolean(object.asbestos_siding) : undefined,
      fireDamage: isSet(object.fire_damage) ? Boolean(object.fire_damage) : undefined,
      gatedCommunity: isSet(object.gated_community) ? Boolean(object.gated_community) : undefined,
      historyOfChemicalContamination: isSet(object.history_of_chemical_contamination)
        ? Boolean(object.history_of_chemical_contamination)
        : undefined,
      knownFoundationIssues: isSet(object.known_foundation_issues)
        ? Boolean(object.known_foundation_issues)
        : undefined,
      leasedSolarPanels: isSet(object.leased_solar_panels) ? Boolean(object.leased_solar_panels) : undefined,
      livestock: isSet(object.livestock) ? Boolean(object.livestock) : undefined,
      mobileManufacturedHome: isSet(object.mobile_manufactured_home)
        ? Boolean(object.mobile_manufactured_home)
        : undefined,
      ownedSolarPanels: isSet(object.owned_solar_panels) ? Boolean(object.owned_solar_panels) : undefined,
      permittedAddition: isSet(object.permitted_addition) ? Boolean(object.permitted_addition) : undefined,
      septic: isSet(object.septic) ? Boolean(object.septic) : undefined,
      wellWater: isSet(object.well_water) ? Boolean(object.well_water) : undefined,
      uniqueOwnershipStructure: isSet(object.unique_ownership_structure)
        ? Boolean(object.unique_ownership_structure)
        : undefined,
      belowMarketRateOwnership: isSet(object.below_market_rate_ownership)
        ? Boolean(object.below_market_rate_ownership)
        : undefined,
      rentControlledTenantOccupied: isSet(object.rent_controlled_tenant_occupied)
        ? Boolean(object.rent_controlled_tenant_occupied)
        : undefined,
      undergroundOilTank: isSet(object.underground_oil_tank) ? Boolean(object.underground_oil_tank) : undefined,
      polybutylenePiping: isSet(object.polybutylene_piping) ? Boolean(object.polybutylene_piping) : undefined,
      unpermittedAddition: isSet(object.unpermitted_addition) ? Boolean(object.unpermitted_addition) : undefined,
      cesspool: isSet(object.cesspool) ? Boolean(object.cesspool) : undefined,
    };
  },

  toJSON(message: Answers_EligibilityCriteria): unknown {
    const obj: any = {};
    message.ageRestrictedCommunity !== undefined && (obj.age_restricted_community = message.ageRestrictedCommunity);
    message.asbestosSiding !== undefined && (obj.asbestos_siding = message.asbestosSiding);
    message.fireDamage !== undefined && (obj.fire_damage = message.fireDamage);
    message.gatedCommunity !== undefined && (obj.gated_community = message.gatedCommunity);
    message.historyOfChemicalContamination !== undefined &&
      (obj.history_of_chemical_contamination = message.historyOfChemicalContamination);
    message.knownFoundationIssues !== undefined && (obj.known_foundation_issues = message.knownFoundationIssues);
    message.leasedSolarPanels !== undefined && (obj.leased_solar_panels = message.leasedSolarPanels);
    message.livestock !== undefined && (obj.livestock = message.livestock);
    message.mobileManufacturedHome !== undefined && (obj.mobile_manufactured_home = message.mobileManufacturedHome);
    message.ownedSolarPanels !== undefined && (obj.owned_solar_panels = message.ownedSolarPanels);
    message.permittedAddition !== undefined && (obj.permitted_addition = message.permittedAddition);
    message.septic !== undefined && (obj.septic = message.septic);
    message.wellWater !== undefined && (obj.well_water = message.wellWater);
    message.uniqueOwnershipStructure !== undefined &&
      (obj.unique_ownership_structure = message.uniqueOwnershipStructure);
    message.belowMarketRateOwnership !== undefined &&
      (obj.below_market_rate_ownership = message.belowMarketRateOwnership);
    message.rentControlledTenantOccupied !== undefined &&
      (obj.rent_controlled_tenant_occupied = message.rentControlledTenantOccupied);
    message.undergroundOilTank !== undefined && (obj.underground_oil_tank = message.undergroundOilTank);
    message.polybutylenePiping !== undefined && (obj.polybutylene_piping = message.polybutylenePiping);
    message.unpermittedAddition !== undefined && (obj.unpermitted_addition = message.unpermittedAddition);
    message.cesspool !== undefined && (obj.cesspool = message.cesspool);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_EligibilityCriteria>, I>>(base?: I): Answers_EligibilityCriteria {
    return Answers_EligibilityCriteria.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_EligibilityCriteria>, I>>(object: I): Answers_EligibilityCriteria {
    const message = createBaseAnswers_EligibilityCriteria();
    message.ageRestrictedCommunity = object.ageRestrictedCommunity ?? undefined;
    message.asbestosSiding = object.asbestosSiding ?? undefined;
    message.fireDamage = object.fireDamage ?? undefined;
    message.gatedCommunity = object.gatedCommunity ?? undefined;
    message.historyOfChemicalContamination = object.historyOfChemicalContamination ?? undefined;
    message.knownFoundationIssues = object.knownFoundationIssues ?? undefined;
    message.leasedSolarPanels = object.leasedSolarPanels ?? undefined;
    message.livestock = object.livestock ?? undefined;
    message.mobileManufacturedHome = object.mobileManufacturedHome ?? undefined;
    message.ownedSolarPanels = object.ownedSolarPanels ?? undefined;
    message.permittedAddition = object.permittedAddition ?? undefined;
    message.septic = object.septic ?? undefined;
    message.wellWater = object.wellWater ?? undefined;
    message.uniqueOwnershipStructure = object.uniqueOwnershipStructure ?? undefined;
    message.belowMarketRateOwnership = object.belowMarketRateOwnership ?? undefined;
    message.rentControlledTenantOccupied = object.rentControlledTenantOccupied ?? undefined;
    message.undergroundOilTank = object.undergroundOilTank ?? undefined;
    message.polybutylenePiping = object.polybutylenePiping ?? undefined;
    message.unpermittedAddition = object.unpermittedAddition ?? undefined;
    message.cesspool = object.cesspool ?? undefined;
    return message;
  },
};

function createBaseAnswers_FloodHistoryTriage(): Answers_FloodHistoryTriage {
  return { harvey: undefined, memorialDay: undefined, notSure: undefined, other: undefined, taxDay: undefined };
}

export const Answers_FloodHistoryTriage = {
  fromJSON(object: any): Answers_FloodHistoryTriage {
    return {
      harvey: isSet(object.harvey) ? Boolean(object.harvey) : undefined,
      memorialDay: isSet(object.memorial_day) ? Boolean(object.memorial_day) : undefined,
      notSure: isSet(object.not_sure) ? Boolean(object.not_sure) : undefined,
      other: isSet(object.other) ? Boolean(object.other) : undefined,
      taxDay: isSet(object.tax_day) ? Boolean(object.tax_day) : undefined,
    };
  },

  toJSON(message: Answers_FloodHistoryTriage): unknown {
    const obj: any = {};
    message.harvey !== undefined && (obj.harvey = message.harvey);
    message.memorialDay !== undefined && (obj.memorial_day = message.memorialDay);
    message.notSure !== undefined && (obj.not_sure = message.notSure);
    message.other !== undefined && (obj.other = message.other);
    message.taxDay !== undefined && (obj.tax_day = message.taxDay);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_FloodHistoryTriage>, I>>(base?: I): Answers_FloodHistoryTriage {
    return Answers_FloodHistoryTriage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_FloodHistoryTriage>, I>>(object: I): Answers_FloodHistoryTriage {
    const message = createBaseAnswers_FloodHistoryTriage();
    message.harvey = object.harvey ?? undefined;
    message.memorialDay = object.memorialDay ?? undefined;
    message.notSure = object.notSure ?? undefined;
    message.other = object.other ?? undefined;
    message.taxDay = object.taxDay ?? undefined;
    return message;
  },
};

function createBaseAnswers_FloorTypes(): Answers_FloorTypes {
  return { carpet: undefined, hardwood: undefined, laminate: undefined, other: undefined, tile: undefined };
}

export const Answers_FloorTypes = {
  fromJSON(object: any): Answers_FloorTypes {
    return {
      carpet: isSet(object.carpet) ? Boolean(object.carpet) : undefined,
      hardwood: isSet(object.hardwood) ? Boolean(object.hardwood) : undefined,
      laminate: isSet(object.laminate) ? Boolean(object.laminate) : undefined,
      other: isSet(object.other) ? Boolean(object.other) : undefined,
      tile: isSet(object.tile) ? Boolean(object.tile) : undefined,
    };
  },

  toJSON(message: Answers_FloorTypes): unknown {
    const obj: any = {};
    message.carpet !== undefined && (obj.carpet = message.carpet);
    message.hardwood !== undefined && (obj.hardwood = message.hardwood);
    message.laminate !== undefined && (obj.laminate = message.laminate);
    message.other !== undefined && (obj.other = message.other);
    message.tile !== undefined && (obj.tile = message.tile);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_FloorTypes>, I>>(base?: I): Answers_FloorTypes {
    return Answers_FloorTypes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_FloorTypes>, I>>(object: I): Answers_FloorTypes {
    const message = createBaseAnswers_FloorTypes();
    message.carpet = object.carpet ?? undefined;
    message.hardwood = object.hardwood ?? undefined;
    message.laminate = object.laminate ?? undefined;
    message.other = object.other ?? undefined;
    message.tile = object.tile ?? undefined;
    return message;
  },
};

function createBaseAnswers_Rooms(): Answers_Rooms {
  return {
    dining: undefined,
    kitchen: undefined,
    living: undefined,
    masterBathroom: undefined,
    masterBedroom: undefined,
    other: undefined,
  };
}

export const Answers_Rooms = {
  fromJSON(object: any): Answers_Rooms {
    return {
      dining: isSet(object.dining) ? Boolean(object.dining) : undefined,
      kitchen: isSet(object.kitchen) ? Boolean(object.kitchen) : undefined,
      living: isSet(object.living) ? Boolean(object.living) : undefined,
      masterBathroom: isSet(object.master_bathroom) ? Boolean(object.master_bathroom) : undefined,
      masterBedroom: isSet(object.master_bedroom) ? Boolean(object.master_bedroom) : undefined,
      other: isSet(object.other) ? Boolean(object.other) : undefined,
    };
  },

  toJSON(message: Answers_Rooms): unknown {
    const obj: any = {};
    message.dining !== undefined && (obj.dining = message.dining);
    message.kitchen !== undefined && (obj.kitchen = message.kitchen);
    message.living !== undefined && (obj.living = message.living);
    message.masterBathroom !== undefined && (obj.master_bathroom = message.masterBathroom);
    message.masterBedroom !== undefined && (obj.master_bedroom = message.masterBedroom);
    message.other !== undefined && (obj.other = message.other);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_Rooms>, I>>(base?: I): Answers_Rooms {
    return Answers_Rooms.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_Rooms>, I>>(object: I): Answers_Rooms {
    const message = createBaseAnswers_Rooms();
    message.dining = object.dining ?? undefined;
    message.kitchen = object.kitchen ?? undefined;
    message.living = object.living ?? undefined;
    message.masterBathroom = object.masterBathroom ?? undefined;
    message.masterBedroom = object.masterBedroom ?? undefined;
    message.other = object.other ?? undefined;
    return message;
  },
};

function createBaseAnswers_KitchenFeatures(): Answers_KitchenFeatures {
  return { kitchenIsland: undefined, newCabinets: undefined, tileBacksplash: undefined };
}

export const Answers_KitchenFeatures = {
  fromJSON(object: any): Answers_KitchenFeatures {
    return {
      kitchenIsland: isSet(object.kitchen_island) ? Boolean(object.kitchen_island) : undefined,
      newCabinets: isSet(object.new_cabinets) ? Boolean(object.new_cabinets) : undefined,
      tileBacksplash: isSet(object.tile_backsplash) ? Boolean(object.tile_backsplash) : undefined,
    };
  },

  toJSON(message: Answers_KitchenFeatures): unknown {
    const obj: any = {};
    message.kitchenIsland !== undefined && (obj.kitchen_island = message.kitchenIsland);
    message.newCabinets !== undefined && (obj.new_cabinets = message.newCabinets);
    message.tileBacksplash !== undefined && (obj.tile_backsplash = message.tileBacksplash);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_KitchenFeatures>, I>>(base?: I): Answers_KitchenFeatures {
    return Answers_KitchenFeatures.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_KitchenFeatures>, I>>(object: I): Answers_KitchenFeatures {
    const message = createBaseAnswers_KitchenFeatures();
    message.kitchenIsland = object.kitchenIsland ?? undefined;
    message.newCabinets = object.newCabinets ?? undefined;
    message.tileBacksplash = object.tileBacksplash ?? undefined;
    return message;
  },
};

function createBaseAnswers_SellingReason(): Answers_SellingReason {
  return {
    downsizing: undefined,
    investmentProperty: undefined,
    needCash: undefined,
    other: undefined,
    relocating: undefined,
    retiring: undefined,
    upgrading: undefined,
  };
}

export const Answers_SellingReason = {
  fromJSON(object: any): Answers_SellingReason {
    return {
      downsizing: isSet(object.downsizing) ? Boolean(object.downsizing) : undefined,
      investmentProperty: isSet(object.investment_property) ? Boolean(object.investment_property) : undefined,
      needCash: isSet(object.need_cash) ? Boolean(object.need_cash) : undefined,
      other: isSet(object.other) ? Boolean(object.other) : undefined,
      relocating: isSet(object.relocating) ? Boolean(object.relocating) : undefined,
      retiring: isSet(object.retiring) ? Boolean(object.retiring) : undefined,
      upgrading: isSet(object.upgrading) ? Boolean(object.upgrading) : undefined,
    };
  },

  toJSON(message: Answers_SellingReason): unknown {
    const obj: any = {};
    message.downsizing !== undefined && (obj.downsizing = message.downsizing);
    message.investmentProperty !== undefined && (obj.investment_property = message.investmentProperty);
    message.needCash !== undefined && (obj.need_cash = message.needCash);
    message.other !== undefined && (obj.other = message.other);
    message.relocating !== undefined && (obj.relocating = message.relocating);
    message.retiring !== undefined && (obj.retiring = message.retiring);
    message.upgrading !== undefined && (obj.upgrading = message.upgrading);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_SellingReason>, I>>(base?: I): Answers_SellingReason {
    return Answers_SellingReason.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_SellingReason>, I>>(object: I): Answers_SellingReason {
    const message = createBaseAnswers_SellingReason();
    message.downsizing = object.downsizing ?? undefined;
    message.investmentProperty = object.investmentProperty ?? undefined;
    message.needCash = object.needCash ?? undefined;
    message.other = object.other ?? undefined;
    message.relocating = object.relocating ?? undefined;
    message.retiring = object.retiring ?? undefined;
    message.upgrading = object.upgrading ?? undefined;
    return message;
  },
};

function createBaseAnswers_Photo(): Answers_Photo {
  return { key: undefined, url: undefined, filename: undefined, mimetype: undefined };
}

export const Answers_Photo = {
  fromJSON(object: any): Answers_Photo {
    return {
      key: isSet(object.key) ? String(object.key) : undefined,
      url: isSet(object.url) ? String(object.url) : undefined,
      filename: isSet(object.filename) ? String(object.filename) : undefined,
      mimetype: isSet(object.mimetype) ? String(object.mimetype) : undefined,
    };
  },

  toJSON(message: Answers_Photo): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.url !== undefined && (obj.url = message.url);
    message.filename !== undefined && (obj.filename = message.filename);
    message.mimetype !== undefined && (obj.mimetype = message.mimetype);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_Photo>, I>>(base?: I): Answers_Photo {
    return Answers_Photo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_Photo>, I>>(object: I): Answers_Photo {
    const message = createBaseAnswers_Photo();
    message.key = object.key ?? undefined;
    message.url = object.url ?? undefined;
    message.filename = object.filename ?? undefined;
    message.mimetype = object.mimetype ?? undefined;
    return message;
  },
};

function createBaseAnswers_BuyerZone(): Answers_BuyerZone {
  return {
    breadcrumbPath: undefined,
    displayName: undefined,
    displayablePropertiesCount: undefined,
    id: undefined,
    isNearby: undefined,
    name: undefined,
    parentName: undefined,
    parents: [],
    value: undefined,
    zip: undefined,
    gdsId: undefined,
  };
}

export const Answers_BuyerZone = {
  fromJSON(object: any): Answers_BuyerZone {
    return {
      breadcrumbPath: isSet(object.breadcrumb_path) ? String(object.breadcrumb_path) : undefined,
      displayName: isSet(object.display_name) ? String(object.display_name) : undefined,
      displayablePropertiesCount: isSet(object.displayable_properties_count)
        ? Number(object.displayable_properties_count)
        : undefined,
      id: isSet(object.id) ? String(object.id) : undefined,
      isNearby: isSet(object.is_nearby) ? Boolean(object.is_nearby) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      parentName: isSet(object.parent_name) ? String(object.parent_name) : undefined,
      parents: Array.isArray(object?.parents)
        ? object.parents.map((e: any) => Answers_BuyerZone_ZoneParent.fromJSON(e))
        : [],
      value: isSet(object.value) ? String(object.value) : undefined,
      zip: isSet(object.zip) ? Boolean(object.zip) : undefined,
      gdsId: isSet(object.gdsId) ? String(object.gdsId) : undefined,
    };
  },

  toJSON(message: Answers_BuyerZone): unknown {
    const obj: any = {};
    message.breadcrumbPath !== undefined && (obj.breadcrumb_path = message.breadcrumbPath);
    message.displayName !== undefined && (obj.display_name = message.displayName);
    message.displayablePropertiesCount !== undefined &&
      (obj.displayable_properties_count = message.displayablePropertiesCount);
    message.id !== undefined && (obj.id = message.id);
    message.isNearby !== undefined && (obj.is_nearby = message.isNearby);
    message.name !== undefined && (obj.name = message.name);
    message.parentName !== undefined && (obj.parent_name = message.parentName);
    if (message.parents) {
      obj.parents = message.parents.map((e) => e ? Answers_BuyerZone_ZoneParent.toJSON(e) : undefined);
    } else {
      obj.parents = [];
    }
    message.value !== undefined && (obj.value = message.value);
    message.zip !== undefined && (obj.zip = message.zip);
    message.gdsId !== undefined && (obj.gdsId = message.gdsId);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_BuyerZone>, I>>(base?: I): Answers_BuyerZone {
    return Answers_BuyerZone.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_BuyerZone>, I>>(object: I): Answers_BuyerZone {
    const message = createBaseAnswers_BuyerZone();
    message.breadcrumbPath = object.breadcrumbPath ?? undefined;
    message.displayName = object.displayName ?? undefined;
    message.displayablePropertiesCount = object.displayablePropertiesCount ?? undefined;
    message.id = object.id ?? undefined;
    message.isNearby = object.isNearby ?? undefined;
    message.name = object.name ?? undefined;
    message.parentName = object.parentName ?? undefined;
    message.parents = object.parents?.map((e) => Answers_BuyerZone_ZoneParent.fromPartial(e)) || [];
    message.value = object.value ?? undefined;
    message.zip = object.zip ?? undefined;
    message.gdsId = object.gdsId ?? undefined;
    return message;
  },
};

function createBaseAnswers_BuyerZone_ZoneParent(): Answers_BuyerZone_ZoneParent {
  return { breadcrumbPath: undefined, name: undefined };
}

export const Answers_BuyerZone_ZoneParent = {
  fromJSON(object: any): Answers_BuyerZone_ZoneParent {
    return {
      breadcrumbPath: isSet(object.breadcrumb_path) ? String(object.breadcrumb_path) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: Answers_BuyerZone_ZoneParent): unknown {
    const obj: any = {};
    message.breadcrumbPath !== undefined && (obj.breadcrumb_path = message.breadcrumbPath);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_BuyerZone_ZoneParent>, I>>(base?: I): Answers_BuyerZone_ZoneParent {
    return Answers_BuyerZone_ZoneParent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_BuyerZone_ZoneParent>, I>>(object: I): Answers_BuyerZone_ZoneParent {
    const message = createBaseAnswers_BuyerZone_ZoneParent();
    message.breadcrumbPath = object.breadcrumbPath ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseAnswers_FeedPropertyTypes(): Answers_FeedPropertyTypes {
  return { home: undefined, apartment: undefined, townhome: undefined, multiFamily: undefined };
}

export const Answers_FeedPropertyTypes = {
  fromJSON(object: any): Answers_FeedPropertyTypes {
    return {
      home: isSet(object.home) ? Boolean(object.home) : undefined,
      apartment: isSet(object.apartment) ? Boolean(object.apartment) : undefined,
      townhome: isSet(object.townhome) ? Boolean(object.townhome) : undefined,
      multiFamily: isSet(object.multi_family) ? Boolean(object.multi_family) : undefined,
    };
  },

  toJSON(message: Answers_FeedPropertyTypes): unknown {
    const obj: any = {};
    message.home !== undefined && (obj.home = message.home);
    message.apartment !== undefined && (obj.apartment = message.apartment);
    message.townhome !== undefined && (obj.townhome = message.townhome);
    message.multiFamily !== undefined && (obj.multi_family = message.multiFamily);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_FeedPropertyTypes>, I>>(base?: I): Answers_FeedPropertyTypes {
    return Answers_FeedPropertyTypes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_FeedPropertyTypes>, I>>(object: I): Answers_FeedPropertyTypes {
    const message = createBaseAnswers_FeedPropertyTypes();
    message.home = object.home ?? undefined;
    message.apartment = object.apartment ?? undefined;
    message.townhome = object.townhome ?? undefined;
    message.multiFamily = object.multiFamily ?? undefined;
    return message;
  },
};

function createBaseAnswers_SetupBuyerFeed(): Answers_SetupBuyerFeed {
  return {
    zones: [],
    maxPrice: undefined,
    minPrice: undefined,
    minBedrooms: undefined,
    minBathrooms: undefined,
    propertyTypes: undefined,
  };
}

export const Answers_SetupBuyerFeed = {
  fromJSON(object: any): Answers_SetupBuyerFeed {
    return {
      zones: Array.isArray(object?.zones) ? object.zones.map((e: any) => Answers_BuyerZone.fromJSON(e)) : [],
      maxPrice: isSet(object.max_price) ? Number(object.max_price) : undefined,
      minPrice: isSet(object.min_price) ? Number(object.min_price) : undefined,
      minBedrooms: isSet(object.min_bedrooms) ? Number(object.min_bedrooms) : undefined,
      minBathrooms: isSet(object.min_bathrooms) ? Number(object.min_bathrooms) : undefined,
      propertyTypes: isSet(object.property_types)
        ? Answers_FeedPropertyTypes.fromJSON(object.property_types)
        : undefined,
    };
  },

  toJSON(message: Answers_SetupBuyerFeed): unknown {
    const obj: any = {};
    if (message.zones) {
      obj.zones = message.zones.map((e) => e ? Answers_BuyerZone.toJSON(e) : undefined);
    } else {
      obj.zones = [];
    }
    message.maxPrice !== undefined && (obj.max_price = message.maxPrice);
    message.minPrice !== undefined && (obj.min_price = message.minPrice);
    message.minBedrooms !== undefined && (obj.min_bedrooms = message.minBedrooms);
    message.minBathrooms !== undefined && (obj.min_bathrooms = message.minBathrooms);
    message.propertyTypes !== undefined &&
      (obj.property_types = message.propertyTypes
        ? Answers_FeedPropertyTypes.toJSON(message.propertyTypes)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_SetupBuyerFeed>, I>>(base?: I): Answers_SetupBuyerFeed {
    return Answers_SetupBuyerFeed.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_SetupBuyerFeed>, I>>(object: I): Answers_SetupBuyerFeed {
    const message = createBaseAnswers_SetupBuyerFeed();
    message.zones = object.zones?.map((e) => Answers_BuyerZone.fromPartial(e)) || [];
    message.maxPrice = object.maxPrice ?? undefined;
    message.minPrice = object.minPrice ?? undefined;
    message.minBedrooms = object.minBedrooms ?? undefined;
    message.minBathrooms = object.minBathrooms ?? undefined;
    message.propertyTypes = (object.propertyTypes !== undefined && object.propertyTypes !== null)
      ? Answers_FeedPropertyTypes.fromPartial(object.propertyTypes)
      : undefined;
    return message;
  },
};

function createBaseAnswers_BuyingPower(): Answers_BuyingPower {
  return { annualHouseholdIncome: 0, downPaymentAmount: 0, monthlyDebt: 0, creditScoreIndicated: undefined };
}

export const Answers_BuyingPower = {
  fromJSON(object: any): Answers_BuyingPower {
    return {
      annualHouseholdIncome: isSet(object.annual_household_income) ? Number(object.annual_household_income) : 0,
      downPaymentAmount: isSet(object.down_payment_amount) ? Number(object.down_payment_amount) : 0,
      monthlyDebt: isSet(object.monthly_debt) ? Number(object.monthly_debt) : 0,
      creditScoreIndicated: isSet(object.credit_score_indicated)
        ? CreditRange.fromJSON(object.credit_score_indicated)
        : undefined,
    };
  },

  toJSON(message: Answers_BuyingPower): unknown {
    const obj: any = {};
    message.annualHouseholdIncome !== undefined &&
      (obj.annual_household_income = Math.round(message.annualHouseholdIncome));
    message.downPaymentAmount !== undefined && (obj.down_payment_amount = Math.round(message.downPaymentAmount));
    message.monthlyDebt !== undefined && (obj.monthly_debt = Math.round(message.monthlyDebt));
    message.creditScoreIndicated !== undefined && (obj.credit_score_indicated = message.creditScoreIndicated
      ? CreditRange.toJSON(message.creditScoreIndicated)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_BuyingPower>, I>>(base?: I): Answers_BuyingPower {
    return Answers_BuyingPower.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_BuyingPower>, I>>(object: I): Answers_BuyingPower {
    const message = createBaseAnswers_BuyingPower();
    message.annualHouseholdIncome = object.annualHouseholdIncome ?? 0;
    message.downPaymentAmount = object.downPaymentAmount ?? 0;
    message.monthlyDebt = object.monthlyDebt ?? 0;
    message.creditScoreIndicated = (object.creditScoreIndicated !== undefined && object.creditScoreIndicated !== null)
      ? CreditRange.fromPartial(object.creditScoreIndicated)
      : undefined;
    return message;
  },
};

function createBaseAnswers_EntryTypes(): Answers_EntryTypes {
  return { directEntry: undefined, sharedEntranceCondo: undefined };
}

export const Answers_EntryTypes = {
  fromJSON(object: any): Answers_EntryTypes {
    return {
      directEntry: isSet(object.direct_entry) ? Boolean(object.direct_entry) : undefined,
      sharedEntranceCondo: isSet(object.shared_entrance_condo) ? Boolean(object.shared_entrance_condo) : undefined,
    };
  },

  toJSON(message: Answers_EntryTypes): unknown {
    const obj: any = {};
    message.directEntry !== undefined && (obj.direct_entry = message.directEntry);
    message.sharedEntranceCondo !== undefined && (obj.shared_entrance_condo = message.sharedEntranceCondo);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_EntryTypes>, I>>(base?: I): Answers_EntryTypes {
    return Answers_EntryTypes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_EntryTypes>, I>>(object: I): Answers_EntryTypes {
    const message = createBaseAnswers_EntryTypes();
    message.directEntry = object.directEntry ?? undefined;
    message.sharedEntranceCondo = object.sharedEntranceCondo ?? undefined;
    return message;
  },
};

function createBaseAnswers_HoaTypes(): Answers_HoaTypes {
  return {
    ageRestrictedCommunity: undefined,
    gatedCommunity: undefined,
    guardedGatedCommunity: undefined,
    sharedEntranceCondo: undefined,
  };
}

export const Answers_HoaTypes = {
  fromJSON(object: any): Answers_HoaTypes {
    return {
      ageRestrictedCommunity: isSet(object.age_restricted_community)
        ? Boolean(object.age_restricted_community)
        : undefined,
      gatedCommunity: isSet(object.gated_community) ? Boolean(object.gated_community) : undefined,
      guardedGatedCommunity: isSet(object.guarded_gated_community) ? String(object.guarded_gated_community) : undefined,
      sharedEntranceCondo: isSet(object.shared_entrance_condo) ? Boolean(object.shared_entrance_condo) : undefined,
    };
  },

  toJSON(message: Answers_HoaTypes): unknown {
    const obj: any = {};
    message.ageRestrictedCommunity !== undefined && (obj.age_restricted_community = message.ageRestrictedCommunity);
    message.gatedCommunity !== undefined && (obj.gated_community = message.gatedCommunity);
    message.guardedGatedCommunity !== undefined && (obj.guarded_gated_community = message.guardedGatedCommunity);
    message.sharedEntranceCondo !== undefined && (obj.shared_entrance_condo = message.sharedEntranceCondo);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_HoaTypes>, I>>(base?: I): Answers_HoaTypes {
    return Answers_HoaTypes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_HoaTypes>, I>>(object: I): Answers_HoaTypes {
    const message = createBaseAnswers_HoaTypes();
    message.ageRestrictedCommunity = object.ageRestrictedCommunity ?? undefined;
    message.gatedCommunity = object.gatedCommunity ?? undefined;
    message.guardedGatedCommunity = object.guardedGatedCommunity ?? undefined;
    message.sharedEntranceCondo = object.sharedEntranceCondo ?? undefined;
    return message;
  },
};

function createBaseAnswers_PriceEstimateRange(): Answers_PriceEstimateRange {
  return { minPrice: undefined, maxPrice: undefined };
}

export const Answers_PriceEstimateRange = {
  fromJSON(object: any): Answers_PriceEstimateRange {
    return {
      minPrice: isSet(object.min) ? String(object.min) : undefined,
      maxPrice: isSet(object.max) ? String(object.max) : undefined,
    };
  },

  toJSON(message: Answers_PriceEstimateRange): unknown {
    const obj: any = {};
    message.minPrice !== undefined && (obj.min = message.minPrice);
    message.maxPrice !== undefined && (obj.max = message.maxPrice);
    return obj;
  },

  create<I extends Exact<DeepPartial<Answers_PriceEstimateRange>, I>>(base?: I): Answers_PriceEstimateRange {
    return Answers_PriceEstimateRange.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answers_PriceEstimateRange>, I>>(object: I): Answers_PriceEstimateRange {
    const message = createBaseAnswers_PriceEstimateRange();
    message.minPrice = object.minPrice ?? undefined;
    message.maxPrice = object.maxPrice ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
