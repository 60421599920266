/* storybook-check-ignore */
/**
 * Warning: this provider is imported by the _app component at the root
 * of the application. Make sure that you keep the imports minimal here.
 */
import { AuthContextProvider, GraphQLClient, GraphQLClientError } from '@opendoor/auth-fe';
import { ATHENA_URL, DEPLOY_ENV } from '../globals';
import type { GraphQLError } from 'graphql';
import { useMemo } from 'react';

type EndpointOptions = {
  apiEndpoint: string;
  clientName: string;
};

export class FetchGqlError extends Error implements GraphQLClientError {
  graphQLErrors: readonly GraphQLError[];
  networkError: { status?: number; statusText?: string };
  constructor(
    message: string,
    errors: Array<GraphQLError>,
    networkError: { status?: number; statusText?: string },
  ) {
    super(message);
    this.graphQLErrors = errors;
    this.networkError = networkError;
  }
}

export const getQueryOperationName = (query: string) => {
  return query.match(/query\s+(?<operationName>[^{(\s]+)/)?.groups?.operationName ?? null;
};

export const fetchClient: (opts: EndpointOptions) => GraphQLClient = (opts) => {
  return {
    async query<TData, TVars>(query: string, vars: TVars) {
      const body = {
        operationName: getQueryOperationName(query),
        query: query,
        variables: vars,
      };
      const res = await fetch(opts.apiEndpoint, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'apollographql-client-name': opts.clientName,
        },
        body: JSON.stringify(body),
        mode: 'cors',
      });
      if (!res.ok) {
        return {
          error: new FetchGqlError('Failed to fetch', [], {
            status: res.status,
            statusText: res.statusText,
          }),
          data: undefined,
        };
      }
      const json = await res.json();
      if (json.errors) {
        return {
          error: new FetchGqlError('Graphql request had errors', json.errors, {}),
          data: undefined,
        };
      }
      return {
        data: json.data as TData,
        error: undefined,
      };
    },
  };
};

const AuthProvider: React.FC = ({ children }) => {
  const fetchClientMemo = useMemo(
    () => fetchClient({ apiEndpoint: ATHENA_URL, clientName: 'cosmos' }),
    [],
  );
  return (
    <AuthContextProvider env={DEPLOY_ENV} client={fetchClientMemo}>
      {children}
    </AuthContextProvider>
  );
};

export default AuthProvider;
