/* eslint-disable */
import { Timestamp } from "../../../../google/protobuf/timestamp";
import {
  DenialReason,
  denialReasonFromJSON,
  denialReasonToJSON,
  Product,
  productFromJSON,
  productToJSON,
} from "../../common/common";
import {
  SellerInput_Channel,
  sellerInput_ChannelFromJSON,
  sellerInput_ChannelToJSON,
} from "../../reception/data/seller_input";
import { Policy } from "./policy";
import { SellerDetails } from "./seller_details";

export const protobufPackage = "od_protos.sell.seller.data";

/**
 * A qualified sell lead.
 * Next ID: 26
 */
export interface Lead {
  /** Primary identifier for a lead. */
  id: string;
  /** The address of the lead. */
  addressId: string;
  /**
   * The customer who originated the lead.
   *
   * @deprecated
   */
  customerId: string;
  customerUuid: string;
  /**
   * List of users who are participants of the lead. This will initially
   * be empty. When an offer is accepted we will prompt the customer to
   * create an account or log in at which point we can associate the first
   * participant to the lead. Thereafter participants can invite others
   * to join (e.g. spouses, other legal owners).
   */
  userIds: string[];
  /** Identifier for the sell request in ODLS. */
  sellRequestId: string;
  /** User token of the assigned home adviser. */
  homeAdviserId: string;
  /** User token of the assigned closing manager. */
  closingManagerId: string;
  /** The brokerage that is representing the transaction. */
  brokerageId: string;
  /** The user token of the broker representing the brokerage. Optional. */
  brokerId: string;
  /** Home details record referencing the seller's answers. */
  homeDetailId: string;
  /** Name of the market region the home is in. */
  region: string;
  /** All the product offerings associated with the lead. */
  productOfferings: ProductOffering[];
  /** The time the lead was created */
  createdAt?: Date;
  /** The time of the offer review if the customer opted for one. */
  offerReview?: OfferReview;
  /** Policies attached to this lead. */
  policy?: Policy;
  /** Information provided by the seller. */
  sellerDetails?: SellerDetails;
  source?: Lead_Source;
  /** Experiment entity ID to be used with Optimizely and other experiments. */
  experimentEntityId: string;
  /** Timestamp of decision_call for customers receiving both lwod and od_direct offer. */
  decisionCallAt?: Date;
  /** Channel that the lead belongs to */
  channel: SellerInput_Channel;
  /** Records when a refresh was requested for this lead */
  refreshRequestedAt?: Date;
  /**
   * JSON answers that the seller provided (e.g. home details, sale timeline).
   * Example: "{ \"home.bedrooms\": 3, \"seller.email\": \"test@test.com\" }"
   */
  answersJson: string;
}

/** Which system generated the lead. */
export interface Lead_Source {
  type: Lead_Source_Type;
  id: string;
}

export enum Lead_Source_Type {
  UNKNOWN_TYPE = 0,
  RECEPTION = 1,
  PARTNERSHIP = 2,
  UNRECOGNIZED = -1,
}

export function lead_Source_TypeFromJSON(object: any): Lead_Source_Type {
  switch (object) {
    case 0:
    case "UNKNOWN_TYPE":
      return Lead_Source_Type.UNKNOWN_TYPE;
    case 1:
    case "RECEPTION":
      return Lead_Source_Type.RECEPTION;
    case 2:
    case "PARTNERSHIP":
      return Lead_Source_Type.PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Lead_Source_Type.UNRECOGNIZED;
  }
}

export function lead_Source_TypeToJSON(object: Lead_Source_Type): string {
  switch (object) {
    case Lead_Source_Type.UNKNOWN_TYPE:
      return "UNKNOWN_TYPE";
    case Lead_Source_Type.RECEPTION:
      return "RECEPTION";
    case Lead_Source_Type.PARTNERSHIP:
      return "PARTNERSHIP";
    case Lead_Source_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * OfferReview is a scheduled offer review session between the customer and a HA.
 * Next ID: 6
 */
export interface OfferReview {
  /** Identifier for the offer review. */
  id: string;
  /** The time that the offer review is scheduled to start and end. */
  startTime?: Date;
  endTime?: Date;
  /** Calendly's invitee identifier for the scheduled event. */
  calendlyInviteeUuid: string;
}

/**
 * ProductOffering represents a product that we can offer to the seller.
 * Next ID: 9
 */
export interface ProductOffering {
  product: Product;
  state: ProductOffering_State;
  /** Following fields are only set if denied. */
  denialReason: DenialReason;
  denialNotes: string;
  /** User token of the actor who denied the product. */
  deniedBy: string;
  /** The time that lead was denied for this product (auto or manual denial) */
  deniedAt?: Date;
  /** The time that lead was initially qualified for this product */
  initialQualifiedAt?: Date;
  /** The interest level of a seller towards a specific product */
  interestLevel: ProductOffering_InterestLevel;
}

export enum ProductOffering_State {
  UNKNOWN_STATE = 0,
  QUALIFIED = 1,
  DENIED = 2,
  UNRECOGNIZED = -1,
}

export function productOffering_StateFromJSON(object: any): ProductOffering_State {
  switch (object) {
    case 0:
    case "UNKNOWN_STATE":
      return ProductOffering_State.UNKNOWN_STATE;
    case 1:
    case "QUALIFIED":
      return ProductOffering_State.QUALIFIED;
    case 2:
    case "DENIED":
      return ProductOffering_State.DENIED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductOffering_State.UNRECOGNIZED;
  }
}

export function productOffering_StateToJSON(object: ProductOffering_State): string {
  switch (object) {
    case ProductOffering_State.UNKNOWN_STATE:
      return "UNKNOWN_STATE";
    case ProductOffering_State.QUALIFIED:
      return "QUALIFIED";
    case ProductOffering_State.DENIED:
      return "DENIED";
    case ProductOffering_State.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** InterestLevel represents the interest level of a seller towards a product */
export enum ProductOffering_InterestLevel {
  UNKNOWN_INTEREST_LEVEL = 0,
  NOT_INTERESTED = 1,
  INTERESTED = 2,
  UNRECOGNIZED = -1,
}

export function productOffering_InterestLevelFromJSON(object: any): ProductOffering_InterestLevel {
  switch (object) {
    case 0:
    case "UNKNOWN_INTEREST_LEVEL":
      return ProductOffering_InterestLevel.UNKNOWN_INTEREST_LEVEL;
    case 1:
    case "NOT_INTERESTED":
      return ProductOffering_InterestLevel.NOT_INTERESTED;
    case 2:
    case "INTERESTED":
      return ProductOffering_InterestLevel.INTERESTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductOffering_InterestLevel.UNRECOGNIZED;
  }
}

export function productOffering_InterestLevelToJSON(object: ProductOffering_InterestLevel): string {
  switch (object) {
    case ProductOffering_InterestLevel.UNKNOWN_INTEREST_LEVEL:
      return "UNKNOWN_INTEREST_LEVEL";
    case ProductOffering_InterestLevel.NOT_INTERESTED:
      return "NOT_INTERESTED";
    case ProductOffering_InterestLevel.INTERESTED:
      return "INTERESTED";
    case ProductOffering_InterestLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * LeadId represents a lead input, currently including a lead id (seller_uuid) or a lead source (e.g. reception type and seller_input_uuid).
 * Next ID: 3
 */
export interface LeadId {
  lead?: { $case: "leadId"; leadId: string } | { $case: "source"; source: Lead_Source };
}

function createBaseLead(): Lead {
  return {
    id: "",
    addressId: "",
    customerId: "",
    customerUuid: "",
    userIds: [],
    sellRequestId: "",
    homeAdviserId: "",
    closingManagerId: "",
    brokerageId: "",
    brokerId: "",
    homeDetailId: "",
    region: "",
    productOfferings: [],
    createdAt: undefined,
    offerReview: undefined,
    policy: undefined,
    sellerDetails: undefined,
    source: undefined,
    experimentEntityId: "",
    decisionCallAt: undefined,
    channel: 0,
    refreshRequestedAt: undefined,
    answersJson: "",
  };
}

export const Lead = {
  fromJSON(object: any): Lead {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      addressId: isSet(object.addressId) ? String(object.addressId) : "",
      customerId: isSet(object.customerId) ? String(object.customerId) : "",
      customerUuid: isSet(object.customerUuid) ? String(object.customerUuid) : "",
      userIds: Array.isArray(object?.userIds) ? object.userIds.map((e: any) => String(e)) : [],
      sellRequestId: isSet(object.sellRequestId) ? String(object.sellRequestId) : "",
      homeAdviserId: isSet(object.homeAdviserId) ? String(object.homeAdviserId) : "",
      closingManagerId: isSet(object.closingManagerId) ? String(object.closingManagerId) : "",
      brokerageId: isSet(object.brokerageId) ? String(object.brokerageId) : "",
      brokerId: isSet(object.brokerId) ? String(object.brokerId) : "",
      homeDetailId: isSet(object.homeDetailId) ? String(object.homeDetailId) : "",
      region: isSet(object.region) ? String(object.region) : "",
      productOfferings: Array.isArray(object?.productOfferings)
        ? object.productOfferings.map((e: any) => ProductOffering.fromJSON(e))
        : [],
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      offerReview: isSet(object.offerReview) ? OfferReview.fromJSON(object.offerReview) : undefined,
      policy: isSet(object.policy) ? Policy.fromJSON(object.policy) : undefined,
      sellerDetails: isSet(object.sellerDetails) ? SellerDetails.fromJSON(object.sellerDetails) : undefined,
      source: isSet(object.source) ? Lead_Source.fromJSON(object.source) : undefined,
      experimentEntityId: isSet(object.experimentEntityId) ? String(object.experimentEntityId) : "",
      decisionCallAt: isSet(object.decisionCallAt) ? fromJsonTimestamp(object.decisionCallAt) : undefined,
      channel: isSet(object.channel) ? sellerInput_ChannelFromJSON(object.channel) : 0,
      refreshRequestedAt: isSet(object.refreshRequestedAt) ? fromJsonTimestamp(object.refreshRequestedAt) : undefined,
      answersJson: isSet(object.answersJson) ? String(object.answersJson) : "",
    };
  },

  toJSON(message: Lead): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.addressId !== undefined && (obj.addressId = message.addressId);
    message.customerId !== undefined && (obj.customerId = message.customerId);
    message.customerUuid !== undefined && (obj.customerUuid = message.customerUuid);
    if (message.userIds) {
      obj.userIds = message.userIds.map((e) => e);
    } else {
      obj.userIds = [];
    }
    message.sellRequestId !== undefined && (obj.sellRequestId = message.sellRequestId);
    message.homeAdviserId !== undefined && (obj.homeAdviserId = message.homeAdviserId);
    message.closingManagerId !== undefined && (obj.closingManagerId = message.closingManagerId);
    message.brokerageId !== undefined && (obj.brokerageId = message.brokerageId);
    message.brokerId !== undefined && (obj.brokerId = message.brokerId);
    message.homeDetailId !== undefined && (obj.homeDetailId = message.homeDetailId);
    message.region !== undefined && (obj.region = message.region);
    if (message.productOfferings) {
      obj.productOfferings = message.productOfferings.map((e) => e ? ProductOffering.toJSON(e) : undefined);
    } else {
      obj.productOfferings = [];
    }
    message.createdAt !== undefined && (obj.createdAt = message.createdAt.toISOString());
    message.offerReview !== undefined &&
      (obj.offerReview = message.offerReview ? OfferReview.toJSON(message.offerReview) : undefined);
    message.policy !== undefined && (obj.policy = message.policy ? Policy.toJSON(message.policy) : undefined);
    message.sellerDetails !== undefined &&
      (obj.sellerDetails = message.sellerDetails ? SellerDetails.toJSON(message.sellerDetails) : undefined);
    message.source !== undefined && (obj.source = message.source ? Lead_Source.toJSON(message.source) : undefined);
    message.experimentEntityId !== undefined && (obj.experimentEntityId = message.experimentEntityId);
    message.decisionCallAt !== undefined && (obj.decisionCallAt = message.decisionCallAt.toISOString());
    message.channel !== undefined && (obj.channel = sellerInput_ChannelToJSON(message.channel));
    message.refreshRequestedAt !== undefined && (obj.refreshRequestedAt = message.refreshRequestedAt.toISOString());
    message.answersJson !== undefined && (obj.answersJson = message.answersJson);
    return obj;
  },

  create<I extends Exact<DeepPartial<Lead>, I>>(base?: I): Lead {
    return Lead.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Lead>, I>>(object: I): Lead {
    const message = createBaseLead();
    message.id = object.id ?? "";
    message.addressId = object.addressId ?? "";
    message.customerId = object.customerId ?? "";
    message.customerUuid = object.customerUuid ?? "";
    message.userIds = object.userIds?.map((e) => e) || [];
    message.sellRequestId = object.sellRequestId ?? "";
    message.homeAdviserId = object.homeAdviserId ?? "";
    message.closingManagerId = object.closingManagerId ?? "";
    message.brokerageId = object.brokerageId ?? "";
    message.brokerId = object.brokerId ?? "";
    message.homeDetailId = object.homeDetailId ?? "";
    message.region = object.region ?? "";
    message.productOfferings = object.productOfferings?.map((e) => ProductOffering.fromPartial(e)) || [];
    message.createdAt = object.createdAt ?? undefined;
    message.offerReview = (object.offerReview !== undefined && object.offerReview !== null)
      ? OfferReview.fromPartial(object.offerReview)
      : undefined;
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? Policy.fromPartial(object.policy)
      : undefined;
    message.sellerDetails = (object.sellerDetails !== undefined && object.sellerDetails !== null)
      ? SellerDetails.fromPartial(object.sellerDetails)
      : undefined;
    message.source = (object.source !== undefined && object.source !== null)
      ? Lead_Source.fromPartial(object.source)
      : undefined;
    message.experimentEntityId = object.experimentEntityId ?? "";
    message.decisionCallAt = object.decisionCallAt ?? undefined;
    message.channel = object.channel ?? 0;
    message.refreshRequestedAt = object.refreshRequestedAt ?? undefined;
    message.answersJson = object.answersJson ?? "";
    return message;
  },
};

function createBaseLead_Source(): Lead_Source {
  return { type: 0, id: "" };
}

export const Lead_Source = {
  fromJSON(object: any): Lead_Source {
    return {
      type: isSet(object.type) ? lead_Source_TypeFromJSON(object.type) : 0,
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: Lead_Source): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = lead_Source_TypeToJSON(message.type));
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<Lead_Source>, I>>(base?: I): Lead_Source {
    return Lead_Source.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Lead_Source>, I>>(object: I): Lead_Source {
    const message = createBaseLead_Source();
    message.type = object.type ?? 0;
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseOfferReview(): OfferReview {
  return { id: "", startTime: undefined, endTime: undefined, calendlyInviteeUuid: "" };
}

export const OfferReview = {
  fromJSON(object: any): OfferReview {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      calendlyInviteeUuid: isSet(object.calendlyInviteeUuid) ? String(object.calendlyInviteeUuid) : "",
    };
  },

  toJSON(message: OfferReview): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.startTime !== undefined && (obj.startTime = message.startTime.toISOString());
    message.endTime !== undefined && (obj.endTime = message.endTime.toISOString());
    message.calendlyInviteeUuid !== undefined && (obj.calendlyInviteeUuid = message.calendlyInviteeUuid);
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferReview>, I>>(base?: I): OfferReview {
    return OfferReview.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OfferReview>, I>>(object: I): OfferReview {
    const message = createBaseOfferReview();
    message.id = object.id ?? "";
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.calendlyInviteeUuid = object.calendlyInviteeUuid ?? "";
    return message;
  },
};

function createBaseProductOffering(): ProductOffering {
  return {
    product: 0,
    state: 0,
    denialReason: 0,
    denialNotes: "",
    deniedBy: "",
    deniedAt: undefined,
    initialQualifiedAt: undefined,
    interestLevel: 0,
  };
}

export const ProductOffering = {
  fromJSON(object: any): ProductOffering {
    return {
      product: isSet(object.product) ? productFromJSON(object.product) : 0,
      state: isSet(object.state) ? productOffering_StateFromJSON(object.state) : 0,
      denialReason: isSet(object.denialReason) ? denialReasonFromJSON(object.denialReason) : 0,
      denialNotes: isSet(object.denialNotes) ? String(object.denialNotes) : "",
      deniedBy: isSet(object.deniedBy) ? String(object.deniedBy) : "",
      deniedAt: isSet(object.deniedAt) ? fromJsonTimestamp(object.deniedAt) : undefined,
      initialQualifiedAt: isSet(object.initialQualifiedAt) ? fromJsonTimestamp(object.initialQualifiedAt) : undefined,
      interestLevel: isSet(object.interestLevel) ? productOffering_InterestLevelFromJSON(object.interestLevel) : 0,
    };
  },

  toJSON(message: ProductOffering): unknown {
    const obj: any = {};
    message.product !== undefined && (obj.product = productToJSON(message.product));
    message.state !== undefined && (obj.state = productOffering_StateToJSON(message.state));
    message.denialReason !== undefined && (obj.denialReason = denialReasonToJSON(message.denialReason));
    message.denialNotes !== undefined && (obj.denialNotes = message.denialNotes);
    message.deniedBy !== undefined && (obj.deniedBy = message.deniedBy);
    message.deniedAt !== undefined && (obj.deniedAt = message.deniedAt.toISOString());
    message.initialQualifiedAt !== undefined && (obj.initialQualifiedAt = message.initialQualifiedAt.toISOString());
    message.interestLevel !== undefined &&
      (obj.interestLevel = productOffering_InterestLevelToJSON(message.interestLevel));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductOffering>, I>>(base?: I): ProductOffering {
    return ProductOffering.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductOffering>, I>>(object: I): ProductOffering {
    const message = createBaseProductOffering();
    message.product = object.product ?? 0;
    message.state = object.state ?? 0;
    message.denialReason = object.denialReason ?? 0;
    message.denialNotes = object.denialNotes ?? "";
    message.deniedBy = object.deniedBy ?? "";
    message.deniedAt = object.deniedAt ?? undefined;
    message.initialQualifiedAt = object.initialQualifiedAt ?? undefined;
    message.interestLevel = object.interestLevel ?? 0;
    return message;
  },
};

function createBaseLeadId(): LeadId {
  return { lead: undefined };
}

export const LeadId = {
  fromJSON(object: any): LeadId {
    return {
      lead: isSet(object.leadId)
        ? { $case: "leadId", leadId: String(object.leadId) }
        : isSet(object.source)
        ? { $case: "source", source: Lead_Source.fromJSON(object.source) }
        : undefined,
    };
  },

  toJSON(message: LeadId): unknown {
    const obj: any = {};
    message.lead?.$case === "leadId" && (obj.leadId = message.lead?.leadId);
    message.lead?.$case === "source" &&
      (obj.source = message.lead?.source ? Lead_Source.toJSON(message.lead?.source) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadId>, I>>(base?: I): LeadId {
    return LeadId.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LeadId>, I>>(object: I): LeadId {
    const message = createBaseLeadId();
    if (object.lead?.$case === "leadId" && object.lead?.leadId !== undefined && object.lead?.leadId !== null) {
      message.lead = { $case: "leadId", leadId: object.lead.leadId };
    }
    if (object.lead?.$case === "source" && object.lead?.source !== undefined && object.lead?.source !== null) {
      message.lead = { $case: "source", source: Lead_Source.fromPartial(object.lead.source) };
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
