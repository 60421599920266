/* eslint-disable */

export const protobufPackage = "od_protos.common.sell";

/** Next ID: 3 */
export interface OfferRequest {
  source: OfferRequest_Source;
  id: number;
}

/** Next ID: 24 */
export enum OfferRequest_Source {
  UNKNOWN_OFFER_SOURCE = 0,
  REFRESH_ADMIN = 1,
  REFRESH_OFFER_DASHBOARD = 2,
  REFRESH_BROKERAGE_OFFER_DASHBOARD = 3,
  REFRESH_SELLER_FEEDBACK = 4,
  REFRESH_REENGAGEMENT_EMAILS = 5,
  REFRESH_CORRECTED_SQ_FT_DATA = 6,
  INITIAL_LEAD_FLOW = 7,
  INCOMPLETE_INITIAL_LEAD_FLOW = 8,
  OFFER_REENGAGEMENT_CAMPAIGN = 9,
  AUTOMATED_REENGAGEMENT_OFFER = 10,
  MOBILE_APP = 11,
  REIT_BULK_REQUEST = 12,
  OFFER_REVIEW = 13,
  /** FLYWHEEL - FLYWHEEL is used for offer requests on leads from the FLYWHEEL channel */
  FLYWHEEL = 14,
  /** EMAIL_PROSPECTING - EMAIL_PROSPECTING is used for offer requests on leads from the EMAIL_PROSPECTING channel */
  EMAIL_PROSPECTING = 15,
  /** OUTBOUND_OFFERS_LISTED - OUTBOUND_OFFERS_LISTED is used for 2020 Hackweek experiment of sending offers to listed homes */
  OUTBOUND_OFFERS_LISTED = 16,
  /** REFRESH_PARTNER_REFERRAL - REFRESH_PARTNER_REFERRAL is used for offers refreshed from the partnerships referral dashboard */
  REFRESH_PARTNER_REFERRAL = 17,
  /** HOME_RESERVE - HOME_RESERVE is used for offers requests on leads from the HOME_RESERVE channel */
  HOME_RESERVE = 18,
  /** HOMEPAGE_RETURN_EXPERIENCE - HOMEPAGE_RETURN_EXPERIENCE is used for offer refreshes that are triggered from the homepage */
  HOMEPAGE_RETURN_EXPERIENCE = 19,
  /** REFRESH_HOME_DETAILS - REFRESH_HOME_DETAILS is used for offer refreshes from the offer dashboard home details page */
  REFRESH_HOME_DETAILS = 20,
  /** REFRESH_FROM_ENG - REFRESH_FROM_ENG is used for backfilling any refresh properties that expierenced a break in flow due to an error */
  REFRESH_FROM_ENG = 21,
  /** REFRESH_PARTNER_CTA - REFRESH_PARTNER_CTA is used for offer requests that come from Opendoor partners */
  REFRESH_PARTNER_CTA = 22,
  /** RETURN_TO_HUB_AUTO_REFRESH - RETURN_TO_HUB_AUTO_REFRESH is used for offer refreshes automatically made when users return to the Hub */
  RETURN_TO_HUB_AUTO_REFRESH = 23,
  UNRECOGNIZED = -1,
}

export function offerRequest_SourceFromJSON(object: any): OfferRequest_Source {
  switch (object) {
    case 0:
    case "UNKNOWN_OFFER_SOURCE":
      return OfferRequest_Source.UNKNOWN_OFFER_SOURCE;
    case 1:
    case "REFRESH_ADMIN":
      return OfferRequest_Source.REFRESH_ADMIN;
    case 2:
    case "REFRESH_OFFER_DASHBOARD":
      return OfferRequest_Source.REFRESH_OFFER_DASHBOARD;
    case 3:
    case "REFRESH_BROKERAGE_OFFER_DASHBOARD":
      return OfferRequest_Source.REFRESH_BROKERAGE_OFFER_DASHBOARD;
    case 4:
    case "REFRESH_SELLER_FEEDBACK":
      return OfferRequest_Source.REFRESH_SELLER_FEEDBACK;
    case 5:
    case "REFRESH_REENGAGEMENT_EMAILS":
      return OfferRequest_Source.REFRESH_REENGAGEMENT_EMAILS;
    case 6:
    case "REFRESH_CORRECTED_SQ_FT_DATA":
      return OfferRequest_Source.REFRESH_CORRECTED_SQ_FT_DATA;
    case 7:
    case "INITIAL_LEAD_FLOW":
      return OfferRequest_Source.INITIAL_LEAD_FLOW;
    case 8:
    case "INCOMPLETE_INITIAL_LEAD_FLOW":
      return OfferRequest_Source.INCOMPLETE_INITIAL_LEAD_FLOW;
    case 9:
    case "OFFER_REENGAGEMENT_CAMPAIGN":
      return OfferRequest_Source.OFFER_REENGAGEMENT_CAMPAIGN;
    case 10:
    case "AUTOMATED_REENGAGEMENT_OFFER":
      return OfferRequest_Source.AUTOMATED_REENGAGEMENT_OFFER;
    case 11:
    case "MOBILE_APP":
      return OfferRequest_Source.MOBILE_APP;
    case 12:
    case "REIT_BULK_REQUEST":
      return OfferRequest_Source.REIT_BULK_REQUEST;
    case 13:
    case "OFFER_REVIEW":
      return OfferRequest_Source.OFFER_REVIEW;
    case 14:
    case "FLYWHEEL":
      return OfferRequest_Source.FLYWHEEL;
    case 15:
    case "EMAIL_PROSPECTING":
      return OfferRequest_Source.EMAIL_PROSPECTING;
    case 16:
    case "OUTBOUND_OFFERS_LISTED":
      return OfferRequest_Source.OUTBOUND_OFFERS_LISTED;
    case 17:
    case "REFRESH_PARTNER_REFERRAL":
      return OfferRequest_Source.REFRESH_PARTNER_REFERRAL;
    case 18:
    case "HOME_RESERVE":
      return OfferRequest_Source.HOME_RESERVE;
    case 19:
    case "HOMEPAGE_RETURN_EXPERIENCE":
      return OfferRequest_Source.HOMEPAGE_RETURN_EXPERIENCE;
    case 20:
    case "REFRESH_HOME_DETAILS":
      return OfferRequest_Source.REFRESH_HOME_DETAILS;
    case 21:
    case "REFRESH_FROM_ENG":
      return OfferRequest_Source.REFRESH_FROM_ENG;
    case 22:
    case "REFRESH_PARTNER_CTA":
      return OfferRequest_Source.REFRESH_PARTNER_CTA;
    case 23:
    case "RETURN_TO_HUB_AUTO_REFRESH":
      return OfferRequest_Source.RETURN_TO_HUB_AUTO_REFRESH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OfferRequest_Source.UNRECOGNIZED;
  }
}

export function offerRequest_SourceToJSON(object: OfferRequest_Source): string {
  switch (object) {
    case OfferRequest_Source.UNKNOWN_OFFER_SOURCE:
      return "UNKNOWN_OFFER_SOURCE";
    case OfferRequest_Source.REFRESH_ADMIN:
      return "REFRESH_ADMIN";
    case OfferRequest_Source.REFRESH_OFFER_DASHBOARD:
      return "REFRESH_OFFER_DASHBOARD";
    case OfferRequest_Source.REFRESH_BROKERAGE_OFFER_DASHBOARD:
      return "REFRESH_BROKERAGE_OFFER_DASHBOARD";
    case OfferRequest_Source.REFRESH_SELLER_FEEDBACK:
      return "REFRESH_SELLER_FEEDBACK";
    case OfferRequest_Source.REFRESH_REENGAGEMENT_EMAILS:
      return "REFRESH_REENGAGEMENT_EMAILS";
    case OfferRequest_Source.REFRESH_CORRECTED_SQ_FT_DATA:
      return "REFRESH_CORRECTED_SQ_FT_DATA";
    case OfferRequest_Source.INITIAL_LEAD_FLOW:
      return "INITIAL_LEAD_FLOW";
    case OfferRequest_Source.INCOMPLETE_INITIAL_LEAD_FLOW:
      return "INCOMPLETE_INITIAL_LEAD_FLOW";
    case OfferRequest_Source.OFFER_REENGAGEMENT_CAMPAIGN:
      return "OFFER_REENGAGEMENT_CAMPAIGN";
    case OfferRequest_Source.AUTOMATED_REENGAGEMENT_OFFER:
      return "AUTOMATED_REENGAGEMENT_OFFER";
    case OfferRequest_Source.MOBILE_APP:
      return "MOBILE_APP";
    case OfferRequest_Source.REIT_BULK_REQUEST:
      return "REIT_BULK_REQUEST";
    case OfferRequest_Source.OFFER_REVIEW:
      return "OFFER_REVIEW";
    case OfferRequest_Source.FLYWHEEL:
      return "FLYWHEEL";
    case OfferRequest_Source.EMAIL_PROSPECTING:
      return "EMAIL_PROSPECTING";
    case OfferRequest_Source.OUTBOUND_OFFERS_LISTED:
      return "OUTBOUND_OFFERS_LISTED";
    case OfferRequest_Source.REFRESH_PARTNER_REFERRAL:
      return "REFRESH_PARTNER_REFERRAL";
    case OfferRequest_Source.HOME_RESERVE:
      return "HOME_RESERVE";
    case OfferRequest_Source.HOMEPAGE_RETURN_EXPERIENCE:
      return "HOMEPAGE_RETURN_EXPERIENCE";
    case OfferRequest_Source.REFRESH_HOME_DETAILS:
      return "REFRESH_HOME_DETAILS";
    case OfferRequest_Source.REFRESH_FROM_ENG:
      return "REFRESH_FROM_ENG";
    case OfferRequest_Source.REFRESH_PARTNER_CTA:
      return "REFRESH_PARTNER_CTA";
    case OfferRequest_Source.RETURN_TO_HUB_AUTO_REFRESH:
      return "RETURN_TO_HUB_AUTO_REFRESH";
    case OfferRequest_Source.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseOfferRequest(): OfferRequest {
  return { source: 0, id: 0 };
}

export const OfferRequest = {
  fromJSON(object: any): OfferRequest {
    return {
      source: isSet(object.source) ? offerRequest_SourceFromJSON(object.source) : 0,
      id: isSet(object.id) ? Number(object.id) : 0,
    };
  },

  toJSON(message: OfferRequest): unknown {
    const obj: any = {};
    message.source !== undefined && (obj.source = offerRequest_SourceToJSON(message.source));
    message.id !== undefined && (obj.id = Math.round(message.id));
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferRequest>, I>>(base?: I): OfferRequest {
    return OfferRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OfferRequest>, I>>(object: I): OfferRequest {
    const message = createBaseOfferRequest();
    message.source = object.source ?? 0;
    message.id = object.id ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
