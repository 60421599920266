/* eslint-disable */
import {
  OfferRequest_Source,
  offerRequest_SourceFromJSON,
  offerRequest_SourceToJSON,
} from "../../common/sell/offer_request";

export const protobufPackage = "od_protos.journey.data";

/**
 * Context for tracking parameter fields
 * Next ID: 13
 */
export interface CampaignContext {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
  utmAdgid?: string;
  utmNtwk?: string;
  utmSubsite?: string;
  utmCta?: string;
  utmExternalid?: string;
  utmGeo?: string;
  utmCat?: string;
}

/**
 * Context for customer related fields
 * Next ID: 6
 */
export interface CustomerContext {
  customerUuid: string;
  firstName?: string;
  lastName?: string;
  primaryEmail?: string;
  primaryPhone?: string;
}

/**
 * Context for Exclusive Offers
 * Next ID: 8
 */
export interface ExclusiveOffersContext {
  offerBlurPricing?: boolean;
  offerRangeMax?: number;
  offerRangeMin?: number;
  potentialBuyerCount?: number;
  allPhotoCount?: number;
  internalPhotoCount?: number;
  mlsPhotoCount?: number;
}

/**
 * Context for lead fields
 * Next ID: 4
 */
export interface LeadContext {
  sellerInputUuid?: string;
  sellerLeadUuid?: string;
  sellerLeadMarket?: string;
}

/**
 * Context for Sell Direct
 * Next ID: 7
 */
export interface SellDirectContext {
  offerId: string;
  offerRequestSource: OfferRequest_Source;
  offerHeadlinePrice?: number;
  offerDenialReason?: string;
  offerState?: string;
  dwellingType?: string;
}

/**
 * Context for Ad Clicks
 * Next ID: 4
 */
export interface AdClickContext {
  /** facbook ad click */
  fbclid?: string;
  /** tiktok ad click */
  ttclid?: string;
  /** google ad click */
  gclid?: string;
}

function createBaseCampaignContext(): CampaignContext {
  return {
    utmSource: undefined,
    utmMedium: undefined,
    utmCampaign: undefined,
    utmTerm: undefined,
    utmContent: undefined,
    utmAdgid: undefined,
    utmNtwk: undefined,
    utmSubsite: undefined,
    utmCta: undefined,
    utmExternalid: undefined,
    utmGeo: undefined,
    utmCat: undefined,
  };
}

export const CampaignContext = {
  fromJSON(object: any): CampaignContext {
    return {
      utmSource: isSet(object.utmSource) ? String(object.utmSource) : undefined,
      utmMedium: isSet(object.utmMedium) ? String(object.utmMedium) : undefined,
      utmCampaign: isSet(object.utmCampaign) ? String(object.utmCampaign) : undefined,
      utmTerm: isSet(object.utmTerm) ? String(object.utmTerm) : undefined,
      utmContent: isSet(object.utmContent) ? String(object.utmContent) : undefined,
      utmAdgid: isSet(object.utmAdgid) ? String(object.utmAdgid) : undefined,
      utmNtwk: isSet(object.utmNtwk) ? String(object.utmNtwk) : undefined,
      utmSubsite: isSet(object.utmSubsite) ? String(object.utmSubsite) : undefined,
      utmCta: isSet(object.utmCta) ? String(object.utmCta) : undefined,
      utmExternalid: isSet(object.utmExternalid) ? String(object.utmExternalid) : undefined,
      utmGeo: isSet(object.utmGeo) ? String(object.utmGeo) : undefined,
      utmCat: isSet(object.utmCat) ? String(object.utmCat) : undefined,
    };
  },

  toJSON(message: CampaignContext): unknown {
    const obj: any = {};
    message.utmSource !== undefined && (obj.utmSource = message.utmSource);
    message.utmMedium !== undefined && (obj.utmMedium = message.utmMedium);
    message.utmCampaign !== undefined && (obj.utmCampaign = message.utmCampaign);
    message.utmTerm !== undefined && (obj.utmTerm = message.utmTerm);
    message.utmContent !== undefined && (obj.utmContent = message.utmContent);
    message.utmAdgid !== undefined && (obj.utmAdgid = message.utmAdgid);
    message.utmNtwk !== undefined && (obj.utmNtwk = message.utmNtwk);
    message.utmSubsite !== undefined && (obj.utmSubsite = message.utmSubsite);
    message.utmCta !== undefined && (obj.utmCta = message.utmCta);
    message.utmExternalid !== undefined && (obj.utmExternalid = message.utmExternalid);
    message.utmGeo !== undefined && (obj.utmGeo = message.utmGeo);
    message.utmCat !== undefined && (obj.utmCat = message.utmCat);
    return obj;
  },

  create<I extends Exact<DeepPartial<CampaignContext>, I>>(base?: I): CampaignContext {
    return CampaignContext.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CampaignContext>, I>>(object: I): CampaignContext {
    const message = createBaseCampaignContext();
    message.utmSource = object.utmSource ?? undefined;
    message.utmMedium = object.utmMedium ?? undefined;
    message.utmCampaign = object.utmCampaign ?? undefined;
    message.utmTerm = object.utmTerm ?? undefined;
    message.utmContent = object.utmContent ?? undefined;
    message.utmAdgid = object.utmAdgid ?? undefined;
    message.utmNtwk = object.utmNtwk ?? undefined;
    message.utmSubsite = object.utmSubsite ?? undefined;
    message.utmCta = object.utmCta ?? undefined;
    message.utmExternalid = object.utmExternalid ?? undefined;
    message.utmGeo = object.utmGeo ?? undefined;
    message.utmCat = object.utmCat ?? undefined;
    return message;
  },
};

function createBaseCustomerContext(): CustomerContext {
  return {
    customerUuid: "",
    firstName: undefined,
    lastName: undefined,
    primaryEmail: undefined,
    primaryPhone: undefined,
  };
}

export const CustomerContext = {
  fromJSON(object: any): CustomerContext {
    return {
      customerUuid: isSet(object.customerUuid) ? String(object.customerUuid) : "",
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      primaryEmail: isSet(object.primaryEmail) ? String(object.primaryEmail) : undefined,
      primaryPhone: isSet(object.primaryPhone) ? String(object.primaryPhone) : undefined,
    };
  },

  toJSON(message: CustomerContext): unknown {
    const obj: any = {};
    message.customerUuid !== undefined && (obj.customerUuid = message.customerUuid);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.primaryEmail !== undefined && (obj.primaryEmail = message.primaryEmail);
    message.primaryPhone !== undefined && (obj.primaryPhone = message.primaryPhone);
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomerContext>, I>>(base?: I): CustomerContext {
    return CustomerContext.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CustomerContext>, I>>(object: I): CustomerContext {
    const message = createBaseCustomerContext();
    message.customerUuid = object.customerUuid ?? "";
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.primaryEmail = object.primaryEmail ?? undefined;
    message.primaryPhone = object.primaryPhone ?? undefined;
    return message;
  },
};

function createBaseExclusiveOffersContext(): ExclusiveOffersContext {
  return {
    offerBlurPricing: undefined,
    offerRangeMax: undefined,
    offerRangeMin: undefined,
    potentialBuyerCount: undefined,
    allPhotoCount: undefined,
    internalPhotoCount: undefined,
    mlsPhotoCount: undefined,
  };
}

export const ExclusiveOffersContext = {
  fromJSON(object: any): ExclusiveOffersContext {
    return {
      offerBlurPricing: isSet(object.offerBlurPricing) ? Boolean(object.offerBlurPricing) : undefined,
      offerRangeMax: isSet(object.offerRangeMax) ? Number(object.offerRangeMax) : undefined,
      offerRangeMin: isSet(object.offerRangeMin) ? Number(object.offerRangeMin) : undefined,
      potentialBuyerCount: isSet(object.potentialBuyerCount) ? Number(object.potentialBuyerCount) : undefined,
      allPhotoCount: isSet(object.allPhotoCount) ? Number(object.allPhotoCount) : undefined,
      internalPhotoCount: isSet(object.internalPhotoCount) ? Number(object.internalPhotoCount) : undefined,
      mlsPhotoCount: isSet(object.mlsPhotoCount) ? Number(object.mlsPhotoCount) : undefined,
    };
  },

  toJSON(message: ExclusiveOffersContext): unknown {
    const obj: any = {};
    message.offerBlurPricing !== undefined && (obj.offerBlurPricing = message.offerBlurPricing);
    message.offerRangeMax !== undefined && (obj.offerRangeMax = message.offerRangeMax);
    message.offerRangeMin !== undefined && (obj.offerRangeMin = message.offerRangeMin);
    message.potentialBuyerCount !== undefined && (obj.potentialBuyerCount = message.potentialBuyerCount);
    message.allPhotoCount !== undefined && (obj.allPhotoCount = message.allPhotoCount);
    message.internalPhotoCount !== undefined && (obj.internalPhotoCount = message.internalPhotoCount);
    message.mlsPhotoCount !== undefined && (obj.mlsPhotoCount = message.mlsPhotoCount);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExclusiveOffersContext>, I>>(base?: I): ExclusiveOffersContext {
    return ExclusiveOffersContext.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExclusiveOffersContext>, I>>(object: I): ExclusiveOffersContext {
    const message = createBaseExclusiveOffersContext();
    message.offerBlurPricing = object.offerBlurPricing ?? undefined;
    message.offerRangeMax = object.offerRangeMax ?? undefined;
    message.offerRangeMin = object.offerRangeMin ?? undefined;
    message.potentialBuyerCount = object.potentialBuyerCount ?? undefined;
    message.allPhotoCount = object.allPhotoCount ?? undefined;
    message.internalPhotoCount = object.internalPhotoCount ?? undefined;
    message.mlsPhotoCount = object.mlsPhotoCount ?? undefined;
    return message;
  },
};

function createBaseLeadContext(): LeadContext {
  return { sellerInputUuid: undefined, sellerLeadUuid: undefined, sellerLeadMarket: undefined };
}

export const LeadContext = {
  fromJSON(object: any): LeadContext {
    return {
      sellerInputUuid: isSet(object.sellerInputUuid) ? String(object.sellerInputUuid) : undefined,
      sellerLeadUuid: isSet(object.sellerLeadUuid) ? String(object.sellerLeadUuid) : undefined,
      sellerLeadMarket: isSet(object.sellerLeadMarket) ? String(object.sellerLeadMarket) : undefined,
    };
  },

  toJSON(message: LeadContext): unknown {
    const obj: any = {};
    message.sellerInputUuid !== undefined && (obj.sellerInputUuid = message.sellerInputUuid);
    message.sellerLeadUuid !== undefined && (obj.sellerLeadUuid = message.sellerLeadUuid);
    message.sellerLeadMarket !== undefined && (obj.sellerLeadMarket = message.sellerLeadMarket);
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadContext>, I>>(base?: I): LeadContext {
    return LeadContext.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LeadContext>, I>>(object: I): LeadContext {
    const message = createBaseLeadContext();
    message.sellerInputUuid = object.sellerInputUuid ?? undefined;
    message.sellerLeadUuid = object.sellerLeadUuid ?? undefined;
    message.sellerLeadMarket = object.sellerLeadMarket ?? undefined;
    return message;
  },
};

function createBaseSellDirectContext(): SellDirectContext {
  return {
    offerId: "",
    offerRequestSource: 0,
    offerHeadlinePrice: undefined,
    offerDenialReason: undefined,
    offerState: undefined,
    dwellingType: undefined,
  };
}

export const SellDirectContext = {
  fromJSON(object: any): SellDirectContext {
    return {
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      offerRequestSource: isSet(object.offerRequestSource) ? offerRequest_SourceFromJSON(object.offerRequestSource) : 0,
      offerHeadlinePrice: isSet(object.offerHeadlinePrice) ? Number(object.offerHeadlinePrice) : undefined,
      offerDenialReason: isSet(object.offerDenialReason) ? String(object.offerDenialReason) : undefined,
      offerState: isSet(object.offerState) ? String(object.offerState) : undefined,
      dwellingType: isSet(object.dwellingType) ? String(object.dwellingType) : undefined,
    };
  },

  toJSON(message: SellDirectContext): unknown {
    const obj: any = {};
    message.offerId !== undefined && (obj.offerId = message.offerId);
    message.offerRequestSource !== undefined &&
      (obj.offerRequestSource = offerRequest_SourceToJSON(message.offerRequestSource));
    message.offerHeadlinePrice !== undefined && (obj.offerHeadlinePrice = message.offerHeadlinePrice);
    message.offerDenialReason !== undefined && (obj.offerDenialReason = message.offerDenialReason);
    message.offerState !== undefined && (obj.offerState = message.offerState);
    message.dwellingType !== undefined && (obj.dwellingType = message.dwellingType);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellDirectContext>, I>>(base?: I): SellDirectContext {
    return SellDirectContext.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellDirectContext>, I>>(object: I): SellDirectContext {
    const message = createBaseSellDirectContext();
    message.offerId = object.offerId ?? "";
    message.offerRequestSource = object.offerRequestSource ?? 0;
    message.offerHeadlinePrice = object.offerHeadlinePrice ?? undefined;
    message.offerDenialReason = object.offerDenialReason ?? undefined;
    message.offerState = object.offerState ?? undefined;
    message.dwellingType = object.dwellingType ?? undefined;
    return message;
  },
};

function createBaseAdClickContext(): AdClickContext {
  return { fbclid: undefined, ttclid: undefined, gclid: undefined };
}

export const AdClickContext = {
  fromJSON(object: any): AdClickContext {
    return {
      fbclid: isSet(object.fbclid) ? String(object.fbclid) : undefined,
      ttclid: isSet(object.ttclid) ? String(object.ttclid) : undefined,
      gclid: isSet(object.gclid) ? String(object.gclid) : undefined,
    };
  },

  toJSON(message: AdClickContext): unknown {
    const obj: any = {};
    message.fbclid !== undefined && (obj.fbclid = message.fbclid);
    message.ttclid !== undefined && (obj.ttclid = message.ttclid);
    message.gclid !== undefined && (obj.gclid = message.gclid);
    return obj;
  },

  create<I extends Exact<DeepPartial<AdClickContext>, I>>(base?: I): AdClickContext {
    return AdClickContext.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdClickContext>, I>>(object: I): AdClickContext {
    const message = createBaseAdClickContext();
    message.fbclid = object.fbclid ?? undefined;
    message.ttclid = object.ttclid ?? undefined;
    message.gclid = object.gclid ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
