/* eslint-disable */
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { Product, productFromJSON, productToJSON } from "../../common/common";
import { AgentReferralSpecificData } from "./agent_referral";
import { Lead_Source } from "./lead";
import { ListWithOpendoorSpecificData } from "./list_with_opendoor";
import { OpendoorDirectSpecificData } from "./opendoor_direct";
import { OfferPolicy } from "./policy";
import { ReitSpecificData } from "./reit";
import { SellWithUpsideSpecificData } from "./sell_with_upside";

export const protobufPackage = "od_protos.sell.seller.data";

/** Next ID: 32 */
export interface Offer {
  /** Identifier for the offer */
  id: string;
  /** Identifier for seller's qualified lead. */
  leadId: string;
  /** What state the offer is in. */
  state: Offer_State;
  /**
   * Whether the offer is in an active state, such that the seller may close
   * on their home.
   */
  active: boolean;
  /** Timestamps for state transitions. */
  createdAt?: Date;
  updatedAt?: Date;
  receivedAt?: Date;
  sentAt?: Date;
  viewedAt?: Date;
  rejectedAt?: Date;
  expiredAt?: Date;
  acceptedAt?: Date;
  enteredContractAt?: Date;
  contingenciesReleasedAt?: Date;
  closedAt?: Date;
  withdrawnAt?: Date;
  buyerExpiresAt?: Date;
  /**
   * The product corresponding to the offer. Details specific to the product can
   * be found in the details oneof field.
   */
  product: Product;
  /** This field will be removed. Please use the source field below. */
  sourceId: string;
  details?:
    | { $case: "opendoorOffer"; opendoorOffer: OpendoorOfferDetails }
    | { $case: "reitOffer"; reitOffer: REITOfferDetails }
    | { $case: "agentReferral"; agentReferral: AgentReferralDetails }
    | { $case: "listWithOpendoorOffer"; listWithOpendoorOffer: ListWithOpendoorDetails };
  productSpecificData?: ProductSpecificData;
  /** Identifier for the buy offer in ODLS. */
  odlsSellResponseId: string;
  /** Policies attached to this offer. */
  policy?: OfferPolicy;
  source?: Offer_Source;
  /** @deprecated */
  addOns: AddOn[];
  /** Records when a refresh was requested on this offer */
  refreshRequestedAt?: Date;
}

/**
 * State represents a state machine for offers.
 * Next ID: 10
 */
export enum Offer_State {
  /** UNKNOWN_STATE - This is an unknown or invalid state. */
  UNKNOWN_STATE = 0,
  /**
   * RECEIVED - Received means we have received an offer from a buyer, but
   * have yet to notify the seller of the offer.
   */
  RECEIVED = 1,
  /**
   * SENT - Sent means we have notified the seller of the offer, and are
   * waiting on their response.
   */
  SENT = 2,
  /** REJECTED - Rejected means the seller has rejected the offer. */
  REJECTED = 3,
  /**
   * EXPIRED - Expired means the seller did not take action before the expiry of
   * the offer, and the offer is now invalid.
   */
  EXPIRED = 4,
  /**
   * ACCEPTED - Accepted means the seller has accepted the offer and is ready
   * to proceed. This is not legally binding.
   */
  ACCEPTED = 5,
  /**
   * IN_CONTRACT - In contract means the seller has signed the purchase agreement and
   * we now have a contractual relationship with the seller.
   */
  IN_CONTRACT = 8,
  /**
   * CONTINGENCIES_RELEASED - Contingencies released means all contingencies have been released on
   * the transaction.
   */
  CONTINGENCIES_RELEASED = 9,
  /**
   * CLOSED - Closed means the transaction has been successfully completed. In the
   * case of a direct sale, this means they have sold their home.
   */
  CLOSED = 6,
  /** WITHDRAWN - Withdrawn means the seller has withdrawn from the transaction. */
  WITHDRAWN = 7,
  UNRECOGNIZED = -1,
}

export function offer_StateFromJSON(object: any): Offer_State {
  switch (object) {
    case 0:
    case "UNKNOWN_STATE":
      return Offer_State.UNKNOWN_STATE;
    case 1:
    case "RECEIVED":
      return Offer_State.RECEIVED;
    case 2:
    case "SENT":
      return Offer_State.SENT;
    case 3:
    case "REJECTED":
      return Offer_State.REJECTED;
    case 4:
    case "EXPIRED":
      return Offer_State.EXPIRED;
    case 5:
    case "ACCEPTED":
      return Offer_State.ACCEPTED;
    case 8:
    case "IN_CONTRACT":
      return Offer_State.IN_CONTRACT;
    case 9:
    case "CONTINGENCIES_RELEASED":
      return Offer_State.CONTINGENCIES_RELEASED;
    case 6:
    case "CLOSED":
      return Offer_State.CLOSED;
    case 7:
    case "WITHDRAWN":
      return Offer_State.WITHDRAWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Offer_State.UNRECOGNIZED;
  }
}

export function offer_StateToJSON(object: Offer_State): string {
  switch (object) {
    case Offer_State.UNKNOWN_STATE:
      return "UNKNOWN_STATE";
    case Offer_State.RECEIVED:
      return "RECEIVED";
    case Offer_State.SENT:
      return "SENT";
    case Offer_State.REJECTED:
      return "REJECTED";
    case Offer_State.EXPIRED:
      return "EXPIRED";
    case Offer_State.ACCEPTED:
      return "ACCEPTED";
    case Offer_State.IN_CONTRACT:
      return "IN_CONTRACT";
    case Offer_State.CONTINGENCIES_RELEASED:
      return "CONTINGENCIES_RELEASED";
    case Offer_State.CLOSED:
      return "CLOSED";
    case Offer_State.WITHDRAWN:
      return "WITHDRAWN";
    case Offer_State.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Source which stores the offer information */
export interface Offer_Source {
  type: Offer_Source_Type;
  id: string;
}

export enum Offer_Source_Type {
  UNKOWN_TYPE = 0,
  AGENT_REFERRAL = 1,
  CLEARINGHOUSE = 2,
  LASER = 3,
  WEB_BROKERAGE_OFFER = 4,
  WEB_OFFER = 5,
  UNRECOGNIZED = -1,
}

export function offer_Source_TypeFromJSON(object: any): Offer_Source_Type {
  switch (object) {
    case 0:
    case "UNKOWN_TYPE":
      return Offer_Source_Type.UNKOWN_TYPE;
    case 1:
    case "AGENT_REFERRAL":
      return Offer_Source_Type.AGENT_REFERRAL;
    case 2:
    case "CLEARINGHOUSE":
      return Offer_Source_Type.CLEARINGHOUSE;
    case 3:
    case "LASER":
      return Offer_Source_Type.LASER;
    case 4:
    case "WEB_BROKERAGE_OFFER":
      return Offer_Source_Type.WEB_BROKERAGE_OFFER;
    case 5:
    case "WEB_OFFER":
      return Offer_Source_Type.WEB_OFFER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Offer_Source_Type.UNRECOGNIZED;
  }
}

export function offer_Source_TypeToJSON(object: Offer_Source_Type): string {
  switch (object) {
    case Offer_Source_Type.UNKOWN_TYPE:
      return "UNKOWN_TYPE";
    case Offer_Source_Type.AGENT_REFERRAL:
      return "AGENT_REFERRAL";
    case Offer_Source_Type.CLEARINGHOUSE:
      return "CLEARINGHOUSE";
    case Offer_Source_Type.LASER:
      return "LASER";
    case Offer_Source_Type.WEB_BROKERAGE_OFFER:
      return "WEB_BROKERAGE_OFFER";
    case Offer_Source_Type.WEB_OFFER:
      return "WEB_OFFER";
    case Offer_Source_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Data specific to a single product's offer. This type wraps a single oneof so
 * that we can use the generated Go type directly in the Seller service.
 * Next ID: 6
 */
export interface ProductSpecificData {
  data?:
    | { $case: "opendoorDirect"; opendoorDirect: OpendoorDirectSpecificData }
    | { $case: "reit"; reit: ReitSpecificData }
    | { $case: "agentReferral"; agentReferral: AgentReferralSpecificData }
    | { $case: "listWithOpendoor"; listWithOpendoor: ListWithOpendoorSpecificData }
    | { $case: "sellWithUpside"; sellWithUpside: SellWithUpsideSpecificData };
}

/**
 * Represents an offer from Opendoor to acquire the home.
 * Next ID: 4
 */
export interface OpendoorOfferDetails {
  comps: Comp[];
  netProceeds?: NetProceeds;
  estimatedDaysOnMarket: number;
}

/**
 * Comp is a comparative listing for a subject home.
 * Next ID: 9
 */
export interface Comp {
  addressId: string;
  homeDetailId: string;
  /** Listing related fields. */
  originalListPriceCents: number;
  listPriceCents: number;
  closePriceCents: number;
  listDate?: Date;
  pendingDate?: Date;
  closeDate?: Date;
}

/**
 * Describes the headline price and breakdown of fees charged on the offer.
 * Next ID: 6
 */
export interface NetProceeds {
  /** The offered amount. */
  headlinePriceCents: number;
  /** Exhaustive breakdown of all the fees charged on the offer. */
  opendoorServiceChargeCents: number;
  closingCostCents: number;
  repairCostCents: number;
  netCashCertain: boolean;
}

/**
 * Represents an offer from an institutional buyer to acquire the home.
 * Next ID: 1
 */
export interface REITOfferDetails {
}

/**
 * Represents an offer to refer an agent to the seller.
 * Next ID: 10
 */
export interface AgentReferralDetails {
  /**
   * An outbound referral tracks the various states of the AgentReferralInfo
   * (sent, displayed, accepted). Used as a primary key to reference the
   * referral with.
   */
  outboundReferralId: string;
  /**
   * ID of the listing agent partnership
   * Downstream consumers (ODLS, SellerCo) use this ID to inform Agent Referral service about updates.
   * The combination of agent_partnership_id and sell_request_id should be unique.
   */
  agentPartnershipId: string;
  /**
   * Score indicates strength of the match between the agent and the given
   * sell request. A higher score is a better fit.
   */
  score: number;
  agentBio?: AgentReferralDetails_AgentBio;
  agentStats?: AgentReferralDetails_AgentStats;
  /** Reviews of the agent for display */
  agentReviews: AgentReferralDetails_AgentReview[];
  /** List of recently sold homes. */
  recentSaleAddresses: AgentReferralDetails_Address[];
  /**
   * Specializations describes the types of houses / homeowners the
   * agent prefers to work with.
   */
  specializations: string[];
}

export interface AgentReferralDetails_AgentReview {
  reviewerName: string;
  reviewBody: string;
  fiveStarRatingScore: number;
}

export interface AgentReferralDetails_HomeCoordinate {
  lat: number;
  lon: number;
}

export interface AgentReferralDetails_AgentBio {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  /** Cabinet id for the agent's profile photo. */
  profilePhotoId: string;
  profilePhotoUrl: string;
  gender: AgentReferralDetails_AgentBio_Gender;
}

export enum AgentReferralDetails_AgentBio_Gender {
  UNKNOWN_GENDER = 0,
  FEMALE = 1,
  MALE = 2,
  NON_BINARY = 3,
  UNRECOGNIZED = -1,
}

export function agentReferralDetails_AgentBio_GenderFromJSON(object: any): AgentReferralDetails_AgentBio_Gender {
  switch (object) {
    case 0:
    case "UNKNOWN_GENDER":
      return AgentReferralDetails_AgentBio_Gender.UNKNOWN_GENDER;
    case 1:
    case "FEMALE":
      return AgentReferralDetails_AgentBio_Gender.FEMALE;
    case 2:
    case "MALE":
      return AgentReferralDetails_AgentBio_Gender.MALE;
    case 3:
    case "NON_BINARY":
      return AgentReferralDetails_AgentBio_Gender.NON_BINARY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AgentReferralDetails_AgentBio_Gender.UNRECOGNIZED;
  }
}

export function agentReferralDetails_AgentBio_GenderToJSON(object: AgentReferralDetails_AgentBio_Gender): string {
  switch (object) {
    case AgentReferralDetails_AgentBio_Gender.UNKNOWN_GENDER:
      return "UNKNOWN_GENDER";
    case AgentReferralDetails_AgentBio_Gender.FEMALE:
      return "FEMALE";
    case AgentReferralDetails_AgentBio_Gender.MALE:
      return "MALE";
    case AgentReferralDetails_AgentBio_Gender.NON_BINARY:
      return "NON_BINARY";
    case AgentReferralDetails_AgentBio_Gender.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Statistics about the agent -- populated manually by operators in the short term. */
export interface AgentReferralDetails_AgentStats {
  /** Used to display '12+ years of experience' */
  yearsOfExperience: number;
  /** Average review score on a 5.0 scale. */
  avgFiveStarRatingScore: number;
  /** Total number of reviews for an Agent */
  ratingsCount: number;
  /** Number of buy transactions the agent was involved in during last 12 months. */
  numHomesSoldInLast12Mo: number;
  /** Average days listed during last 12 months. */
  avgDaysListedLast12Mo: number;
  /** Ratio of sale price to original price during last 12 months. */
  avgListToClosePriceRatioLast12Mo: number;
}

export interface AgentReferralDetails_Address {
  /** Identifier for the Casa service. */
  addressId: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  suite: string;
  latitude: number;
  longitude: number;
}

/**
 * Represents an offer for the List with Opendoor product.
 * Next ID: 1
 */
export interface ListWithOpendoorDetails {
}

/**
 * LeadOffers encapsulates offers affiliated with a lead.
 * Next ID: 3
 */
export interface LeadOffers {
  source?: Lead_Source;
  offers: Offer[];
}

/**
 * DEPRECATED - Addon encapsulates addons associated with an offer.
 * Next ID: 5
 */
export interface AddOn {
  offerUuid: string;
  addOnType: AddOn_AddOnType;
  eligibleAt?: Date;
  included?: boolean;
}

export enum AddOn_AddOnType {
  UNKNOWN_ADD_ON = 0,
  HOME_RESERVE = 1,
  BWOD = 2,
  UNRECOGNIZED = -1,
}

export function addOn_AddOnTypeFromJSON(object: any): AddOn_AddOnType {
  switch (object) {
    case 0:
    case "UNKNOWN_ADD_ON":
      return AddOn_AddOnType.UNKNOWN_ADD_ON;
    case 1:
    case "HOME_RESERVE":
      return AddOn_AddOnType.HOME_RESERVE;
    case 2:
    case "BWOD":
      return AddOn_AddOnType.BWOD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AddOn_AddOnType.UNRECOGNIZED;
  }
}

export function addOn_AddOnTypeToJSON(object: AddOn_AddOnType): string {
  switch (object) {
    case AddOn_AddOnType.UNKNOWN_ADD_ON:
      return "UNKNOWN_ADD_ON";
    case AddOn_AddOnType.HOME_RESERVE:
      return "HOME_RESERVE";
    case AddOn_AddOnType.BWOD:
      return "BWOD";
    case AddOn_AddOnType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseOffer(): Offer {
  return {
    id: "",
    leadId: "",
    state: 0,
    active: false,
    createdAt: undefined,
    updatedAt: undefined,
    receivedAt: undefined,
    sentAt: undefined,
    viewedAt: undefined,
    rejectedAt: undefined,
    expiredAt: undefined,
    acceptedAt: undefined,
    enteredContractAt: undefined,
    contingenciesReleasedAt: undefined,
    closedAt: undefined,
    withdrawnAt: undefined,
    buyerExpiresAt: undefined,
    product: 0,
    sourceId: "",
    details: undefined,
    productSpecificData: undefined,
    odlsSellResponseId: "",
    policy: undefined,
    source: undefined,
    addOns: [],
    refreshRequestedAt: undefined,
  };
}

export const Offer = {
  fromJSON(object: any): Offer {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      state: isSet(object.state) ? offer_StateFromJSON(object.state) : 0,
      active: isSet(object.active) ? Boolean(object.active) : false,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
      receivedAt: isSet(object.receivedAt) ? fromJsonTimestamp(object.receivedAt) : undefined,
      sentAt: isSet(object.sentAt) ? fromJsonTimestamp(object.sentAt) : undefined,
      viewedAt: isSet(object.viewedAt) ? fromJsonTimestamp(object.viewedAt) : undefined,
      rejectedAt: isSet(object.rejectedAt) ? fromJsonTimestamp(object.rejectedAt) : undefined,
      expiredAt: isSet(object.expiredAt) ? fromJsonTimestamp(object.expiredAt) : undefined,
      acceptedAt: isSet(object.acceptedAt) ? fromJsonTimestamp(object.acceptedAt) : undefined,
      enteredContractAt: isSet(object.enteredContractAt) ? fromJsonTimestamp(object.enteredContractAt) : undefined,
      contingenciesReleasedAt: isSet(object.contingenciesReleasedAt)
        ? fromJsonTimestamp(object.contingenciesReleasedAt)
        : undefined,
      closedAt: isSet(object.closedAt) ? fromJsonTimestamp(object.closedAt) : undefined,
      withdrawnAt: isSet(object.withdrawnAt) ? fromJsonTimestamp(object.withdrawnAt) : undefined,
      buyerExpiresAt: isSet(object.buyerExpiresAt) ? fromJsonTimestamp(object.buyerExpiresAt) : undefined,
      product: isSet(object.product) ? productFromJSON(object.product) : 0,
      sourceId: isSet(object.sourceId) ? String(object.sourceId) : "",
      details: isSet(object.opendoorOffer)
        ? { $case: "opendoorOffer", opendoorOffer: OpendoorOfferDetails.fromJSON(object.opendoorOffer) }
        : isSet(object.reitOffer)
        ? { $case: "reitOffer", reitOffer: REITOfferDetails.fromJSON(object.reitOffer) }
        : isSet(object.agentReferral)
        ? { $case: "agentReferral", agentReferral: AgentReferralDetails.fromJSON(object.agentReferral) }
        : isSet(object.listWithOpendoorOffer)
        ? {
          $case: "listWithOpendoorOffer",
          listWithOpendoorOffer: ListWithOpendoorDetails.fromJSON(object.listWithOpendoorOffer),
        }
        : undefined,
      productSpecificData: isSet(object.productSpecificData)
        ? ProductSpecificData.fromJSON(object.productSpecificData)
        : undefined,
      odlsSellResponseId: isSet(object.odlsSellResponseId) ? String(object.odlsSellResponseId) : "",
      policy: isSet(object.policy) ? OfferPolicy.fromJSON(object.policy) : undefined,
      source: isSet(object.source) ? Offer_Source.fromJSON(object.source) : undefined,
      addOns: Array.isArray(object?.addOns)
        ? object.addOns.map((e: any) => AddOn.fromJSON(e))
        : [],
      refreshRequestedAt: isSet(object.refreshRequestedAt) ? fromJsonTimestamp(object.refreshRequestedAt) : undefined,
    };
  },

  toJSON(message: Offer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.leadId !== undefined && (obj.leadId = message.leadId);
    message.state !== undefined && (obj.state = offer_StateToJSON(message.state));
    message.active !== undefined && (obj.active = message.active);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt.toISOString());
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt.toISOString());
    message.receivedAt !== undefined && (obj.receivedAt = message.receivedAt.toISOString());
    message.sentAt !== undefined && (obj.sentAt = message.sentAt.toISOString());
    message.viewedAt !== undefined && (obj.viewedAt = message.viewedAt.toISOString());
    message.rejectedAt !== undefined && (obj.rejectedAt = message.rejectedAt.toISOString());
    message.expiredAt !== undefined && (obj.expiredAt = message.expiredAt.toISOString());
    message.acceptedAt !== undefined && (obj.acceptedAt = message.acceptedAt.toISOString());
    message.enteredContractAt !== undefined && (obj.enteredContractAt = message.enteredContractAt.toISOString());
    message.contingenciesReleasedAt !== undefined &&
      (obj.contingenciesReleasedAt = message.contingenciesReleasedAt.toISOString());
    message.closedAt !== undefined && (obj.closedAt = message.closedAt.toISOString());
    message.withdrawnAt !== undefined && (obj.withdrawnAt = message.withdrawnAt.toISOString());
    message.buyerExpiresAt !== undefined && (obj.buyerExpiresAt = message.buyerExpiresAt.toISOString());
    message.product !== undefined && (obj.product = productToJSON(message.product));
    message.sourceId !== undefined && (obj.sourceId = message.sourceId);
    message.details?.$case === "opendoorOffer" && (obj.opendoorOffer = message.details?.opendoorOffer
      ? OpendoorOfferDetails.toJSON(message.details?.opendoorOffer)
      : undefined);
    message.details?.$case === "reitOffer" &&
      (obj.reitOffer = message.details?.reitOffer ? REITOfferDetails.toJSON(message.details?.reitOffer) : undefined);
    message.details?.$case === "agentReferral" && (obj.agentReferral = message.details?.agentReferral
      ? AgentReferralDetails.toJSON(message.details?.agentReferral)
      : undefined);
    message.details?.$case === "listWithOpendoorOffer" &&
      (obj.listWithOpendoorOffer = message.details?.listWithOpendoorOffer
        ? ListWithOpendoorDetails.toJSON(message.details?.listWithOpendoorOffer)
        : undefined);
    message.productSpecificData !== undefined && (obj.productSpecificData = message.productSpecificData
      ? ProductSpecificData.toJSON(message.productSpecificData)
      : undefined);
    message.odlsSellResponseId !== undefined && (obj.odlsSellResponseId = message.odlsSellResponseId);
    message.policy !== undefined && (obj.policy = message.policy ? OfferPolicy.toJSON(message.policy) : undefined);
    message.source !== undefined && (obj.source = message.source ? Offer_Source.toJSON(message.source) : undefined);
    if (message.addOns) {
      obj.addOns = message.addOns.map((e) => e ? AddOn.toJSON(e) : undefined);
    } else {
      obj.addOns = [];
    }
    message.refreshRequestedAt !== undefined && (obj.refreshRequestedAt = message.refreshRequestedAt.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<Offer>, I>>(base?: I): Offer {
    return Offer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Offer>, I>>(object: I): Offer {
    const message = createBaseOffer();
    message.id = object.id ?? "";
    message.leadId = object.leadId ?? "";
    message.state = object.state ?? 0;
    message.active = object.active ?? false;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.receivedAt = object.receivedAt ?? undefined;
    message.sentAt = object.sentAt ?? undefined;
    message.viewedAt = object.viewedAt ?? undefined;
    message.rejectedAt = object.rejectedAt ?? undefined;
    message.expiredAt = object.expiredAt ?? undefined;
    message.acceptedAt = object.acceptedAt ?? undefined;
    message.enteredContractAt = object.enteredContractAt ?? undefined;
    message.contingenciesReleasedAt = object.contingenciesReleasedAt ?? undefined;
    message.closedAt = object.closedAt ?? undefined;
    message.withdrawnAt = object.withdrawnAt ?? undefined;
    message.buyerExpiresAt = object.buyerExpiresAt ?? undefined;
    message.product = object.product ?? 0;
    message.sourceId = object.sourceId ?? "";
    if (
      object.details?.$case === "opendoorOffer" &&
      object.details?.opendoorOffer !== undefined &&
      object.details?.opendoorOffer !== null
    ) {
      message.details = {
        $case: "opendoorOffer",
        opendoorOffer: OpendoorOfferDetails.fromPartial(object.details.opendoorOffer),
      };
    }
    if (
      object.details?.$case === "reitOffer" &&
      object.details?.reitOffer !== undefined &&
      object.details?.reitOffer !== null
    ) {
      message.details = { $case: "reitOffer", reitOffer: REITOfferDetails.fromPartial(object.details.reitOffer) };
    }
    if (
      object.details?.$case === "agentReferral" &&
      object.details?.agentReferral !== undefined &&
      object.details?.agentReferral !== null
    ) {
      message.details = {
        $case: "agentReferral",
        agentReferral: AgentReferralDetails.fromPartial(object.details.agentReferral),
      };
    }
    if (
      object.details?.$case === "listWithOpendoorOffer" &&
      object.details?.listWithOpendoorOffer !== undefined &&
      object.details?.listWithOpendoorOffer !== null
    ) {
      message.details = {
        $case: "listWithOpendoorOffer",
        listWithOpendoorOffer: ListWithOpendoorDetails.fromPartial(object.details.listWithOpendoorOffer),
      };
    }
    message.productSpecificData = (object.productSpecificData !== undefined && object.productSpecificData !== null)
      ? ProductSpecificData.fromPartial(object.productSpecificData)
      : undefined;
    message.odlsSellResponseId = object.odlsSellResponseId ?? "";
    message.policy = (object.policy !== undefined && object.policy !== null)
      ? OfferPolicy.fromPartial(object.policy)
      : undefined;
    message.source = (object.source !== undefined && object.source !== null)
      ? Offer_Source.fromPartial(object.source)
      : undefined;
    message.addOns = object.addOns?.map((e) => AddOn.fromPartial(e)) || [];
    message.refreshRequestedAt = object.refreshRequestedAt ?? undefined;
    return message;
  },
};

function createBaseOffer_Source(): Offer_Source {
  return { type: 0, id: "" };
}

export const Offer_Source = {
  fromJSON(object: any): Offer_Source {
    return {
      type: isSet(object.type) ? offer_Source_TypeFromJSON(object.type) : 0,
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: Offer_Source): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = offer_Source_TypeToJSON(message.type));
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<Offer_Source>, I>>(base?: I): Offer_Source {
    return Offer_Source.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Offer_Source>, I>>(object: I): Offer_Source {
    const message = createBaseOffer_Source();
    message.type = object.type ?? 0;
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseProductSpecificData(): ProductSpecificData {
  return { data: undefined };
}

export const ProductSpecificData = {
  fromJSON(object: any): ProductSpecificData {
    return {
      data: isSet(object.opendoorDirect)
        ? { $case: "opendoorDirect", opendoorDirect: OpendoorDirectSpecificData.fromJSON(object.opendoorDirect) }
        : isSet(object.reit)
        ? { $case: "reit", reit: ReitSpecificData.fromJSON(object.reit) }
        : isSet(object.agentReferral)
        ? { $case: "agentReferral", agentReferral: AgentReferralSpecificData.fromJSON(object.agentReferral) }
        : isSet(object.listWithOpendoor)
        ? {
          $case: "listWithOpendoor",
          listWithOpendoor: ListWithOpendoorSpecificData.fromJSON(object.listWithOpendoor),
        }
        : isSet(object.sellWithUpside)
        ? { $case: "sellWithUpside", sellWithUpside: SellWithUpsideSpecificData.fromJSON(object.sellWithUpside) }
        : undefined,
    };
  },

  toJSON(message: ProductSpecificData): unknown {
    const obj: any = {};
    message.data?.$case === "opendoorDirect" && (obj.opendoorDirect = message.data?.opendoorDirect
      ? OpendoorDirectSpecificData.toJSON(message.data?.opendoorDirect)
      : undefined);
    message.data?.$case === "reit" &&
      (obj.reit = message.data?.reit ? ReitSpecificData.toJSON(message.data?.reit) : undefined);
    message.data?.$case === "agentReferral" && (obj.agentReferral = message.data?.agentReferral
      ? AgentReferralSpecificData.toJSON(message.data?.agentReferral)
      : undefined);
    message.data?.$case === "listWithOpendoor" && (obj.listWithOpendoor = message.data?.listWithOpendoor
      ? ListWithOpendoorSpecificData.toJSON(message.data?.listWithOpendoor)
      : undefined);
    message.data?.$case === "sellWithUpside" && (obj.sellWithUpside = message.data?.sellWithUpside
      ? SellWithUpsideSpecificData.toJSON(message.data?.sellWithUpside)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductSpecificData>, I>>(base?: I): ProductSpecificData {
    return ProductSpecificData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductSpecificData>, I>>(object: I): ProductSpecificData {
    const message = createBaseProductSpecificData();
    if (
      object.data?.$case === "opendoorDirect" &&
      object.data?.opendoorDirect !== undefined &&
      object.data?.opendoorDirect !== null
    ) {
      message.data = {
        $case: "opendoorDirect",
        opendoorDirect: OpendoorDirectSpecificData.fromPartial(object.data.opendoorDirect),
      };
    }
    if (object.data?.$case === "reit" && object.data?.reit !== undefined && object.data?.reit !== null) {
      message.data = { $case: "reit", reit: ReitSpecificData.fromPartial(object.data.reit) };
    }
    if (
      object.data?.$case === "agentReferral" &&
      object.data?.agentReferral !== undefined &&
      object.data?.agentReferral !== null
    ) {
      message.data = {
        $case: "agentReferral",
        agentReferral: AgentReferralSpecificData.fromPartial(object.data.agentReferral),
      };
    }
    if (
      object.data?.$case === "listWithOpendoor" &&
      object.data?.listWithOpendoor !== undefined &&
      object.data?.listWithOpendoor !== null
    ) {
      message.data = {
        $case: "listWithOpendoor",
        listWithOpendoor: ListWithOpendoorSpecificData.fromPartial(object.data.listWithOpendoor),
      };
    }
    if (
      object.data?.$case === "sellWithUpside" &&
      object.data?.sellWithUpside !== undefined &&
      object.data?.sellWithUpside !== null
    ) {
      message.data = {
        $case: "sellWithUpside",
        sellWithUpside: SellWithUpsideSpecificData.fromPartial(object.data.sellWithUpside),
      };
    }
    return message;
  },
};

function createBaseOpendoorOfferDetails(): OpendoorOfferDetails {
  return { comps: [], netProceeds: undefined, estimatedDaysOnMarket: 0 };
}

export const OpendoorOfferDetails = {
  fromJSON(object: any): OpendoorOfferDetails {
    return {
      comps: Array.isArray(object?.comps) ? object.comps.map((e: any) => Comp.fromJSON(e)) : [],
      netProceeds: isSet(object.netProceeds) ? NetProceeds.fromJSON(object.netProceeds) : undefined,
      estimatedDaysOnMarket: isSet(object.estimatedDaysOnMarket) ? Number(object.estimatedDaysOnMarket) : 0,
    };
  },

  toJSON(message: OpendoorOfferDetails): unknown {
    const obj: any = {};
    if (message.comps) {
      obj.comps = message.comps.map((e) => e ? Comp.toJSON(e) : undefined);
    } else {
      obj.comps = [];
    }
    message.netProceeds !== undefined &&
      (obj.netProceeds = message.netProceeds ? NetProceeds.toJSON(message.netProceeds) : undefined);
    message.estimatedDaysOnMarket !== undefined &&
      (obj.estimatedDaysOnMarket = Math.round(message.estimatedDaysOnMarket));
    return obj;
  },

  create<I extends Exact<DeepPartial<OpendoorOfferDetails>, I>>(base?: I): OpendoorOfferDetails {
    return OpendoorOfferDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OpendoorOfferDetails>, I>>(object: I): OpendoorOfferDetails {
    const message = createBaseOpendoorOfferDetails();
    message.comps = object.comps?.map((e) => Comp.fromPartial(e)) || [];
    message.netProceeds = (object.netProceeds !== undefined && object.netProceeds !== null)
      ? NetProceeds.fromPartial(object.netProceeds)
      : undefined;
    message.estimatedDaysOnMarket = object.estimatedDaysOnMarket ?? 0;
    return message;
  },
};

function createBaseComp(): Comp {
  return {
    addressId: "",
    homeDetailId: "",
    originalListPriceCents: 0,
    listPriceCents: 0,
    closePriceCents: 0,
    listDate: undefined,
    pendingDate: undefined,
    closeDate: undefined,
  };
}

export const Comp = {
  fromJSON(object: any): Comp {
    return {
      addressId: isSet(object.addressId) ? String(object.addressId) : "",
      homeDetailId: isSet(object.homeDetailId) ? String(object.homeDetailId) : "",
      originalListPriceCents: isSet(object.originalListPriceCents) ? Number(object.originalListPriceCents) : 0,
      listPriceCents: isSet(object.listPriceCents) ? Number(object.listPriceCents) : 0,
      closePriceCents: isSet(object.closePriceCents) ? Number(object.closePriceCents) : 0,
      listDate: isSet(object.listDate) ? fromJsonTimestamp(object.listDate) : undefined,
      pendingDate: isSet(object.pendingDate) ? fromJsonTimestamp(object.pendingDate) : undefined,
      closeDate: isSet(object.closeDate) ? fromJsonTimestamp(object.closeDate) : undefined,
    };
  },

  toJSON(message: Comp): unknown {
    const obj: any = {};
    message.addressId !== undefined && (obj.addressId = message.addressId);
    message.homeDetailId !== undefined && (obj.homeDetailId = message.homeDetailId);
    message.originalListPriceCents !== undefined &&
      (obj.originalListPriceCents = Math.round(message.originalListPriceCents));
    message.listPriceCents !== undefined && (obj.listPriceCents = Math.round(message.listPriceCents));
    message.closePriceCents !== undefined && (obj.closePriceCents = Math.round(message.closePriceCents));
    message.listDate !== undefined && (obj.listDate = message.listDate.toISOString());
    message.pendingDate !== undefined && (obj.pendingDate = message.pendingDate.toISOString());
    message.closeDate !== undefined && (obj.closeDate = message.closeDate.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<Comp>, I>>(base?: I): Comp {
    return Comp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Comp>, I>>(object: I): Comp {
    const message = createBaseComp();
    message.addressId = object.addressId ?? "";
    message.homeDetailId = object.homeDetailId ?? "";
    message.originalListPriceCents = object.originalListPriceCents ?? 0;
    message.listPriceCents = object.listPriceCents ?? 0;
    message.closePriceCents = object.closePriceCents ?? 0;
    message.listDate = object.listDate ?? undefined;
    message.pendingDate = object.pendingDate ?? undefined;
    message.closeDate = object.closeDate ?? undefined;
    return message;
  },
};

function createBaseNetProceeds(): NetProceeds {
  return {
    headlinePriceCents: 0,
    opendoorServiceChargeCents: 0,
    closingCostCents: 0,
    repairCostCents: 0,
    netCashCertain: false,
  };
}

export const NetProceeds = {
  fromJSON(object: any): NetProceeds {
    return {
      headlinePriceCents: isSet(object.headlinePriceCents) ? Number(object.headlinePriceCents) : 0,
      opendoorServiceChargeCents: isSet(object.opendoorServiceChargeCents)
        ? Number(object.opendoorServiceChargeCents)
        : 0,
      closingCostCents: isSet(object.closingCostCents) ? Number(object.closingCostCents) : 0,
      repairCostCents: isSet(object.repairCostCents) ? Number(object.repairCostCents) : 0,
      netCashCertain: isSet(object.netCashCertain) ? Boolean(object.netCashCertain) : false,
    };
  },

  toJSON(message: NetProceeds): unknown {
    const obj: any = {};
    message.headlinePriceCents !== undefined && (obj.headlinePriceCents = Math.round(message.headlinePriceCents));
    message.opendoorServiceChargeCents !== undefined &&
      (obj.opendoorServiceChargeCents = Math.round(message.opendoorServiceChargeCents));
    message.closingCostCents !== undefined && (obj.closingCostCents = Math.round(message.closingCostCents));
    message.repairCostCents !== undefined && (obj.repairCostCents = Math.round(message.repairCostCents));
    message.netCashCertain !== undefined && (obj.netCashCertain = message.netCashCertain);
    return obj;
  },

  create<I extends Exact<DeepPartial<NetProceeds>, I>>(base?: I): NetProceeds {
    return NetProceeds.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NetProceeds>, I>>(object: I): NetProceeds {
    const message = createBaseNetProceeds();
    message.headlinePriceCents = object.headlinePriceCents ?? 0;
    message.opendoorServiceChargeCents = object.opendoorServiceChargeCents ?? 0;
    message.closingCostCents = object.closingCostCents ?? 0;
    message.repairCostCents = object.repairCostCents ?? 0;
    message.netCashCertain = object.netCashCertain ?? false;
    return message;
  },
};

function createBaseREITOfferDetails(): REITOfferDetails {
  return {};
}

export const REITOfferDetails = {
  fromJSON(_: any): REITOfferDetails {
    return {};
  },

  toJSON(_: REITOfferDetails): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<REITOfferDetails>, I>>(base?: I): REITOfferDetails {
    return REITOfferDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<REITOfferDetails>, I>>(_: I): REITOfferDetails {
    const message = createBaseREITOfferDetails();
    return message;
  },
};

function createBaseAgentReferralDetails(): AgentReferralDetails {
  return {
    outboundReferralId: "",
    agentPartnershipId: "",
    score: 0,
    agentBio: undefined,
    agentStats: undefined,
    agentReviews: [],
    recentSaleAddresses: [],
    specializations: [],
  };
}

export const AgentReferralDetails = {
  fromJSON(object: any): AgentReferralDetails {
    return {
      outboundReferralId: isSet(object.outboundReferralId) ? String(object.outboundReferralId) : "",
      agentPartnershipId: isSet(object.agentPartnershipId) ? String(object.agentPartnershipId) : "",
      score: isSet(object.score) ? Number(object.score) : 0,
      agentBio: isSet(object.agentBio) ? AgentReferralDetails_AgentBio.fromJSON(object.agentBio) : undefined,
      agentStats: isSet(object.agentStats) ? AgentReferralDetails_AgentStats.fromJSON(object.agentStats) : undefined,
      agentReviews: Array.isArray(object?.agentReviews)
        ? object.agentReviews.map((e: any) => AgentReferralDetails_AgentReview.fromJSON(e))
        : [],
      recentSaleAddresses: Array.isArray(object?.recentSaleAddresses)
        ? object.recentSaleAddresses.map((e: any) => AgentReferralDetails_Address.fromJSON(e))
        : [],
      specializations: Array.isArray(object?.specializations) ? object.specializations.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: AgentReferralDetails): unknown {
    const obj: any = {};
    message.outboundReferralId !== undefined && (obj.outboundReferralId = message.outboundReferralId);
    message.agentPartnershipId !== undefined && (obj.agentPartnershipId = message.agentPartnershipId);
    message.score !== undefined && (obj.score = message.score);
    message.agentBio !== undefined &&
      (obj.agentBio = message.agentBio ? AgentReferralDetails_AgentBio.toJSON(message.agentBio) : undefined);
    message.agentStats !== undefined &&
      (obj.agentStats = message.agentStats ? AgentReferralDetails_AgentStats.toJSON(message.agentStats) : undefined);
    if (message.agentReviews) {
      obj.agentReviews = message.agentReviews.map((e) => e ? AgentReferralDetails_AgentReview.toJSON(e) : undefined);
    } else {
      obj.agentReviews = [];
    }
    if (message.recentSaleAddresses) {
      obj.recentSaleAddresses = message.recentSaleAddresses.map((e) =>
        e ? AgentReferralDetails_Address.toJSON(e) : undefined
      );
    } else {
      obj.recentSaleAddresses = [];
    }
    if (message.specializations) {
      obj.specializations = message.specializations.map((e) => e);
    } else {
      obj.specializations = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentReferralDetails>, I>>(base?: I): AgentReferralDetails {
    return AgentReferralDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AgentReferralDetails>, I>>(object: I): AgentReferralDetails {
    const message = createBaseAgentReferralDetails();
    message.outboundReferralId = object.outboundReferralId ?? "";
    message.agentPartnershipId = object.agentPartnershipId ?? "";
    message.score = object.score ?? 0;
    message.agentBio = (object.agentBio !== undefined && object.agentBio !== null)
      ? AgentReferralDetails_AgentBio.fromPartial(object.agentBio)
      : undefined;
    message.agentStats = (object.agentStats !== undefined && object.agentStats !== null)
      ? AgentReferralDetails_AgentStats.fromPartial(object.agentStats)
      : undefined;
    message.agentReviews = object.agentReviews?.map((e) => AgentReferralDetails_AgentReview.fromPartial(e)) || [];
    message.recentSaleAddresses = object.recentSaleAddresses?.map((e) => AgentReferralDetails_Address.fromPartial(e)) ||
      [];
    message.specializations = object.specializations?.map((e) => e) || [];
    return message;
  },
};

function createBaseAgentReferralDetails_AgentReview(): AgentReferralDetails_AgentReview {
  return { reviewerName: "", reviewBody: "", fiveStarRatingScore: 0 };
}

export const AgentReferralDetails_AgentReview = {
  fromJSON(object: any): AgentReferralDetails_AgentReview {
    return {
      reviewerName: isSet(object.reviewerName) ? String(object.reviewerName) : "",
      reviewBody: isSet(object.reviewBody) ? String(object.reviewBody) : "",
      fiveStarRatingScore: isSet(object.fiveStarRatingScore) ? Number(object.fiveStarRatingScore) : 0,
    };
  },

  toJSON(message: AgentReferralDetails_AgentReview): unknown {
    const obj: any = {};
    message.reviewerName !== undefined && (obj.reviewerName = message.reviewerName);
    message.reviewBody !== undefined && (obj.reviewBody = message.reviewBody);
    message.fiveStarRatingScore !== undefined && (obj.fiveStarRatingScore = message.fiveStarRatingScore);
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentReferralDetails_AgentReview>, I>>(
    base?: I,
  ): AgentReferralDetails_AgentReview {
    return AgentReferralDetails_AgentReview.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AgentReferralDetails_AgentReview>, I>>(
    object: I,
  ): AgentReferralDetails_AgentReview {
    const message = createBaseAgentReferralDetails_AgentReview();
    message.reviewerName = object.reviewerName ?? "";
    message.reviewBody = object.reviewBody ?? "";
    message.fiveStarRatingScore = object.fiveStarRatingScore ?? 0;
    return message;
  },
};

function createBaseAgentReferralDetails_HomeCoordinate(): AgentReferralDetails_HomeCoordinate {
  return { lat: 0, lon: 0 };
}

export const AgentReferralDetails_HomeCoordinate = {
  fromJSON(object: any): AgentReferralDetails_HomeCoordinate {
    return { lat: isSet(object.lat) ? Number(object.lat) : 0, lon: isSet(object.lon) ? Number(object.lon) : 0 };
  },

  toJSON(message: AgentReferralDetails_HomeCoordinate): unknown {
    const obj: any = {};
    message.lat !== undefined && (obj.lat = message.lat);
    message.lon !== undefined && (obj.lon = message.lon);
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentReferralDetails_HomeCoordinate>, I>>(
    base?: I,
  ): AgentReferralDetails_HomeCoordinate {
    return AgentReferralDetails_HomeCoordinate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AgentReferralDetails_HomeCoordinate>, I>>(
    object: I,
  ): AgentReferralDetails_HomeCoordinate {
    const message = createBaseAgentReferralDetails_HomeCoordinate();
    message.lat = object.lat ?? 0;
    message.lon = object.lon ?? 0;
    return message;
  },
};

function createBaseAgentReferralDetails_AgentBio(): AgentReferralDetails_AgentBio {
  return {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    profilePhotoId: "",
    profilePhotoUrl: "",
    gender: 0,
  };
}

export const AgentReferralDetails_AgentBio = {
  fromJSON(object: any): AgentReferralDetails_AgentBio {
    return {
      firstName: isSet(object.firstName) ? String(object.firstName) : "",
      lastName: isSet(object.lastName) ? String(object.lastName) : "",
      email: isSet(object.email) ? String(object.email) : "",
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : "",
      profilePhotoId: isSet(object.profilePhotoId) ? String(object.profilePhotoId) : "",
      profilePhotoUrl: isSet(object.profilePhotoUrl) ? String(object.profilePhotoUrl) : "",
      gender: isSet(object.gender) ? agentReferralDetails_AgentBio_GenderFromJSON(object.gender) : 0,
    };
  },

  toJSON(message: AgentReferralDetails_AgentBio): unknown {
    const obj: any = {};
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.email !== undefined && (obj.email = message.email);
    message.phoneNumber !== undefined && (obj.phoneNumber = message.phoneNumber);
    message.profilePhotoId !== undefined && (obj.profilePhotoId = message.profilePhotoId);
    message.profilePhotoUrl !== undefined && (obj.profilePhotoUrl = message.profilePhotoUrl);
    message.gender !== undefined && (obj.gender = agentReferralDetails_AgentBio_GenderToJSON(message.gender));
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentReferralDetails_AgentBio>, I>>(base?: I): AgentReferralDetails_AgentBio {
    return AgentReferralDetails_AgentBio.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AgentReferralDetails_AgentBio>, I>>(
    object: I,
  ): AgentReferralDetails_AgentBio {
    const message = createBaseAgentReferralDetails_AgentBio();
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.email = object.email ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.profilePhotoId = object.profilePhotoId ?? "";
    message.profilePhotoUrl = object.profilePhotoUrl ?? "";
    message.gender = object.gender ?? 0;
    return message;
  },
};

function createBaseAgentReferralDetails_AgentStats(): AgentReferralDetails_AgentStats {
  return {
    yearsOfExperience: 0,
    avgFiveStarRatingScore: 0,
    ratingsCount: 0,
    numHomesSoldInLast12Mo: 0,
    avgDaysListedLast12Mo: 0,
    avgListToClosePriceRatioLast12Mo: 0,
  };
}

export const AgentReferralDetails_AgentStats = {
  fromJSON(object: any): AgentReferralDetails_AgentStats {
    return {
      yearsOfExperience: isSet(object.yearsOfExperience) ? Number(object.yearsOfExperience) : 0,
      avgFiveStarRatingScore: isSet(object.avgFiveStarRatingScore) ? Number(object.avgFiveStarRatingScore) : 0,
      ratingsCount: isSet(object.ratingsCount) ? Number(object.ratingsCount) : 0,
      numHomesSoldInLast12Mo: isSet(object.numHomesSoldInLast12Mo) ? Number(object.numHomesSoldInLast12Mo) : 0,
      avgDaysListedLast12Mo: isSet(object.avgDaysListedLast12Mo) ? Number(object.avgDaysListedLast12Mo) : 0,
      avgListToClosePriceRatioLast12Mo: isSet(object.avgListToClosePriceRatioLast12Mo)
        ? Number(object.avgListToClosePriceRatioLast12Mo)
        : 0,
    };
  },

  toJSON(message: AgentReferralDetails_AgentStats): unknown {
    const obj: any = {};
    message.yearsOfExperience !== undefined && (obj.yearsOfExperience = Math.round(message.yearsOfExperience));
    message.avgFiveStarRatingScore !== undefined && (obj.avgFiveStarRatingScore = message.avgFiveStarRatingScore);
    message.ratingsCount !== undefined && (obj.ratingsCount = Math.round(message.ratingsCount));
    message.numHomesSoldInLast12Mo !== undefined &&
      (obj.numHomesSoldInLast12Mo = Math.round(message.numHomesSoldInLast12Mo));
    message.avgDaysListedLast12Mo !== undefined && (obj.avgDaysListedLast12Mo = message.avgDaysListedLast12Mo);
    message.avgListToClosePriceRatioLast12Mo !== undefined &&
      (obj.avgListToClosePriceRatioLast12Mo = message.avgListToClosePriceRatioLast12Mo);
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentReferralDetails_AgentStats>, I>>(base?: I): AgentReferralDetails_AgentStats {
    return AgentReferralDetails_AgentStats.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AgentReferralDetails_AgentStats>, I>>(
    object: I,
  ): AgentReferralDetails_AgentStats {
    const message = createBaseAgentReferralDetails_AgentStats();
    message.yearsOfExperience = object.yearsOfExperience ?? 0;
    message.avgFiveStarRatingScore = object.avgFiveStarRatingScore ?? 0;
    message.ratingsCount = object.ratingsCount ?? 0;
    message.numHomesSoldInLast12Mo = object.numHomesSoldInLast12Mo ?? 0;
    message.avgDaysListedLast12Mo = object.avgDaysListedLast12Mo ?? 0;
    message.avgListToClosePriceRatioLast12Mo = object.avgListToClosePriceRatioLast12Mo ?? 0;
    return message;
  },
};

function createBaseAgentReferralDetails_Address(): AgentReferralDetails_Address {
  return { addressId: "", street: "", city: "", state: "", postalCode: "", suite: "", latitude: 0, longitude: 0 };
}

export const AgentReferralDetails_Address = {
  fromJSON(object: any): AgentReferralDetails_Address {
    return {
      addressId: isSet(object.addressId) ? String(object.addressId) : "",
      street: isSet(object.street) ? String(object.street) : "",
      city: isSet(object.city) ? String(object.city) : "",
      state: isSet(object.state) ? String(object.state) : "",
      postalCode: isSet(object.postalCode) ? String(object.postalCode) : "",
      suite: isSet(object.suite) ? String(object.suite) : "",
      latitude: isSet(object.latitude) ? Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? Number(object.longitude) : 0,
    };
  },

  toJSON(message: AgentReferralDetails_Address): unknown {
    const obj: any = {};
    message.addressId !== undefined && (obj.addressId = message.addressId);
    message.street !== undefined && (obj.street = message.street);
    message.city !== undefined && (obj.city = message.city);
    message.state !== undefined && (obj.state = message.state);
    message.postalCode !== undefined && (obj.postalCode = message.postalCode);
    message.suite !== undefined && (obj.suite = message.suite);
    message.latitude !== undefined && (obj.latitude = message.latitude);
    message.longitude !== undefined && (obj.longitude = message.longitude);
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentReferralDetails_Address>, I>>(base?: I): AgentReferralDetails_Address {
    return AgentReferralDetails_Address.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AgentReferralDetails_Address>, I>>(object: I): AgentReferralDetails_Address {
    const message = createBaseAgentReferralDetails_Address();
    message.addressId = object.addressId ?? "";
    message.street = object.street ?? "";
    message.city = object.city ?? "";
    message.state = object.state ?? "";
    message.postalCode = object.postalCode ?? "";
    message.suite = object.suite ?? "";
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    return message;
  },
};

function createBaseListWithOpendoorDetails(): ListWithOpendoorDetails {
  return {};
}

export const ListWithOpendoorDetails = {
  fromJSON(_: any): ListWithOpendoorDetails {
    return {};
  },

  toJSON(_: ListWithOpendoorDetails): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListWithOpendoorDetails>, I>>(base?: I): ListWithOpendoorDetails {
    return ListWithOpendoorDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ListWithOpendoorDetails>, I>>(_: I): ListWithOpendoorDetails {
    const message = createBaseListWithOpendoorDetails();
    return message;
  },
};

function createBaseLeadOffers(): LeadOffers {
  return { source: undefined, offers: [] };
}

export const LeadOffers = {
  fromJSON(object: any): LeadOffers {
    return {
      source: isSet(object.source) ? Lead_Source.fromJSON(object.source) : undefined,
      offers: Array.isArray(object?.offers) ? object.offers.map((e: any) => Offer.fromJSON(e)) : [],
    };
  },

  toJSON(message: LeadOffers): unknown {
    const obj: any = {};
    message.source !== undefined && (obj.source = message.source ? Lead_Source.toJSON(message.source) : undefined);
    if (message.offers) {
      obj.offers = message.offers.map((e) => e ? Offer.toJSON(e) : undefined);
    } else {
      obj.offers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadOffers>, I>>(base?: I): LeadOffers {
    return LeadOffers.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LeadOffers>, I>>(object: I): LeadOffers {
    const message = createBaseLeadOffers();
    message.source = (object.source !== undefined && object.source !== null)
      ? Lead_Source.fromPartial(object.source)
      : undefined;
    message.offers = object.offers?.map((e) => Offer.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddOn(): AddOn {
  return { offerUuid: "", addOnType: 0, eligibleAt: undefined, included: undefined };
}

export const AddOn = {
  fromJSON(object: any): AddOn {
    return {
      offerUuid: isSet(object.offerUuid) ? String(object.offerUuid) : "",
      addOnType: isSet(object.addOnType) ? addOn_AddOnTypeFromJSON(object.addOnType) : 0,
      eligibleAt: isSet(object.eligibleAt) ? fromJsonTimestamp(object.eligibleAt) : undefined,
      included: isSet(object.included) ? Boolean(object.included) : undefined,
    };
  },

  toJSON(message: AddOn): unknown {
    const obj: any = {};
    message.offerUuid !== undefined && (obj.offerUuid = message.offerUuid);
    message.addOnType !== undefined && (obj.addOnType = addOn_AddOnTypeToJSON(message.addOnType));
    message.eligibleAt !== undefined && (obj.eligibleAt = message.eligibleAt.toISOString());
    message.included !== undefined && (obj.included = message.included);
    return obj;
  },

  create<I extends Exact<DeepPartial<AddOn>, I>>(base?: I): AddOn {
    return AddOn.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AddOn>, I>>(object: I): AddOn {
    const message = createBaseAddOn();
    message.offerUuid = object.offerUuid ?? "";
    message.addOnType = object.addOnType ?? 0;
    message.eligibleAt = object.eligibleAt ?? undefined;
    message.included = object.included ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
