/* eslint-disable */

export const protobufPackage = "od_protos.journey.data.categories";

export interface ExperimentationCategory {
  event?: { $case: "impression"; impression: ExperimentationCategory_ImpressionEvent };
}

export interface ExperimentationCategory_ImpressionEvent {
  experimentName: string;
  variant?: string;
}

function createBaseExperimentationCategory(): ExperimentationCategory {
  return { event: undefined };
}

export const ExperimentationCategory = {
  fromJSON(object: any): ExperimentationCategory {
    return {
      event: isSet(object.impression)
        ? { $case: "impression", impression: ExperimentationCategory_ImpressionEvent.fromJSON(object.impression) }
        : undefined,
    };
  },

  toJSON(message: ExperimentationCategory): unknown {
    const obj: any = {};
    message.event?.$case === "impression" && (obj.impression = message.event?.impression
      ? ExperimentationCategory_ImpressionEvent.toJSON(message.event?.impression)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExperimentationCategory>, I>>(base?: I): ExperimentationCategory {
    return ExperimentationCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExperimentationCategory>, I>>(object: I): ExperimentationCategory {
    const message = createBaseExperimentationCategory();
    if (
      object.event?.$case === "impression" &&
      object.event?.impression !== undefined &&
      object.event?.impression !== null
    ) {
      message.event = {
        $case: "impression",
        impression: ExperimentationCategory_ImpressionEvent.fromPartial(object.event.impression),
      };
    }
    return message;
  },
};

function createBaseExperimentationCategory_ImpressionEvent(): ExperimentationCategory_ImpressionEvent {
  return { experimentName: "", variant: undefined };
}

export const ExperimentationCategory_ImpressionEvent = {
  fromJSON(object: any): ExperimentationCategory_ImpressionEvent {
    return {
      experimentName: isSet(object.experimentName) ? String(object.experimentName) : "",
      variant: isSet(object.variant) ? String(object.variant) : undefined,
    };
  },

  toJSON(message: ExperimentationCategory_ImpressionEvent): unknown {
    const obj: any = {};
    message.experimentName !== undefined && (obj.experimentName = message.experimentName);
    message.variant !== undefined && (obj.variant = message.variant);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExperimentationCategory_ImpressionEvent>, I>>(
    base?: I,
  ): ExperimentationCategory_ImpressionEvent {
    return ExperimentationCategory_ImpressionEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExperimentationCategory_ImpressionEvent>, I>>(
    object: I,
  ): ExperimentationCategory_ImpressionEvent {
    const message = createBaseExperimentationCategory_ImpressionEvent();
    message.experimentName = object.experimentName ?? "";
    message.variant = object.variant ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
