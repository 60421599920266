/* eslint-disable */

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellExclusivesCategory {
  event?: { $case: "rangeViewEvent"; rangeViewEvent: SellExclusivesCategory_RangeViewEvent };
}

export interface SellExclusivesCategory_RangeViewEvent {
  sellerLeadId: string;
  rangeLowerBound: number;
  rangeUpperBound: number;
}

function createBaseSellExclusivesCategory(): SellExclusivesCategory {
  return { event: undefined };
}

export const SellExclusivesCategory = {
  fromJSON(object: any): SellExclusivesCategory {
    return {
      event: isSet(object.rangeViewEvent)
        ? {
          $case: "rangeViewEvent",
          rangeViewEvent: SellExclusivesCategory_RangeViewEvent.fromJSON(object.rangeViewEvent),
        }
        : undefined,
    };
  },

  toJSON(message: SellExclusivesCategory): unknown {
    const obj: any = {};
    message.event?.$case === "rangeViewEvent" && (obj.rangeViewEvent = message.event?.rangeViewEvent
      ? SellExclusivesCategory_RangeViewEvent.toJSON(message.event?.rangeViewEvent)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory>, I>>(base?: I): SellExclusivesCategory {
    return SellExclusivesCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory>, I>>(object: I): SellExclusivesCategory {
    const message = createBaseSellExclusivesCategory();
    if (
      object.event?.$case === "rangeViewEvent" &&
      object.event?.rangeViewEvent !== undefined &&
      object.event?.rangeViewEvent !== null
    ) {
      message.event = {
        $case: "rangeViewEvent",
        rangeViewEvent: SellExclusivesCategory_RangeViewEvent.fromPartial(object.event.rangeViewEvent),
      };
    }
    return message;
  },
};

function createBaseSellExclusivesCategory_RangeViewEvent(): SellExclusivesCategory_RangeViewEvent {
  return { sellerLeadId: "", rangeLowerBound: 0, rangeUpperBound: 0 };
}

export const SellExclusivesCategory_RangeViewEvent = {
  fromJSON(object: any): SellExclusivesCategory_RangeViewEvent {
    return {
      sellerLeadId: isSet(object.sellerLeadId) ? String(object.sellerLeadId) : "",
      rangeLowerBound: isSet(object.rangeLowerBound) ? Number(object.rangeLowerBound) : 0,
      rangeUpperBound: isSet(object.rangeUpperBound) ? Number(object.rangeUpperBound) : 0,
    };
  },

  toJSON(message: SellExclusivesCategory_RangeViewEvent): unknown {
    const obj: any = {};
    message.sellerLeadId !== undefined && (obj.sellerLeadId = message.sellerLeadId);
    message.rangeLowerBound !== undefined && (obj.rangeLowerBound = message.rangeLowerBound);
    message.rangeUpperBound !== undefined && (obj.rangeUpperBound = message.rangeUpperBound);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellExclusivesCategory_RangeViewEvent>, I>>(
    base?: I,
  ): SellExclusivesCategory_RangeViewEvent {
    return SellExclusivesCategory_RangeViewEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellExclusivesCategory_RangeViewEvent>, I>>(
    object: I,
  ): SellExclusivesCategory_RangeViewEvent {
    const message = createBaseSellExclusivesCategory_RangeViewEvent();
    message.sellerLeadId = object.sellerLeadId ?? "";
    message.rangeLowerBound = object.rangeLowerBound ?? 0;
    message.rangeUpperBound = object.rangeUpperBound ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
