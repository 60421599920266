/* eslint-disable */
import {
  SellerInput_Channel,
  sellerInput_ChannelFromJSON,
  sellerInput_ChannelToJSON,
} from "../../../sell/reception/data/seller_input";

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellCategory {
  event?: { $case: "addressEntry"; addressEntry: SellCategory_AddressEntryEvent } | {
    $case: "ssvaTaskComplete";
    ssvaTaskComplete: SellCategory_SSVATaskCompleteEvent;
  };
}

export enum SellCategory_SSVATaskType {
  UNKNOWN_SSVA_TASK_TYPE = 0,
  UPLOAD_INTERIOR_RECORDING_TASK = 1,
  UPLOAD_FRONTYARD_RECORDING_TASK = 2,
  UPLOAD_BACKYARD_RECORDING_TASK = 3,
  UPLOAD_WHOLE_HOME_RECORDING_TASK = 4,
  QUESTIONNAIRE_TASK = 5,
  SELLER_DISCLOSURE_DOCUMENT_TASK = 6,
  UNRECOGNIZED = -1,
}

export function sellCategory_SSVATaskTypeFromJSON(object: any): SellCategory_SSVATaskType {
  switch (object) {
    case 0:
    case "UNKNOWN_SSVA_TASK_TYPE":
      return SellCategory_SSVATaskType.UNKNOWN_SSVA_TASK_TYPE;
    case 1:
    case "UPLOAD_INTERIOR_RECORDING_TASK":
      return SellCategory_SSVATaskType.UPLOAD_INTERIOR_RECORDING_TASK;
    case 2:
    case "UPLOAD_FRONTYARD_RECORDING_TASK":
      return SellCategory_SSVATaskType.UPLOAD_FRONTYARD_RECORDING_TASK;
    case 3:
    case "UPLOAD_BACKYARD_RECORDING_TASK":
      return SellCategory_SSVATaskType.UPLOAD_BACKYARD_RECORDING_TASK;
    case 4:
    case "UPLOAD_WHOLE_HOME_RECORDING_TASK":
      return SellCategory_SSVATaskType.UPLOAD_WHOLE_HOME_RECORDING_TASK;
    case 5:
    case "QUESTIONNAIRE_TASK":
      return SellCategory_SSVATaskType.QUESTIONNAIRE_TASK;
    case 6:
    case "SELLER_DISCLOSURE_DOCUMENT_TASK":
      return SellCategory_SSVATaskType.SELLER_DISCLOSURE_DOCUMENT_TASK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellCategory_SSVATaskType.UNRECOGNIZED;
  }
}

export function sellCategory_SSVATaskTypeToJSON(object: SellCategory_SSVATaskType): string {
  switch (object) {
    case SellCategory_SSVATaskType.UNKNOWN_SSVA_TASK_TYPE:
      return "UNKNOWN_SSVA_TASK_TYPE";
    case SellCategory_SSVATaskType.UPLOAD_INTERIOR_RECORDING_TASK:
      return "UPLOAD_INTERIOR_RECORDING_TASK";
    case SellCategory_SSVATaskType.UPLOAD_FRONTYARD_RECORDING_TASK:
      return "UPLOAD_FRONTYARD_RECORDING_TASK";
    case SellCategory_SSVATaskType.UPLOAD_BACKYARD_RECORDING_TASK:
      return "UPLOAD_BACKYARD_RECORDING_TASK";
    case SellCategory_SSVATaskType.UPLOAD_WHOLE_HOME_RECORDING_TASK:
      return "UPLOAD_WHOLE_HOME_RECORDING_TASK";
    case SellCategory_SSVATaskType.QUESTIONNAIRE_TASK:
      return "QUESTIONNAIRE_TASK";
    case SellCategory_SSVATaskType.SELLER_DISCLOSURE_DOCUMENT_TASK:
      return "SELLER_DISCLOSURE_DOCUMENT_TASK";
    case SellCategory_SSVATaskType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * sell_address_entry_event
 * Triggered on successful and failed address entries
 */
export interface SellCategory_AddressEntryEvent {
  /**
   * Fields match IAddressInputAddress
   * https://github.com/opendoor-labs/code/blob/c393c8d0061e9a8ce32c63621d3ec1ded9fa55ca/js/packages/cinderblocks/src/growth/AddressInput/AddressInput.tsx#L19
   */
  street1: string;
  city: string;
  state: string;
  postalCode: string;
  unit?: string;
  latitude?: number;
  longitude?: number;
  receptionChannel: SellerInput_Channel;
}

export interface SellCategory_SSVATaskCompleteEvent {
  leadId: string;
  offerId: string;
  ssvaTaskName: SellCategory_SSVATaskType;
}

function createBaseSellCategory(): SellCategory {
  return { event: undefined };
}

export const SellCategory = {
  fromJSON(object: any): SellCategory {
    return {
      event: isSet(object.addressEntry)
        ? { $case: "addressEntry", addressEntry: SellCategory_AddressEntryEvent.fromJSON(object.addressEntry) }
        : isSet(object.ssvaTaskComplete)
        ? {
          $case: "ssvaTaskComplete",
          ssvaTaskComplete: SellCategory_SSVATaskCompleteEvent.fromJSON(object.ssvaTaskComplete),
        }
        : undefined,
    };
  },

  toJSON(message: SellCategory): unknown {
    const obj: any = {};
    message.event?.$case === "addressEntry" && (obj.addressEntry = message.event?.addressEntry
      ? SellCategory_AddressEntryEvent.toJSON(message.event?.addressEntry)
      : undefined);
    message.event?.$case === "ssvaTaskComplete" && (obj.ssvaTaskComplete = message.event?.ssvaTaskComplete
      ? SellCategory_SSVATaskCompleteEvent.toJSON(message.event?.ssvaTaskComplete)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellCategory>, I>>(base?: I): SellCategory {
    return SellCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellCategory>, I>>(object: I): SellCategory {
    const message = createBaseSellCategory();
    if (
      object.event?.$case === "addressEntry" &&
      object.event?.addressEntry !== undefined &&
      object.event?.addressEntry !== null
    ) {
      message.event = {
        $case: "addressEntry",
        addressEntry: SellCategory_AddressEntryEvent.fromPartial(object.event.addressEntry),
      };
    }
    if (
      object.event?.$case === "ssvaTaskComplete" &&
      object.event?.ssvaTaskComplete !== undefined &&
      object.event?.ssvaTaskComplete !== null
    ) {
      message.event = {
        $case: "ssvaTaskComplete",
        ssvaTaskComplete: SellCategory_SSVATaskCompleteEvent.fromPartial(object.event.ssvaTaskComplete),
      };
    }
    return message;
  },
};

function createBaseSellCategory_AddressEntryEvent(): SellCategory_AddressEntryEvent {
  return {
    street1: "",
    city: "",
    state: "",
    postalCode: "",
    unit: undefined,
    latitude: undefined,
    longitude: undefined,
    receptionChannel: 0,
  };
}

export const SellCategory_AddressEntryEvent = {
  fromJSON(object: any): SellCategory_AddressEntryEvent {
    return {
      street1: isSet(object.street1) ? String(object.street1) : "",
      city: isSet(object.city) ? String(object.city) : "",
      state: isSet(object.state) ? String(object.state) : "",
      postalCode: isSet(object.postalCode) ? String(object.postalCode) : "",
      unit: isSet(object.unit) ? String(object.unit) : undefined,
      latitude: isSet(object.latitude) ? Number(object.latitude) : undefined,
      longitude: isSet(object.longitude) ? Number(object.longitude) : undefined,
      receptionChannel: isSet(object.receptionChannel) ? sellerInput_ChannelFromJSON(object.receptionChannel) : 0,
    };
  },

  toJSON(message: SellCategory_AddressEntryEvent): unknown {
    const obj: any = {};
    message.street1 !== undefined && (obj.street1 = message.street1);
    message.city !== undefined && (obj.city = message.city);
    message.state !== undefined && (obj.state = message.state);
    message.postalCode !== undefined && (obj.postalCode = message.postalCode);
    message.unit !== undefined && (obj.unit = message.unit);
    message.latitude !== undefined && (obj.latitude = message.latitude);
    message.longitude !== undefined && (obj.longitude = message.longitude);
    message.receptionChannel !== undefined &&
      (obj.receptionChannel = sellerInput_ChannelToJSON(message.receptionChannel));
    return obj;
  },

  create<I extends Exact<DeepPartial<SellCategory_AddressEntryEvent>, I>>(base?: I): SellCategory_AddressEntryEvent {
    return SellCategory_AddressEntryEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellCategory_AddressEntryEvent>, I>>(
    object: I,
  ): SellCategory_AddressEntryEvent {
    const message = createBaseSellCategory_AddressEntryEvent();
    message.street1 = object.street1 ?? "";
    message.city = object.city ?? "";
    message.state = object.state ?? "";
    message.postalCode = object.postalCode ?? "";
    message.unit = object.unit ?? undefined;
    message.latitude = object.latitude ?? undefined;
    message.longitude = object.longitude ?? undefined;
    message.receptionChannel = object.receptionChannel ?? 0;
    return message;
  },
};

function createBaseSellCategory_SSVATaskCompleteEvent(): SellCategory_SSVATaskCompleteEvent {
  return { leadId: "", offerId: "", ssvaTaskName: 0 };
}

export const SellCategory_SSVATaskCompleteEvent = {
  fromJSON(object: any): SellCategory_SSVATaskCompleteEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      ssvaTaskName: isSet(object.ssvaTaskName) ? sellCategory_SSVATaskTypeFromJSON(object.ssvaTaskName) : 0,
    };
  },

  toJSON(message: SellCategory_SSVATaskCompleteEvent): unknown {
    const obj: any = {};
    message.leadId !== undefined && (obj.leadId = message.leadId);
    message.offerId !== undefined && (obj.offerId = message.offerId);
    message.ssvaTaskName !== undefined && (obj.ssvaTaskName = sellCategory_SSVATaskTypeToJSON(message.ssvaTaskName));
    return obj;
  },

  create<I extends Exact<DeepPartial<SellCategory_SSVATaskCompleteEvent>, I>>(
    base?: I,
  ): SellCategory_SSVATaskCompleteEvent {
    return SellCategory_SSVATaskCompleteEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellCategory_SSVATaskCompleteEvent>, I>>(
    object: I,
  ): SellCategory_SSVATaskCompleteEvent {
    const message = createBaseSellCategory_SSVATaskCompleteEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.ssvaTaskName = object.ssvaTaskName ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
