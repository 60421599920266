/* eslint-disable */

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellVACategory {
  event?: { $case: "flowStart"; flowStart: SellVACategory_FlowStartEvent } | {
    $case: "start";
    start: SellVACategory_StartEvent;
  } | { $case: "complete"; complete: SellVACategory_CompleteEvent };
}

export enum SellVACategory_VAType {
  UNKNOWN_VA_TYPE = 0,
  ORVA = 1,
  SSVA = 2,
  UNRECOGNIZED = -1,
}

export function sellVACategory_VATypeFromJSON(object: any): SellVACategory_VAType {
  switch (object) {
    case 0:
    case "UNKNOWN_VA_TYPE":
      return SellVACategory_VAType.UNKNOWN_VA_TYPE;
    case 1:
    case "ORVA":
      return SellVACategory_VAType.ORVA;
    case 2:
    case "SSVA":
      return SellVACategory_VAType.SSVA;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellVACategory_VAType.UNRECOGNIZED;
  }
}

export function sellVACategory_VATypeToJSON(object: SellVACategory_VAType): string {
  switch (object) {
    case SellVACategory_VAType.UNKNOWN_VA_TYPE:
      return "UNKNOWN_VA_TYPE";
    case SellVACategory_VAType.ORVA:
      return "ORVA";
    case SellVACategory_VAType.SSVA:
      return "SSVA";
    case SellVACategory_VAType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SellVACategory_FlowStartEvent {
  leadId: string;
  offerId: string;
}

export interface SellVACategory_StartEvent {
  leadId: string;
  offerId: string;
  vaType: SellVACategory_VAType;
}

export interface SellVACategory_CompleteEvent {
  leadId: string;
  offerId: string;
  vaType: SellVACategory_VAType;
}

function createBaseSellVACategory(): SellVACategory {
  return { event: undefined };
}

export const SellVACategory = {
  fromJSON(object: any): SellVACategory {
    return {
      event: isSet(object.flowStart)
        ? { $case: "flowStart", flowStart: SellVACategory_FlowStartEvent.fromJSON(object.flowStart) }
        : isSet(object.start)
        ? { $case: "start", start: SellVACategory_StartEvent.fromJSON(object.start) }
        : isSet(object.complete)
        ? { $case: "complete", complete: SellVACategory_CompleteEvent.fromJSON(object.complete) }
        : undefined,
    };
  },

  toJSON(message: SellVACategory): unknown {
    const obj: any = {};
    message.event?.$case === "flowStart" && (obj.flowStart = message.event?.flowStart
      ? SellVACategory_FlowStartEvent.toJSON(message.event?.flowStart)
      : undefined);
    message.event?.$case === "start" &&
      (obj.start = message.event?.start ? SellVACategory_StartEvent.toJSON(message.event?.start) : undefined);
    message.event?.$case === "complete" &&
      (obj.complete = message.event?.complete
        ? SellVACategory_CompleteEvent.toJSON(message.event?.complete)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellVACategory>, I>>(base?: I): SellVACategory {
    return SellVACategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellVACategory>, I>>(object: I): SellVACategory {
    const message = createBaseSellVACategory();
    if (
      object.event?.$case === "flowStart" && object.event?.flowStart !== undefined && object.event?.flowStart !== null
    ) {
      message.event = {
        $case: "flowStart",
        flowStart: SellVACategory_FlowStartEvent.fromPartial(object.event.flowStart),
      };
    }
    if (object.event?.$case === "start" && object.event?.start !== undefined && object.event?.start !== null) {
      message.event = { $case: "start", start: SellVACategory_StartEvent.fromPartial(object.event.start) };
    }
    if (object.event?.$case === "complete" && object.event?.complete !== undefined && object.event?.complete !== null) {
      message.event = { $case: "complete", complete: SellVACategory_CompleteEvent.fromPartial(object.event.complete) };
    }
    return message;
  },
};

function createBaseSellVACategory_FlowStartEvent(): SellVACategory_FlowStartEvent {
  return { leadId: "", offerId: "" };
}

export const SellVACategory_FlowStartEvent = {
  fromJSON(object: any): SellVACategory_FlowStartEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
    };
  },

  toJSON(message: SellVACategory_FlowStartEvent): unknown {
    const obj: any = {};
    message.leadId !== undefined && (obj.leadId = message.leadId);
    message.offerId !== undefined && (obj.offerId = message.offerId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellVACategory_FlowStartEvent>, I>>(base?: I): SellVACategory_FlowStartEvent {
    return SellVACategory_FlowStartEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellVACategory_FlowStartEvent>, I>>(
    object: I,
  ): SellVACategory_FlowStartEvent {
    const message = createBaseSellVACategory_FlowStartEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    return message;
  },
};

function createBaseSellVACategory_StartEvent(): SellVACategory_StartEvent {
  return { leadId: "", offerId: "", vaType: 0 };
}

export const SellVACategory_StartEvent = {
  fromJSON(object: any): SellVACategory_StartEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      vaType: isSet(object.vaType) ? sellVACategory_VATypeFromJSON(object.vaType) : 0,
    };
  },

  toJSON(message: SellVACategory_StartEvent): unknown {
    const obj: any = {};
    message.leadId !== undefined && (obj.leadId = message.leadId);
    message.offerId !== undefined && (obj.offerId = message.offerId);
    message.vaType !== undefined && (obj.vaType = sellVACategory_VATypeToJSON(message.vaType));
    return obj;
  },

  create<I extends Exact<DeepPartial<SellVACategory_StartEvent>, I>>(base?: I): SellVACategory_StartEvent {
    return SellVACategory_StartEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellVACategory_StartEvent>, I>>(object: I): SellVACategory_StartEvent {
    const message = createBaseSellVACategory_StartEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.vaType = object.vaType ?? 0;
    return message;
  },
};

function createBaseSellVACategory_CompleteEvent(): SellVACategory_CompleteEvent {
  return { leadId: "", offerId: "", vaType: 0 };
}

export const SellVACategory_CompleteEvent = {
  fromJSON(object: any): SellVACategory_CompleteEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      vaType: isSet(object.vaType) ? sellVACategory_VATypeFromJSON(object.vaType) : 0,
    };
  },

  toJSON(message: SellVACategory_CompleteEvent): unknown {
    const obj: any = {};
    message.leadId !== undefined && (obj.leadId = message.leadId);
    message.offerId !== undefined && (obj.offerId = message.offerId);
    message.vaType !== undefined && (obj.vaType = sellVACategory_VATypeToJSON(message.vaType));
    return obj;
  },

  create<I extends Exact<DeepPartial<SellVACategory_CompleteEvent>, I>>(base?: I): SellVACategory_CompleteEvent {
    return SellVACategory_CompleteEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellVACategory_CompleteEvent>, I>>(object: I): SellVACategory_CompleteEvent {
    const message = createBaseSellVACategory_CompleteEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.vaType = object.vaType ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
