import {
  globalObservability,
  // eslint-disable-next-line no-restricted-syntax
  useObservability as useObservabilityLib,
  initObservability as init,
} from '@opendoor/observability/slim';

import {
  GOOGLE_ANALYTICS_KEY,
  DATADOG_RUM_APPLICATION_ID,
  DATADOG_RUM_APPLICATION_CLIENT_TOKEN,
  DEPLOY_ENV,
} from '../components/globals';
import type { EventWrapper as CosmosJourneyEventWrapper } from '../__generated__/protobuf/journey/data/event_wrapper';
import { EventWrapper as journeyEventHelper } from '../__generated__/protobuf/journey/data/event_wrapper';

import * as Sentry from '@sentry/nextjs';
import type { ErrorEvent } from '@sentry/types';
import { datadogRum } from '@datadog/browser-rum';

type CosmosTrackingCategory = 'cosmos';

type CosmosTrackingActions =
  | 'inputting_address'
  | 'inputting_address_success'
  | 'exclusives-checkout'
  | 'exclusives-touring'
  | 'exclusives-request-a-tour'
  | 'exclusives-gallery'
  | 'exclusives-pdp'
  | 'exclusives-preferences'
  | 'exclusives-favorite'
  | 'exclusives-sharing'
  | 'exclusives-subscribe'
  | 'exclusives-home-page'
  | 'exclusives-login'
  | 'exclusives-available-soon'
  | 'exclusives-open-house'
  | 'exclusives-self-unlock'
  | 'exclusives-buyer-driver-demand'
  | 'exclusives-in-home-touring'
  | 'exclusives-in-home-touring-property'
  | 'exclusives-seller-owned-tours'
  | 'exclusives-market-selector-modal'
  | 'property-view'
  | 'property-favorite'
  | 'property-share'
  | 'property-similar-listings'
  | 'property-purchase-calculator'
  | 'property-images';

//observability
export const initObservability = () => {
  // make sure we only init observability client-side
  if (typeof window !== 'undefined' && !globalObservability.isInitialized()) {
    init<CosmosTrackingCategory, CosmosTrackingActions, CosmosJourneyEventWrapper>({
      eventTrackingCategory: 'cosmos',
      googleAnalyticsToken: GOOGLE_ANALYTICS_KEY,
      env: DEPLOY_ENV,
      appName: 'cosmos',
      version: process.env.NEXT_PUBLIC_VERSION || 'unknown',
      sentry: {
        dsn: 'https://3414e8de867a4131a44598d29006eccc@o117144.ingest.sentry.io/1439728',
        maxBreadcrumbs: 50,
        attachStacktrace: true,
        // Only allow errors from first-party sources, to reduce sentry spam
        allowUrls: ['simplersell.com', 'opendoor.com'],
        // observability package has default ignore errors set by default
        // https://github.com/opendoor-labs/code/blob/master/js/packages/observability/src/internal/sentry.ts#L138
        init: async (initOpts) => {
          const customBeforeSend = (event: ErrorEvent, hint: Sentry.EventHint) => {
            // ref: https://github.com/getsentry/sentry-javascript/issues/7941#issuecomment-1525640063

            // {
            //   "type": "Event",
            //   "value": "Non-Error exception captured with keys: currentTarget, isTrusted, target, type",
            //   "stacktrace": {
            //     "frames": [
            //       {
            //         "module": "exclusives/homes/21027-Manon-Ln-Spring-TX-77388",
            //         "filename": "app:///exclusives/homes/21027-Manon-Ln-Spring-TX-77388",
            //         "abs_path": "app:///exclusives/homes/21027-Manon-Ln-Spring-TX-77388",
            //         "in_app": true
            //       }
            //     ]
            //   },
            //   "mechanism": {
            //     "type": "onerror",
            //     "synthetic": true,
            //     "handled": false
            //   }
            // }
            const eventMessage = event?.exception?.values?.[0]?.value || '';
            if (
              eventMessage.includes?.(
                'Non-Error exception captured with keys: currentTarget, isTrusted, target, type',
              )
            ) {
              return null;
            }
            return initOpts.beforeSend?.(event, hint) ?? event;
          };
          Sentry.init({
            ...initOpts,
            beforeSend: customBeforeSend,
            enableTracing: false,
          });
          window.addEventListener('unhandledrejection', (event) => {
            Sentry.getCurrentHub().captureException(event.reason);
          });
          return Sentry.getCurrentHub();
        },
      },
      datadog: {
        service: 'cosmos',
        applicationId: DATADOG_RUM_APPLICATION_ID,
        clientToken: DATADOG_RUM_APPLICATION_CLIENT_TOKEN,
        sampleRate: 100,
        replaySampleRate: 100,
        trackInteractions: true,
        silentMultipleInit: true,
        trackSessionAcrossSubdomains: true,
        // Please be careful when adding tracing origins.
        // If a server is not prepared for the new Datadog headers, then it may fail with CORS errors.
        // Relevant postmortem: https://docs.google.com/document/d/1O9eWUdXZJOS4Oha2v_dtPfjO34IU3KEDpNqjk3AtXdk/edit
        allowedTracingOrigins: [/https:\/\/.*\.simplersell\.com/, /https:\/\/.*\.opendoor\.com/],
        init: async (initOpts) => {
          datadogRum.init(initOpts);
          datadogRum.startSessionReplayRecording();
          return datadogRum;
        },
      },
      journeyEventHelpers: {
        toJSON: journeyEventHelper.toJSON,
      },
    });
  }
};

export const useObservability = () =>
  useObservabilityLib<CosmosTrackingCategory, CosmosTrackingActions, CosmosJourneyEventWrapper>();
