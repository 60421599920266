/* eslint-disable */
import { Money } from "../../../common/money";

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellListCategory {
  event?: { $case: "offerView"; offerView: SellListCategory_OfferViewEvent };
}

/**
 * sell_list_offer_view_event
 * Triggered when an LwC-enrolled lead views the offer dashboard
 */
export interface SellListCategory_OfferViewEvent {
  sellDirectLeadToken: string;
  sellDirectOfferId: string;
  listingRangeMin?: Money;
  listingRangeMax?: Money;
}

function createBaseSellListCategory(): SellListCategory {
  return { event: undefined };
}

export const SellListCategory = {
  fromJSON(object: any): SellListCategory {
    return {
      event: isSet(object.offerView)
        ? { $case: "offerView", offerView: SellListCategory_OfferViewEvent.fromJSON(object.offerView) }
        : undefined,
    };
  },

  toJSON(message: SellListCategory): unknown {
    const obj: any = {};
    message.event?.$case === "offerView" && (obj.offerView = message.event?.offerView
      ? SellListCategory_OfferViewEvent.toJSON(message.event?.offerView)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellListCategory>, I>>(base?: I): SellListCategory {
    return SellListCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellListCategory>, I>>(object: I): SellListCategory {
    const message = createBaseSellListCategory();
    if (
      object.event?.$case === "offerView" && object.event?.offerView !== undefined && object.event?.offerView !== null
    ) {
      message.event = {
        $case: "offerView",
        offerView: SellListCategory_OfferViewEvent.fromPartial(object.event.offerView),
      };
    }
    return message;
  },
};

function createBaseSellListCategory_OfferViewEvent(): SellListCategory_OfferViewEvent {
  return { sellDirectLeadToken: "", sellDirectOfferId: "", listingRangeMin: undefined, listingRangeMax: undefined };
}

export const SellListCategory_OfferViewEvent = {
  fromJSON(object: any): SellListCategory_OfferViewEvent {
    return {
      sellDirectLeadToken: isSet(object.sellDirectLeadToken) ? String(object.sellDirectLeadToken) : "",
      sellDirectOfferId: isSet(object.sellDirectOfferId) ? String(object.sellDirectOfferId) : "",
      listingRangeMin: isSet(object.listingRangeMin) ? Money.fromJSON(object.listingRangeMin) : undefined,
      listingRangeMax: isSet(object.listingRangeMax) ? Money.fromJSON(object.listingRangeMax) : undefined,
    };
  },

  toJSON(message: SellListCategory_OfferViewEvent): unknown {
    const obj: any = {};
    message.sellDirectLeadToken !== undefined && (obj.sellDirectLeadToken = message.sellDirectLeadToken);
    message.sellDirectOfferId !== undefined && (obj.sellDirectOfferId = message.sellDirectOfferId);
    message.listingRangeMin !== undefined &&
      (obj.listingRangeMin = message.listingRangeMin ? Money.toJSON(message.listingRangeMin) : undefined);
    message.listingRangeMax !== undefined &&
      (obj.listingRangeMax = message.listingRangeMax ? Money.toJSON(message.listingRangeMax) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellListCategory_OfferViewEvent>, I>>(base?: I): SellListCategory_OfferViewEvent {
    return SellListCategory_OfferViewEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellListCategory_OfferViewEvent>, I>>(
    object: I,
  ): SellListCategory_OfferViewEvent {
    const message = createBaseSellListCategory_OfferViewEvent();
    message.sellDirectLeadToken = object.sellDirectLeadToken ?? "";
    message.sellDirectOfferId = object.sellDirectOfferId ?? "";
    message.listingRangeMin = (object.listingRangeMin !== undefined && object.listingRangeMin !== null)
      ? Money.fromPartial(object.listingRangeMin)
      : undefined;
    message.listingRangeMax = (object.listingRangeMax !== undefined && object.listingRangeMax !== null)
      ? Money.fromPartial(object.listingRangeMax)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
