/* eslint-disable */

export const protobufPackage = "od_protos.sell.seller.data";

/**
 * Data specific to the List with Opendoor offer. This includes both data
 * provided by Laser (the LwOD service).
 * Next ID: 6
 */
export interface ListWithOpendoorSpecificData {
  minListPriceCents: number;
  maxListPriceCents: number;
  /** TODO: move is_eligible_for_home_reserve to HomeReserveMetadata */
  isEligibleForHomeReserve: boolean;
  homeReserveMetadata?: HomeReserveMetadata;
  isEligibleForUnifiedDenialOpsFlow: boolean;
}

export interface HomeReserveMetadata {
  homeReserveAttributionSource: HomeReserveMetadata_HomeReserveAttributionSource;
}

export enum HomeReserveMetadata_HomeReserveAttributionSource {
  UNKNOWN = 0,
  /** SELLER_FLOW - The seller became eligible for Home Reserve by answering questions in the seller flow */
  SELLER_FLOW = 1,
  /** OFFER_REVIEW_DISPOSITION - The seller became eligible for Home Reserve by an operator submitting offer review notes */
  OFFER_REVIEW_DISPOSITION = 2,
  UNRECOGNIZED = -1,
}

export function homeReserveMetadata_HomeReserveAttributionSourceFromJSON(
  object: any,
): HomeReserveMetadata_HomeReserveAttributionSource {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return HomeReserveMetadata_HomeReserveAttributionSource.UNKNOWN;
    case 1:
    case "SELLER_FLOW":
      return HomeReserveMetadata_HomeReserveAttributionSource.SELLER_FLOW;
    case 2:
    case "OFFER_REVIEW_DISPOSITION":
      return HomeReserveMetadata_HomeReserveAttributionSource.OFFER_REVIEW_DISPOSITION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HomeReserveMetadata_HomeReserveAttributionSource.UNRECOGNIZED;
  }
}

export function homeReserveMetadata_HomeReserveAttributionSourceToJSON(
  object: HomeReserveMetadata_HomeReserveAttributionSource,
): string {
  switch (object) {
    case HomeReserveMetadata_HomeReserveAttributionSource.UNKNOWN:
      return "UNKNOWN";
    case HomeReserveMetadata_HomeReserveAttributionSource.SELLER_FLOW:
      return "SELLER_FLOW";
    case HomeReserveMetadata_HomeReserveAttributionSource.OFFER_REVIEW_DISPOSITION:
      return "OFFER_REVIEW_DISPOSITION";
    case HomeReserveMetadata_HomeReserveAttributionSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseListWithOpendoorSpecificData(): ListWithOpendoorSpecificData {
  return {
    minListPriceCents: 0,
    maxListPriceCents: 0,
    isEligibleForHomeReserve: false,
    homeReserveMetadata: undefined,
    isEligibleForUnifiedDenialOpsFlow: false,
  };
}

export const ListWithOpendoorSpecificData = {
  fromJSON(object: any): ListWithOpendoorSpecificData {
    return {
      minListPriceCents: isSet(object.minListPriceCents) ? Number(object.minListPriceCents) : 0,
      maxListPriceCents: isSet(object.maxListPriceCents) ? Number(object.maxListPriceCents) : 0,
      isEligibleForHomeReserve: isSet(object.isEligibleForHomeReserve)
        ? Boolean(object.isEligibleForHomeReserve)
        : false,
      homeReserveMetadata: isSet(object.homeReserveMetadata)
        ? HomeReserveMetadata.fromJSON(object.homeReserveMetadata)
        : undefined,
      isEligibleForUnifiedDenialOpsFlow: isSet(object.isEligibleForUnifiedDenialOpsFlow)
        ? Boolean(object.isEligibleForUnifiedDenialOpsFlow)
        : false,
    };
  },

  toJSON(message: ListWithOpendoorSpecificData): unknown {
    const obj: any = {};
    message.minListPriceCents !== undefined && (obj.minListPriceCents = Math.round(message.minListPriceCents));
    message.maxListPriceCents !== undefined && (obj.maxListPriceCents = Math.round(message.maxListPriceCents));
    message.isEligibleForHomeReserve !== undefined && (obj.isEligibleForHomeReserve = message.isEligibleForHomeReserve);
    message.homeReserveMetadata !== undefined && (obj.homeReserveMetadata = message.homeReserveMetadata
      ? HomeReserveMetadata.toJSON(message.homeReserveMetadata)
      : undefined);
    message.isEligibleForUnifiedDenialOpsFlow !== undefined &&
      (obj.isEligibleForUnifiedDenialOpsFlow = message.isEligibleForUnifiedDenialOpsFlow);
    return obj;
  },

  create<I extends Exact<DeepPartial<ListWithOpendoorSpecificData>, I>>(base?: I): ListWithOpendoorSpecificData {
    return ListWithOpendoorSpecificData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ListWithOpendoorSpecificData>, I>>(object: I): ListWithOpendoorSpecificData {
    const message = createBaseListWithOpendoorSpecificData();
    message.minListPriceCents = object.minListPriceCents ?? 0;
    message.maxListPriceCents = object.maxListPriceCents ?? 0;
    message.isEligibleForHomeReserve = object.isEligibleForHomeReserve ?? false;
    message.homeReserveMetadata = (object.homeReserveMetadata !== undefined && object.homeReserveMetadata !== null)
      ? HomeReserveMetadata.fromPartial(object.homeReserveMetadata)
      : undefined;
    message.isEligibleForUnifiedDenialOpsFlow = object.isEligibleForUnifiedDenialOpsFlow ?? false;
    return message;
  },
};

function createBaseHomeReserveMetadata(): HomeReserveMetadata {
  return { homeReserveAttributionSource: 0 };
}

export const HomeReserveMetadata = {
  fromJSON(object: any): HomeReserveMetadata {
    return {
      homeReserveAttributionSource: isSet(object.homeReserveAttributionSource)
        ? homeReserveMetadata_HomeReserveAttributionSourceFromJSON(object.homeReserveAttributionSource)
        : 0,
    };
  },

  toJSON(message: HomeReserveMetadata): unknown {
    const obj: any = {};
    message.homeReserveAttributionSource !== undefined &&
      (obj.homeReserveAttributionSource = homeReserveMetadata_HomeReserveAttributionSourceToJSON(
        message.homeReserveAttributionSource,
      ));
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeReserveMetadata>, I>>(base?: I): HomeReserveMetadata {
    return HomeReserveMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HomeReserveMetadata>, I>>(object: I): HomeReserveMetadata {
    const message = createBaseHomeReserveMetadata();
    message.homeReserveAttributionSource = object.homeReserveAttributionSource ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
