/* eslint-disable */
import { Timestamp } from "../../../../google/protobuf/timestamp";

export const protobufPackage = "od_protos.journey.data.categories";

export interface SellORVACategory {
  event?: { $case: "schedule"; schedule: SellORVACategory_ScheduleEvent } | {
    $case: "reschedule";
    reschedule: SellORVACategory_RescheduleEvent;
  } | { $case: "cancel"; cancel: SellORVACategory_CancelEvent };
}

export interface SellORVACategory_ScheduleEvent {
  leadId: string;
  offerId: string;
  scheduledDatetime?: Date;
  agentScheduled: string;
}

export interface SellORVACategory_RescheduleEvent {
  leadId: string;
  offerId: string;
}

export interface SellORVACategory_CancelEvent {
  leadId: string;
  offerId: string;
}

function createBaseSellORVACategory(): SellORVACategory {
  return { event: undefined };
}

export const SellORVACategory = {
  fromJSON(object: any): SellORVACategory {
    return {
      event: isSet(object.schedule)
        ? { $case: "schedule", schedule: SellORVACategory_ScheduleEvent.fromJSON(object.schedule) }
        : isSet(object.reschedule)
        ? { $case: "reschedule", reschedule: SellORVACategory_RescheduleEvent.fromJSON(object.reschedule) }
        : isSet(object.cancel)
        ? { $case: "cancel", cancel: SellORVACategory_CancelEvent.fromJSON(object.cancel) }
        : undefined,
    };
  },

  toJSON(message: SellORVACategory): unknown {
    const obj: any = {};
    message.event?.$case === "schedule" && (obj.schedule = message.event?.schedule
      ? SellORVACategory_ScheduleEvent.toJSON(message.event?.schedule)
      : undefined);
    message.event?.$case === "reschedule" && (obj.reschedule = message.event?.reschedule
      ? SellORVACategory_RescheduleEvent.toJSON(message.event?.reschedule)
      : undefined);
    message.event?.$case === "cancel" &&
      (obj.cancel = message.event?.cancel ? SellORVACategory_CancelEvent.toJSON(message.event?.cancel) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellORVACategory>, I>>(base?: I): SellORVACategory {
    return SellORVACategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellORVACategory>, I>>(object: I): SellORVACategory {
    const message = createBaseSellORVACategory();
    if (object.event?.$case === "schedule" && object.event?.schedule !== undefined && object.event?.schedule !== null) {
      message.event = {
        $case: "schedule",
        schedule: SellORVACategory_ScheduleEvent.fromPartial(object.event.schedule),
      };
    }
    if (
      object.event?.$case === "reschedule" &&
      object.event?.reschedule !== undefined &&
      object.event?.reschedule !== null
    ) {
      message.event = {
        $case: "reschedule",
        reschedule: SellORVACategory_RescheduleEvent.fromPartial(object.event.reschedule),
      };
    }
    if (object.event?.$case === "cancel" && object.event?.cancel !== undefined && object.event?.cancel !== null) {
      message.event = { $case: "cancel", cancel: SellORVACategory_CancelEvent.fromPartial(object.event.cancel) };
    }
    return message;
  },
};

function createBaseSellORVACategory_ScheduleEvent(): SellORVACategory_ScheduleEvent {
  return { leadId: "", offerId: "", scheduledDatetime: undefined, agentScheduled: "" };
}

export const SellORVACategory_ScheduleEvent = {
  fromJSON(object: any): SellORVACategory_ScheduleEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
      scheduledDatetime: isSet(object.scheduledDatetime) ? fromJsonTimestamp(object.scheduledDatetime) : undefined,
      agentScheduled: isSet(object.agentScheduled) ? String(object.agentScheduled) : "",
    };
  },

  toJSON(message: SellORVACategory_ScheduleEvent): unknown {
    const obj: any = {};
    message.leadId !== undefined && (obj.leadId = message.leadId);
    message.offerId !== undefined && (obj.offerId = message.offerId);
    message.scheduledDatetime !== undefined && (obj.scheduledDatetime = message.scheduledDatetime.toISOString());
    message.agentScheduled !== undefined && (obj.agentScheduled = message.agentScheduled);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellORVACategory_ScheduleEvent>, I>>(base?: I): SellORVACategory_ScheduleEvent {
    return SellORVACategory_ScheduleEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellORVACategory_ScheduleEvent>, I>>(
    object: I,
  ): SellORVACategory_ScheduleEvent {
    const message = createBaseSellORVACategory_ScheduleEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    message.scheduledDatetime = object.scheduledDatetime ?? undefined;
    message.agentScheduled = object.agentScheduled ?? "";
    return message;
  },
};

function createBaseSellORVACategory_RescheduleEvent(): SellORVACategory_RescheduleEvent {
  return { leadId: "", offerId: "" };
}

export const SellORVACategory_RescheduleEvent = {
  fromJSON(object: any): SellORVACategory_RescheduleEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
    };
  },

  toJSON(message: SellORVACategory_RescheduleEvent): unknown {
    const obj: any = {};
    message.leadId !== undefined && (obj.leadId = message.leadId);
    message.offerId !== undefined && (obj.offerId = message.offerId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellORVACategory_RescheduleEvent>, I>>(
    base?: I,
  ): SellORVACategory_RescheduleEvent {
    return SellORVACategory_RescheduleEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellORVACategory_RescheduleEvent>, I>>(
    object: I,
  ): SellORVACategory_RescheduleEvent {
    const message = createBaseSellORVACategory_RescheduleEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    return message;
  },
};

function createBaseSellORVACategory_CancelEvent(): SellORVACategory_CancelEvent {
  return { leadId: "", offerId: "" };
}

export const SellORVACategory_CancelEvent = {
  fromJSON(object: any): SellORVACategory_CancelEvent {
    return {
      leadId: isSet(object.leadId) ? String(object.leadId) : "",
      offerId: isSet(object.offerId) ? String(object.offerId) : "",
    };
  },

  toJSON(message: SellORVACategory_CancelEvent): unknown {
    const obj: any = {};
    message.leadId !== undefined && (obj.leadId = message.leadId);
    message.offerId !== undefined && (obj.offerId = message.offerId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SellORVACategory_CancelEvent>, I>>(base?: I): SellORVACategory_CancelEvent {
    return SellORVACategory_CancelEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SellORVACategory_CancelEvent>, I>>(object: I): SellORVACategory_CancelEvent {
    const message = createBaseSellORVACategory_CancelEvent();
    message.leadId = object.leadId ?? "";
    message.offerId = object.offerId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
