/* eslint-disable */
import { Timestamp } from "../../../google/protobuf/timestamp";
import { AuthenticationCategory } from "./categories/authentication_category";
import { ExperimentationCategory } from "./categories/experimentation_category";
import { PlatformCategory } from "./categories/platform_category";
import { SellCategory } from "./categories/sell_category";
import { SellDirectCategory } from "./categories/sell_direct_category";
import { SellEACategory } from "./categories/sell_ea_category";
import { SellExclusivesCategory } from "./categories/sell_exclusives_category";
import { SellListCategory } from "./categories/sell_list_category";
import { SellOnboardingCategory } from "./categories/sell_onboarding_category";
import { SellORVACategory } from "./categories/sell_orva_category";
import { SellVACategory } from "./categories/sell_va_category";
import { CampaignContext } from "./contexts";
import { BackendSource, MobileSource, WebsiteSource } from "./sources";

export const protobufPackage = "od_protos.journey.data";

export interface InternalEventWrapper {
  id: string;
  eventWrapper?: EventWrapper;
  receivedAt?: Date;
  consentSellOrShare?: boolean;
}

export interface EventWrapperList {
  eventWrappers: EventWrapper[];
}

export interface EventWrapper {
  timestamp?: Date;
  source?:
    | { $case: "websiteSource"; websiteSource: WebsiteSource }
    | { $case: "mobileSource"; mobileSource: MobileSource }
    | { $case: "backendSource"; backendSource: BackendSource };
  /** Must have at least one of these identifiers set */
  anonymousId?: string;
  customerUuid?: string;
  campaignContext?: CampaignContext;
  category?:
    | { $case: "experimentation"; experimentation: ExperimentationCategory }
    | { $case: "authentication"; authentication: AuthenticationCategory }
    | { $case: "sellOnboarding"; sellOnboarding: SellOnboardingCategory }
    | { $case: "platform"; platform: PlatformCategory }
    | { $case: "sell"; sell: SellCategory }
    | { $case: "sellDirect"; sellDirect: SellDirectCategory }
    | { $case: "sellList"; sellList: SellListCategory }
    | { $case: "sellEa"; sellEa: SellEACategory }
    | { $case: "sellOrva"; sellOrva: SellORVACategory }
    | { $case: "sellVa"; sellVa: SellVACategory }
    | { $case: "sellExclusives"; sellExclusives: SellExclusivesCategory };
}

function createBaseInternalEventWrapper(): InternalEventWrapper {
  return { id: "", eventWrapper: undefined, receivedAt: undefined, consentSellOrShare: undefined };
}

export const InternalEventWrapper = {
  fromJSON(object: any): InternalEventWrapper {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      eventWrapper: isSet(object.eventWrapper) ? EventWrapper.fromJSON(object.eventWrapper) : undefined,
      receivedAt: isSet(object.receivedAt) ? fromJsonTimestamp(object.receivedAt) : undefined,
      consentSellOrShare: isSet(object.consentSellOrShare) ? Boolean(object.consentSellOrShare) : undefined,
    };
  },

  toJSON(message: InternalEventWrapper): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.eventWrapper !== undefined &&
      (obj.eventWrapper = message.eventWrapper ? EventWrapper.toJSON(message.eventWrapper) : undefined);
    message.receivedAt !== undefined && (obj.receivedAt = message.receivedAt.toISOString());
    message.consentSellOrShare !== undefined && (obj.consentSellOrShare = message.consentSellOrShare);
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalEventWrapper>, I>>(base?: I): InternalEventWrapper {
    return InternalEventWrapper.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InternalEventWrapper>, I>>(object: I): InternalEventWrapper {
    const message = createBaseInternalEventWrapper();
    message.id = object.id ?? "";
    message.eventWrapper = (object.eventWrapper !== undefined && object.eventWrapper !== null)
      ? EventWrapper.fromPartial(object.eventWrapper)
      : undefined;
    message.receivedAt = object.receivedAt ?? undefined;
    message.consentSellOrShare = object.consentSellOrShare ?? undefined;
    return message;
  },
};

function createBaseEventWrapperList(): EventWrapperList {
  return { eventWrappers: [] };
}

export const EventWrapperList = {
  fromJSON(object: any): EventWrapperList {
    return {
      eventWrappers: Array.isArray(object?.eventWrappers)
        ? object.eventWrappers.map((e: any) => EventWrapper.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EventWrapperList): unknown {
    const obj: any = {};
    if (message.eventWrappers) {
      obj.eventWrappers = message.eventWrappers.map((e) => e ? EventWrapper.toJSON(e) : undefined);
    } else {
      obj.eventWrappers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventWrapperList>, I>>(base?: I): EventWrapperList {
    return EventWrapperList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EventWrapperList>, I>>(object: I): EventWrapperList {
    const message = createBaseEventWrapperList();
    message.eventWrappers = object.eventWrappers?.map((e) => EventWrapper.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEventWrapper(): EventWrapper {
  return {
    timestamp: undefined,
    source: undefined,
    anonymousId: undefined,
    customerUuid: undefined,
    campaignContext: undefined,
    category: undefined,
  };
}

export const EventWrapper = {
  fromJSON(object: any): EventWrapper {
    return {
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
      source: isSet(object.websiteSource)
        ? { $case: "websiteSource", websiteSource: WebsiteSource.fromJSON(object.websiteSource) }
        : isSet(object.mobileSource)
        ? { $case: "mobileSource", mobileSource: MobileSource.fromJSON(object.mobileSource) }
        : isSet(object.backendSource)
        ? { $case: "backendSource", backendSource: BackendSource.fromJSON(object.backendSource) }
        : undefined,
      anonymousId: isSet(object.anonymousId) ? String(object.anonymousId) : undefined,
      customerUuid: isSet(object.customerUuid) ? String(object.customerUuid) : undefined,
      campaignContext: isSet(object.campaignContext) ? CampaignContext.fromJSON(object.campaignContext) : undefined,
      category: isSet(object.experimentation)
        ? { $case: "experimentation", experimentation: ExperimentationCategory.fromJSON(object.experimentation) }
        : isSet(object.authentication)
        ? { $case: "authentication", authentication: AuthenticationCategory.fromJSON(object.authentication) }
        : isSet(object.sellOnboarding)
        ? { $case: "sellOnboarding", sellOnboarding: SellOnboardingCategory.fromJSON(object.sellOnboarding) }
        : isSet(object.platform)
        ? { $case: "platform", platform: PlatformCategory.fromJSON(object.platform) }
        : isSet(object.sell)
        ? { $case: "sell", sell: SellCategory.fromJSON(object.sell) }
        : isSet(object.sellDirect)
        ? { $case: "sellDirect", sellDirect: SellDirectCategory.fromJSON(object.sellDirect) }
        : isSet(object.sellList)
        ? { $case: "sellList", sellList: SellListCategory.fromJSON(object.sellList) }
        : isSet(object.sellEa)
        ? { $case: "sellEa", sellEa: SellEACategory.fromJSON(object.sellEa) }
        : isSet(object.sellOrva)
        ? { $case: "sellOrva", sellOrva: SellORVACategory.fromJSON(object.sellOrva) }
        : isSet(object.sellVa)
        ? { $case: "sellVa", sellVa: SellVACategory.fromJSON(object.sellVa) }
        : isSet(object.sellExclusives)
        ? { $case: "sellExclusives", sellExclusives: SellExclusivesCategory.fromJSON(object.sellExclusives) }
        : undefined,
    };
  },

  toJSON(message: EventWrapper): unknown {
    const obj: any = {};
    message.timestamp !== undefined && (obj.timestamp = message.timestamp.toISOString());
    message.source?.$case === "websiteSource" && (obj.websiteSource = message.source?.websiteSource
      ? WebsiteSource.toJSON(message.source?.websiteSource)
      : undefined);
    message.source?.$case === "mobileSource" &&
      (obj.mobileSource = message.source?.mobileSource ? MobileSource.toJSON(message.source?.mobileSource) : undefined);
    message.source?.$case === "backendSource" && (obj.backendSource = message.source?.backendSource
      ? BackendSource.toJSON(message.source?.backendSource)
      : undefined);
    message.anonymousId !== undefined && (obj.anonymousId = message.anonymousId);
    message.customerUuid !== undefined && (obj.customerUuid = message.customerUuid);
    message.campaignContext !== undefined &&
      (obj.campaignContext = message.campaignContext ? CampaignContext.toJSON(message.campaignContext) : undefined);
    message.category?.$case === "experimentation" && (obj.experimentation = message.category?.experimentation
      ? ExperimentationCategory.toJSON(message.category?.experimentation)
      : undefined);
    message.category?.$case === "authentication" && (obj.authentication = message.category?.authentication
      ? AuthenticationCategory.toJSON(message.category?.authentication)
      : undefined);
    message.category?.$case === "sellOnboarding" && (obj.sellOnboarding = message.category?.sellOnboarding
      ? SellOnboardingCategory.toJSON(message.category?.sellOnboarding)
      : undefined);
    message.category?.$case === "platform" &&
      (obj.platform = message.category?.platform ? PlatformCategory.toJSON(message.category?.platform) : undefined);
    message.category?.$case === "sell" &&
      (obj.sell = message.category?.sell ? SellCategory.toJSON(message.category?.sell) : undefined);
    message.category?.$case === "sellDirect" && (obj.sellDirect = message.category?.sellDirect
      ? SellDirectCategory.toJSON(message.category?.sellDirect)
      : undefined);
    message.category?.$case === "sellList" &&
      (obj.sellList = message.category?.sellList ? SellListCategory.toJSON(message.category?.sellList) : undefined);
    message.category?.$case === "sellEa" &&
      (obj.sellEa = message.category?.sellEa ? SellEACategory.toJSON(message.category?.sellEa) : undefined);
    message.category?.$case === "sellOrva" &&
      (obj.sellOrva = message.category?.sellOrva ? SellORVACategory.toJSON(message.category?.sellOrva) : undefined);
    message.category?.$case === "sellVa" &&
      (obj.sellVa = message.category?.sellVa ? SellVACategory.toJSON(message.category?.sellVa) : undefined);
    message.category?.$case === "sellExclusives" && (obj.sellExclusives = message.category?.sellExclusives
      ? SellExclusivesCategory.toJSON(message.category?.sellExclusives)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<EventWrapper>, I>>(base?: I): EventWrapper {
    return EventWrapper.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EventWrapper>, I>>(object: I): EventWrapper {
    const message = createBaseEventWrapper();
    message.timestamp = object.timestamp ?? undefined;
    if (
      object.source?.$case === "websiteSource" &&
      object.source?.websiteSource !== undefined &&
      object.source?.websiteSource !== null
    ) {
      message.source = {
        $case: "websiteSource",
        websiteSource: WebsiteSource.fromPartial(object.source.websiteSource),
      };
    }
    if (
      object.source?.$case === "mobileSource" &&
      object.source?.mobileSource !== undefined &&
      object.source?.mobileSource !== null
    ) {
      message.source = { $case: "mobileSource", mobileSource: MobileSource.fromPartial(object.source.mobileSource) };
    }
    if (
      object.source?.$case === "backendSource" &&
      object.source?.backendSource !== undefined &&
      object.source?.backendSource !== null
    ) {
      message.source = {
        $case: "backendSource",
        backendSource: BackendSource.fromPartial(object.source.backendSource),
      };
    }
    message.anonymousId = object.anonymousId ?? undefined;
    message.customerUuid = object.customerUuid ?? undefined;
    message.campaignContext = (object.campaignContext !== undefined && object.campaignContext !== null)
      ? CampaignContext.fromPartial(object.campaignContext)
      : undefined;
    if (
      object.category?.$case === "experimentation" &&
      object.category?.experimentation !== undefined &&
      object.category?.experimentation !== null
    ) {
      message.category = {
        $case: "experimentation",
        experimentation: ExperimentationCategory.fromPartial(object.category.experimentation),
      };
    }
    if (
      object.category?.$case === "authentication" &&
      object.category?.authentication !== undefined &&
      object.category?.authentication !== null
    ) {
      message.category = {
        $case: "authentication",
        authentication: AuthenticationCategory.fromPartial(object.category.authentication),
      };
    }
    if (
      object.category?.$case === "sellOnboarding" &&
      object.category?.sellOnboarding !== undefined &&
      object.category?.sellOnboarding !== null
    ) {
      message.category = {
        $case: "sellOnboarding",
        sellOnboarding: SellOnboardingCategory.fromPartial(object.category.sellOnboarding),
      };
    }
    if (
      object.category?.$case === "platform" &&
      object.category?.platform !== undefined &&
      object.category?.platform !== null
    ) {
      message.category = { $case: "platform", platform: PlatformCategory.fromPartial(object.category.platform) };
    }
    if (object.category?.$case === "sell" && object.category?.sell !== undefined && object.category?.sell !== null) {
      message.category = { $case: "sell", sell: SellCategory.fromPartial(object.category.sell) };
    }
    if (
      object.category?.$case === "sellDirect" &&
      object.category?.sellDirect !== undefined &&
      object.category?.sellDirect !== null
    ) {
      message.category = {
        $case: "sellDirect",
        sellDirect: SellDirectCategory.fromPartial(object.category.sellDirect),
      };
    }
    if (
      object.category?.$case === "sellList" &&
      object.category?.sellList !== undefined &&
      object.category?.sellList !== null
    ) {
      message.category = { $case: "sellList", sellList: SellListCategory.fromPartial(object.category.sellList) };
    }
    if (
      object.category?.$case === "sellEa" && object.category?.sellEa !== undefined && object.category?.sellEa !== null
    ) {
      message.category = { $case: "sellEa", sellEa: SellEACategory.fromPartial(object.category.sellEa) };
    }
    if (
      object.category?.$case === "sellOrva" &&
      object.category?.sellOrva !== undefined &&
      object.category?.sellOrva !== null
    ) {
      message.category = { $case: "sellOrva", sellOrva: SellORVACategory.fromPartial(object.category.sellOrva) };
    }
    if (
      object.category?.$case === "sellVa" && object.category?.sellVa !== undefined && object.category?.sellVa !== null
    ) {
      message.category = { $case: "sellVa", sellVa: SellVACategory.fromPartial(object.category.sellVa) };
    }
    if (
      object.category?.$case === "sellExclusives" &&
      object.category?.sellExclusives !== undefined &&
      object.category?.sellExclusives !== null
    ) {
      message.category = {
        $case: "sellExclusives",
        sellExclusives: SellExclusivesCategory.fromPartial(object.category.sellExclusives),
      };
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
