export const APPTOOLBOX_FE_INJECTION_SCRIPT = `
if (document.cookie.includes("qa_env=")) {
  const apptoolboxFeScript = document.createElement('script');

  apptoolboxFeScript.setAttribute(
    'src',
    'https://odfil.es/313e5541776ac579b6959a58f1751d74e0e0e26a/static/embedded/embed.latest.js'
  );

  // used by apptoolbox fe to validate the current app for deploy previews
  const cosmosSha1Sum = "094C26F496BD0DD1B77B8BAC6810D55E69BD2B16";
  apptoolboxFeScript.setAttribute('appId', cosmosSha1Sum);
  apptoolboxFeScript.setAttribute('appPathPrefix','/');

  // div to render apptoolbox-fe UI. div defined in _app.tsx
  // https://github.com/opendoor-labs/code/blob/e3f0a8ec49791c220ae4b46c77001b2b84d6a692/js/packages/cosmos/src/pages/_app.tsx#L130
  apptoolboxFeScript.setAttribute("apptoolboxDivId", "x-od-app-toolbox-root")

  // don't block rendering thread
  apptoolboxFeScript.setAttribute('async','');

  // inject script tag into document
  document.head.appendChild(apptoolboxFeScript);
}`;
